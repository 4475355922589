import { toDataSourceRequestString } from "@progress/kendo-data-query";

export function getStaticPages(dataState) {
  return {
    path: `static-pages?${toDataSourceRequestString(dataState)}`
  };
}

export function getStaticPageDetails(id) {
  return {
    path: `static-pages/${id}`
  };
}

export function addStaticPage(data) {
  return {
    path: "static-pages",
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Static page successfully created!"
    }
  };
}

export function updateStaticPage(data) {
  return {
    path: `static-pages/${data.id}`,
    method: "PUT",
    data: data,
    successNotificationConfig: {
      message: "Static page successfully updated!"
    }
  };
}

export function deleteStaticPage(id) {
  return {
    path: `static-pages/${id}`,
    method: "DELETE",
    data: id,
    successNotificationConfig: {
      message: "Static page successfully deleted!"
    }
  };
}

export function getStaticPageTranslation(data) {
  return {
    path: `static-pages/${data.id}/translations/${data.staticPageLanguageId}`
  };
}

export function addStaticPageTranslation(data) {
  return {
    path: `static-pages/${data.id}/translations`,
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Static page translation successfully created!"
    }
  };
}

export function updateStaticPageTranslation(data) {
  return {
    path: `static-pages/${data.staticPageId}/translations/${data.id}`,
    method: "PUT",
    data: data,
    successNotificationConfig: {
      message: "Static page translation successfully updated!"
    }
  };
}

export function getStaticPageTranslationsLookup() {
  return {
    path: "static-pages/translation-lookup"
  };
}
