import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TreeList } from "@progress/kendo-react-treelist";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";

import CommandCell from "../../../../../components/GridCell/CommandCell";
import DropDownList from "../../../../../components/Form/DropDownList";
import DeleteDialog from "../../../../../components/Dialog";
import QuestionDialog from "./QuestionDialog";
import { CRUD_TYPES } from "../../../../../enums/crudTypes";
import { QUESTION_TYPES } from "../../../../../enums/questionTypes";
import { getLectureTranslationsLookup } from "../../../../../actions/lectures";
import {
  getLectureQuestions,
  addOrUpdateQuestions,
  addOrUpdateQuestionsAndAnswers,
  deleteQuestionThenGetQuestions,
  resetAnswersState
} from "../../../../../actions/questions";
import { getQuestionsList } from "../../../../../reducers/questions";
import { getLecturesLookupList } from "../../../../../reducers/lectures";
import styles from "../../../../../assets/jss/pages/questionsFormStyle";

const useStyles = makeStyles(styles);

const questionTypesArray = Object.entries(QUESTION_TYPES).reduce(
  (result, [key, value]) => {
    result.push({ id: value, name: key.replace(/[A-Z]/g, " $&").trim() });

    return result;
  },
  []
);

const TypeCell = ({ types, dataItem }) => {
  if (
    dataItem.typeId === QUESTION_TYPES.QuestionsAndAnswers &&
    !Number.isInteger(dataItem.number)
  )
    return <td />;

  return <td>{types.find(x => x.id === dataItem.typeId)?.name || ""}</td>;
};

const Questions = () => {
  const classes = useStyles();
  const { id: lectureId } = useParams();
  const questionIdToDelete = useRef();
  const questions = useSelector(getQuestionsList);
  const lecturesLookup = useSelector(getLecturesLookupList);
  const [selectedLectureLanguageId, setSelectedLectureLanguageId] = useState();
  const [expandedIds, setExpandedIds] = useState([]);
  const [isQuestionDialogVisible, setIsQuestionDialogVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [questionInEdit, setQuestionInEdit] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getLectureTranslationsLookup({
        data: { lectureId }
      })
    );
  }, [dispatch, lectureId]);

  useEffect(() => {
    if (selectedLectureLanguageId) {
      dispatch(
        getLectureQuestions({
          data: { lectureLanguageId: selectedLectureLanguageId }
        })
      );
    }
  }, [dispatch, selectedLectureLanguageId]);

  const handleLectureSelect = event => {
    const newValue = event.target.value;
    setSelectedLectureLanguageId(newValue.id);
  };

  const handleExpandItem = event => {
    setExpandedIds(
      event.value
        ? expandedIds.filter(id => id !== event.dataItem.id)
        : [...expandedIds, event.dataItem.id]
    );
  };

  const handleEdit = dataItem => {
    setQuestionInEdit({
      ...dataItem,
      title: dataItem.parentQuestionTitle || dataItem.title,
      isParentQuestion: dataItem.subquestions?.length ? true : false
    });
    setIsQuestionDialogVisible(true);
  };

  const handleDelete = dataItem => {
    setIsDeleteDialogVisible(true);

    questionIdToDelete.current = dataItem.id;
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogVisible(false);

    questionIdToDelete.current = null;
  };

  const confirmDelete = () => {
    let questionToDelete;
    if (
      expandedIds.includes(questionIdToDelete.current) &&
      (questionToDelete = questions.find(
        x => x.id === questionIdToDelete.current
      )) &&
      (!questionToDelete.subquestions ||
        questionToDelete.subquestions.length === 1)
    ) {
      setExpandedIds(
        expandedIds.filter(id => id !== questionIdToDelete.current)
      );
    }

    dispatch(
      deleteQuestionThenGetQuestions({
        data: {
          id: questionIdToDelete.current,
          lectureLanguageId: selectedLectureLanguageId
        }
      })
    );

    closeDeleteDialog();
  };

  const handleInsert = () => {
    dispatch(resetAnswersState());

    setIsQuestionDialogVisible(true);
  };

  const handleCancel = () => {
    setQuestionInEdit(null);
    setIsQuestionDialogVisible(false);
  };

  const handleSave = ({ questions, answers }) => {
    if (answers?.length) {
      dispatch(
        addOrUpdateQuestionsAndAnswers({
          questionData: {
            lectureLanguageId: selectedLectureLanguageId,
            questions
          },
          answerData: { answers }
        })
      );
    } else {
      dispatch(
        addOrUpdateQuestions({
          data: {
            lectureLanguageId: selectedLectureLanguageId,
            questions
          }
        })
      );
    }

    setQuestionInEdit(null);
    setIsQuestionDialogVisible(false);
  };

  const questionsForDisplay = questions.map(x => ({
    ...x,
    expanded: expandedIds.includes(x.id)
  }));

  return (
    <div className={classes.container}>
      <div className={classes.lectureDropDownWrapper}>
        <DropDownList
          label="Select lecture"
          name="lectureLanguageId"
          data={lecturesLookup}
          value={selectedLectureLanguageId}
          onChange={handleLectureSelect}
        />
      </div>

      {selectedLectureLanguageId ? (
        <>
          <TreeList
            className={classes.questionsList}
            data={questionsForDisplay.sort((a, b) => a.number - b.number)}
            expandField="expanded"
            subItemsField="subquestions"
            columns={[
              {
                field: "number",
                title: "Number",
                width: 100,
                expandable: true
              },
              { field: "title", title: "Title", width: 300 },
              { field: "subtitle", title: "Subtitle", width: 300 },
              {
                field: "typeId",
                title: "Type",
                cell: props => (
                  <TypeCell {...props} types={questionTypesArray} />
                )
              },
              {
                field: "_command",
                title: " ",
                cell: props => (
                  <CommandCell
                    {...props}
                    handleEdit={handleEdit}
                    handleDelete={
                      !props.dataItem.subquestions ? handleDelete : undefined
                    }
                  />
                )
              }
            ]}
            onExpandChange={handleExpandItem}
          />

          <Button primary className={classes.addButton} onClick={handleInsert}>
            Add new
          </Button>
        </>
      ) : null}

      {isQuestionDialogVisible && (
        <QuestionDialog
          question={questionInEdit}
          mode={questionInEdit ? CRUD_TYPES.Edit : CRUD_TYPES.Insert}
          types={questionTypesArray}
          numOfQuestions={questions.length}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}

      {isDeleteDialogVisible && (
        <DeleteDialog
          handleConfirm={confirmDelete}
          handleCancel={closeDeleteDialog}
        />
      )}
    </div>
  );
};

export default Questions;
