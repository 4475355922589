export default {
  staticPageLanguageId: {
    label: "Static Page Translation",
    value: null,
    meta: {
      type: "number",
      isRequired: true,
      lookupType: "comboBox",
      lookupKey: "staticPages",
      excludes: "lectureLanguageId"
    }
  },
  lectureLanguageId: {
    label: "Lecture Translation",
    value: null,
    meta: {
      type: "number",
      isRequired: true,
      lookupType: "comboBox",
      lookupKey: "lectures",
      excludes: "staticPageLanguageId"
    }
  },
  userId: {
    label: "User",
    value: null,
    meta: {
      type: "number",
      isRequired: true,
      lookupType: "comboBox",
      lookupKey: "users"
    }
  },
  parentCommentId: {
    label: "Parent Comment",
    value: null,
    meta: {
      type: "number",
      lookupType: "comboBox",
      lookupKey: "comments"
    }
  },
  level: {
    label: "Number",
    value: 1,
    meta: {
      type: "number",
      isRequired: true,
      disabled: true
    }
  },
  text: {
    label: "Text",
    value: "",
    meta: {
      type: "multiline_text",
      isRequired: true
    }
  },
  fileName: {
    label: "File",
    value: "",
    meta: {
      type: "file",
      uploadData: true
    }
  },
  isNew: {
    label: "New?",
    value: true,
    meta: {
      type: "bool",
      disabled: true
    }
  }
};
