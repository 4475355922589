import { GridCell } from "@progress/kendo-react-grid";
import BooleanCell from "../../../../components/GridCell/BooleanCell";

export default [
  {
    name: "id",
    title: "ID",
    cellType: GridCell,
    sortable: true,
    filterable: false
  },
  {
    name: "name",
    title: "Name",
    cellType: GridCell,
    sortable: true,
    filterable: true
  },
  {
    name: "userName",
    title: "Username",
    cellType: GridCell,
    sortable: true,
    filterable: true
  },
  {
    name: "email",
    title: "Email",
    cellType: GridCell,
    sortable: true,
    filterable: true
  },
  {
    name: "role",
    title: "Role",
    cellType: GridCell,
    sortable: false,
    filterable: true
  },
  {
    name: "isApproved",
    title: "Approved",
    cellType: BooleanCell,
    sortable: true,
    filterable: false
  }
];
