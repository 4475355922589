import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const FormElement = ({ type, label, containerStyles, children }) => {
  const classes = useStyles();

  const containerClasses = classnames({
    [classes.formElementHorizontal]: true,
    [containerStyles]: containerStyles
  });

  return type !== "info" ? (
    <GridContainer className={containerClasses}>
      {label ? (
        <GridItem xs={12} sm={2}>
          <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
        </GridItem>
      ) : null}

      <GridItem xs={12} sm={label ? 10 : 12}>
        {children}
      </GridItem>
    </GridContainer>
  ) : (
    <GridContainer className={classes.formElementInfo}>
      <GridItem className={classes.formElementInfoItem} xs={12} sm={12}>
        {children}
      </GridItem>
    </GridContainer>
  );
};

export default FormElement;
