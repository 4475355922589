import React, { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PerfectScrollbar from "perfect-scrollbar";

import FormEditorElement from "./FormEditorElement";
import JoditEditor from "./JoditEditor";
import { getToken } from "../../reducers/auth";

let scrollbars = [];

const HTMLEditor = (
  { defaultValue, onChange, onBlur, label, name, folderName },
  ...rest
) => {
  const token = useSelector(getToken);
  const { id } = useParams();
  const currentRef = useRef(null);

  const initializeScrollbars = () => {
    if (navigator.platform.indexOf("Win") > -1) {
      const targetElements = document.getElementsByClassName("jodit_wysiwyg");

      for (let element of targetElements) {
        if (!element.classList.contains("ps")) {
          const ps = new PerfectScrollbar(element, {
            suppressScrollX: true,
            suppressScrollY: false
          });

          scrollbars.push(ps);
        }
      }
    }
  };

  const destroyScrollbars = () => {
    if (navigator.platform.indexOf("Win") > -1 && scrollbars.length) {
      scrollbars.forEach(ps => ps.destroy());
      scrollbars = [];
    }
  };

  const config = useMemo(() => {
    return {
      zIndex: 0,
      readonly: false,
      activeButtonsInReadOnly: ["source", "fullsize"],
      toolbarButtonSize: "medium",
      theme: "default",
      enableDragAndDropFileToEditor: true,
      saveModeInCookie: false,
      spellcheck: true,
      editorCssClass: false,
      triggerChangeEvent: true,
      height: 500,
      direction: "auto",
      language: "en",
      debugLanguage: false,
      i18n: "en",
      tabIndex: -1,
      toolbar: true,
      enter: "P",
      useSplitMode: false,
      colorPickerDefaultTab: "background",
      imageDefaultWidth: 150,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      removeButtons: ["print", "video", "font"],
      disablePlugins: ["paste", "stat"],
      events: {
        afterInit: initializeScrollbars,
        beforeDestruct: destroyScrollbars
      },
      textIcons: false,
      uploader: {
        url: `/api/${folderName}/upload-media`,
        insertImageAsBase64URI: false,
        imagesExtensions: ["jpg", "png", "jpeg", "gif"],
        filesExtensions: ["pdf"],
        filesVariableName: function (e) {
          return "files";
        },
        headers: {
          Authorization: `bearer ${token}`
        },
        data: {
          id
        },
        prepareData: function (data) {
          return data;
        },
        isSuccess: function (resp) {
          return resp;
        },
        getMsg: function (resp) {
          return resp.msg.join !== undefined ? resp.msg.join(" ") : resp.msg;
        },
        process: function (resp) {
          return {
            files: resp.data.files,
            path: resp.data.path,
            baseUrl: resp.data.baseUrl,
            fileType: resp.data.fileType,
            error: resp.data.success ? false : true,
            message: resp.data.message
          };
        },
        defaultHandlerSuccess: function (data) {
          let i,
            field = this.options.uploader.filesVariableName();
          if (data[field] && data[field].length) {
            for (i = 0; i < data[field].length; i += 1) {
              if (data.fileType === "image") {
                this.selection.insertImage(
                  data.baseUrl + data[field][i],
                  null,
                  250
                );
              } else {
                let a = document.createElement("a");
                let link = document.createTextNode(data[field][i]);
                a.appendChild(link);
                a.title = ` ${data[field][i]}`;
                a.href = data.baseUrl + data[field][i];
                a.style.cursor = "pointer";
                this.selection.insertNode(a);
                // this.selection.moveCursorTo(a);
              }
            }
          }
        }
        // error: function (e) {
        //   this.options.events.fire('errorPopap', [
        //     e.getMessage(),
        //     'error',
        //     4000,
        //   ]);
        // },
      },
      showXPathInStatusbar: true
    };
  }, [folderName, id, token]);
  return (
    <FormEditorElement label={label}>
      <JoditEditor
        defaultValue={defaultValue || ""}
        tabIndex={1}
        ref={currentRef}
        config={config}
        onBlur={onBlur}
        name={name}
      />
    </FormEditorElement>
  );
};

export default HTMLEditor;
