import {
  GET_COMMENTS,
  GET_COMMENT_DETAILS,
  GET_COMMENTS_LOOKUP,
  ADD_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  CHANGE_COMMENT_DATA_STATE,
  RESET_COMMENT_STATE,
  RESET_COMMENT_FORM_STATE
} from "../actions/comments";

const INITIAL_STATE = {
  list: [],
  lookupList: [],
  total: 0,
  dataState: {
    sort: [],
    filter: undefined,
    skip: 0,
    take: 10
  },
  selectedItem: null,
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
  isError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMENTS.REQUEST:
    case GET_COMMENT_DETAILS.REQUEST:
    case GET_COMMENTS_LOOKUP.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }

    case GET_COMMENTS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        list: action.payload.data,
        total: action.payload.total
      };
    }

    case GET_COMMENT_DETAILS.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        isFetching: false,
        isFetched: true,
        isError: false
      };
    }

    case GET_COMMENTS_LOOKUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        lookupList: action.payload
      };
    }

    case ADD_COMMENT.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }

    case ADD_COMMENT.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }

    case UPDATE_COMMENT.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false,
        selectedItem: { ...state.selectedItem, ...action.payload }
      };
    }

    case UPDATE_COMMENT.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }

    case DELETE_COMMENT.SUCCESS: {
      return {
        ...state,
        isError: false
        // list: state.list.filter(item => item.id !== action.payload.id)
      };
    }

    case GET_COMMENTS.ERROR:
    case GET_COMMENT_DETAILS.ERROR:
    case GET_COMMENTS_LOOKUP.ERROR:
    case ADD_COMMENT.ERROR:
    case UPDATE_COMMENT.ERROR:
    case DELETE_COMMENT.ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isError: true
      };
    }

    case CHANGE_COMMENT_DATA_STATE: {
      return {
        ...state,
        dataState: action.payload
      };
    }

    case RESET_COMMENT_STATE: {
      return {
        ...INITIAL_STATE
      };
    }

    case RESET_COMMENT_FORM_STATE: {
      return {
        ...state,
        selectedItem: null,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isError: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getCommentsList = state => state.comments.list;
export const getCommentsListDataState = state => state.comments.dataState;
export const getCommentsListTotalNumber = state => state.comments.total;

export const getSelectedComment = state => state.comments.selectedItem;
export const getIsCommentSaved = state => state.comments.isSaved;

export const getCommentsLookupList = state => state.comments.lookupList;
