import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  roseCardHeader,
  medOpenPrimaryCardHeader,
  medOpenInfoCardHeader
} from "../../commonStyle";

const cardTextStyle = {
  cardText: {
    float: "none",
    display: "inline-block",
    marginRight: "0",
    borderRadius: "3px",
    padding: "15px",
    marginTop: "-20px"
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader: {
    ...medOpenInfoCardHeader
  },
  primaryCardHeader: {
    ...medOpenPrimaryCardHeader
  },
  roseCardHeader
};

export default cardTextStyle;
