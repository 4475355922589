import React, { useState, useMemo } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";

import FormElement from "./FormElement";
import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const FormComboBox = ({
  label = "",
  name = "",
  dataItemKey = "id",
  textField = "name",
  data = [],
  value = {},
  isRequired = false,
  isTouched = false,
  isFocused = false,
  isValid,
  errorMessage = "",
  onFocus = () => {},
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const [filter, setFilter] = useState("");

  const handleBlur = event => {
    isRequired && onFocus(event);
  };

  const handleChange = event => {
    onChange(event, dataItemKey);
  };

  const handleFilterChange = event => {
    setFilter(event.filter.value);
  };

  const filteredData = useMemo(() => {
    return filter
      ? data.filter(x =>
          x[textField].toLowerCase().includes(filter.toLowerCase())
        )
      : data;
  }, [data, filter, textField]);

  return (
    <FormElement label={label}>
      <ComboBox
        className={classes.dropDownList}
        name={name}
        dataItemKey={dataItemKey}
        textField={textField}
        data={filteredData}
        value={
          data.length && value ? data.find(x => x[dataItemKey] === value) : null
        }
        required={isRequired}
        validityStyles={isRequired && isTouched ? true : false}
        valid={isRequired && isTouched ? isValid : undefined}
        suggest={true}
        filterable={true}
        // onFocus={onFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onFilterChange={handleFilterChange}
      />

      {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormElement>
  );
};

export default FormComboBox;
