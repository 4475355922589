import React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { makeStyles } from "@material-ui/core/styles";

import FormElement from "./FormElement";

import styles from "../../assets/jss/components/formStyle";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles(styles);

const FormNumericInput = ({
  name = "",
  label = "",
  value = 1,
  isRequired = false,
  isDisabled = false,
  isUnique = false,
  isTouched = false,
  isFocused = false,
  isValid = false,
  errorMessage = "",
  onFocus = () => {},
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyles();

  const handleBlur = event => {
    isRequired && onFocus(event);
  };

  return (
    <FormElement type={"NumericTextBox"} label={label}>
      <NumericTextBox
        autoComplete="off"
        className={classes.input}
        name={name}
        value={value}
        disabled={isDisabled}
        // onFocus={onFocus}
        onBlur={handleBlur}
        onChange={onChange}
        validityStyles={isRequired ? isTouched : undefined}
        valid={isRequired ? isValid : undefined}
        {...rest}
      />

      {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormElement>
  );
};

export default FormNumericInput;
