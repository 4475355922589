import axios from "axios";
import { refreshToken, logoutUser } from "../../actions/auth";
import {
  getAuthStateFromStorage,
  removeAuthStateFromStorage
} from "../utilities/storage";
import { store } from "../../store/store";

//ASYNC WORKING CODE
// const refreshTokenInterceptor = () => {
//   axios.interceptors.response.use(
//     async response => {
//       return response;
//     },
//     async error => {
//       const initalRequest = error.config;
//       const authState = getAuthStateFromStorage();
//       const tokens = {
//         token: authState?.token,
//         refreshToken: authState?.refreshToken
//       };

//       if (error?.response?.status === 401 && !initalRequest._retry) {
//         initalRequest._retry = true;

//         await store.dispatch(refreshToken(tokens));
//         return await axios(initalRequest);
//       }
//       return Promise.reject(new Error("Invalid token")).then(() => {
//          store.dispatch(logoutUser);
//        });
//     }
//   );
// };

// export default refreshTokenInterceptor;

//SYNC WORKING CODE
const refreshTokenInterceptor = () => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const initialRequest = error.config;
      const {
        response: { status },
        config: { url }
      } = error;
      // const status = error.response.status;
      // const url = error.config.url;

      if (status !== 401) {
        return Promise.reject(error);
      }

      if (status === 401 && url === "/api/auth/token") {
        removeAuthStateFromStorage();
        store.dispatch(logoutUser);
        return Promise.reject(error);
      }
      if (status === 401 && url !== "/api/auth/token") {
        const authData = getAuthStateFromStorage();
        const tokens = {
          token: authData?.token,
          refreshToken: authData?.refreshToken
        };
        return store.dispatch(refreshToken(tokens)).then(res => {
          if (!res) {
            return Promise.reject(error);
          }
          return Promise.resolve(axios(initialRequest));
        });
      }
    }
  );
};

export default refreshTokenInterceptor;
