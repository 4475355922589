import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Done from "@material-ui/icons/Done";
import Cancel from "@material-ui/icons/Cancel";

import CommandCell from "../../../../../components/GridCell/CommandCell";
import HeaderWithHintCell from "../../../../../components/GridCell/HeaderWithHintCell";
import { QUESTION_TYPES } from "../../../../../enums/questionTypes";

const findHiddenWordsFields = ({
  editField,
  deleteField,
  textEditCell,
  numberEditCell,
  onEdit,
  onSaveConfirm,
  onSaveCancel,
  onDelete,
  onDeleteConfirm,
  onDeleteCancel
}) => [
  {
    field: "answer",
    title: "Search words",
    width: "80%",
    headerCell: props => (
      <HeaderWithHintCell {...props} hint={"(comma separated list of words)"} />
    ),
    editCell: textEditCell
  },
  {
    field: "points",
    title: "Points per word",
    editCell: numberEditCell
  },
  {
    field: "_command",
    title: " ",
    cell: props =>
      props.dataItem[editField] ? (
        <td>
          <IconButton
            disabled={
              props.dataItem.answer?.length === 0 || !props.dataItem.points
            }
            onClick={onSaveConfirm}
          >
            <Done />
          </IconButton>

          <IconButton onClick={onSaveCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : props.dataItem[deleteField] ? (
        <td>
          <IconButton onClick={onDeleteConfirm}>
            <Done />
          </IconButton>

          <IconButton onClick={onDeleteCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : (
        <CommandCell {...props} handleEdit={onEdit} handleDelete={onDelete} />
      )
  }
];

const fillMissingLettersFields = ({
  editField,
  deleteField,
  textEditCell,
  optionalTextEditCell,
  numberEditCell,
  onEdit,
  onSaveConfirm,
  onSaveCancel,
  onDelete,
  onDeleteConfirm,
  onDeleteCancel
}) => [
  {
    field: "sentence1",
    title: "First part of sentence",
    width: "20%",
    editCell: optionalTextEditCell
  },
  {
    field: "answer",
    title: "Hidden word",
    width: "40%",
    headerCell: props => (
      <HeaderWithHintCell
        {...props}
        hint={
          "Upercase letters will be displayed in test! You can use spaces for multiple words too"
        }
      />
    ),
    editCell: textEditCell
  },
  {
    field: "sentence2",
    title: "Second part of sentence",
    width: "20%",
    editCell: optionalTextEditCell
  },
  {
    field: "points",
    title: "Points for correct answer",
    editCell: numberEditCell
  },
  {
    field: "_command",
    title: " ",
    cell: props =>
      props.dataItem[editField] ? (
        <td>
          <IconButton
            disabled={
              props.dataItem.answer?.length === 0 || !props.dataItem.points
            }
            onClick={onSaveConfirm}
          >
            <Done />
          </IconButton>

          <IconButton onClick={onSaveCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : props.dataItem[deleteField] ? (
        <td>
          <IconButton onClick={onDeleteConfirm}>
            <Done />
          </IconButton>

          <IconButton onClick={onDeleteCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : (
        <CommandCell {...props} handleEdit={onEdit} handleDelete={onDelete} />
      )
  }
];

const questionsAndAnswersFields = ({
  editField,
  deleteField,
  textEditCell,
  optionalTextEditCell,
  numberEditCell,
  onEdit,
  onSaveConfirm,
  onSaveCancel,
  onDelete,
  onDeleteConfirm,
  onDeleteCancel
}) => [
  {
    field: "answer",
    title: "Answer",
    width: "50%",
    editCell: textEditCell
  },
  {
    field: "points",
    title: "Points",
    width: "10%",
    editCell: numberEditCell
  },
  {
    field: "hiddenComment",
    title: "Hidden comment",
    width: "30%",
    editCell: optionalTextEditCell
  },
  {
    field: "_command",
    title: " ",
    cell: props =>
      props.dataItem[editField] ? (
        <td>
          <IconButton
            disabled={
              props.dataItem.answer?.length === 0 ||
              (!props.dataItem.points && props.dataItem.points !== 0)
            }
            onClick={onSaveConfirm}
          >
            <Done />
          </IconButton>

          <IconButton onClick={onSaveCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : props.dataItem[deleteField] ? (
        <td>
          <IconButton onClick={onDeleteConfirm}>
            <Done />
          </IconButton>

          <IconButton onClick={onDeleteCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : (
        <CommandCell {...props} handleEdit={onEdit} handleDelete={onDelete} />
      )
  }
];

const matrixFields = ({
  editField,
  deleteField,
  textEditCell,
  numberEditCell,
  onEdit,
  onSaveConfirm,
  onSaveCancel,
  onDelete,
  onDeleteConfirm,
  onDeleteCancel
}) => [
  {
    field: "questionOrStatement",
    title: "Statement or question",
    width: "40%",
    editCell: textEditCell
  },
  {
    field: "answer",
    title: "Correct answer",
    width: "40%",
    editCell: textEditCell
  },
  {
    field: "points",
    title: "Points for correct answer",
    editCell: numberEditCell
  },
  {
    field: "_command",
    title: " ",
    cell: props =>
      props.dataItem[editField] ? (
        <td>
          <IconButton
            disabled={
              props.dataItem.questionOrStatement?.length === 0 ||
              props.dataItem.answer?.length === 0 ||
              !props.dataItem.points
            }
            onClick={onSaveConfirm}
          >
            <Done />
          </IconButton>

          <IconButton onClick={onSaveCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : props.dataItem[deleteField] ? (
        <td>
          <IconButton onClick={onDeleteConfirm}>
            <Done />
          </IconButton>

          <IconButton onClick={onDeleteCancel}>
            <Cancel />
          </IconButton>
        </td>
      ) : (
        <CommandCell {...props} handleEdit={onEdit} handleDelete={onDelete} />
      )
  }
];

const getColumnsByType = ({
  type,
  editField,
  deleteField,
  textEditCell,
  optionalTextEditCell,
  numberEditCell,
  onEdit,
  onSaveConfirm,
  onSaveCancel,
  onDelete,
  onDeleteConfirm,
  onDeleteCancel
}) => {
  if (type === QUESTION_TYPES.FindHiddenWords) {
    return findHiddenWordsFields({
      editField,
      deleteField,
      textEditCell,
      numberEditCell,
      onEdit,
      onSaveConfirm,
      onSaveCancel,
      onDelete,
      onDeleteConfirm,
      onDeleteCancel
    });
  } else if (type === QUESTION_TYPES.FillMissingLetters) {
    return fillMissingLettersFields({
      editField,
      deleteField,
      textEditCell,
      optionalTextEditCell,
      numberEditCell,
      onEdit,
      onSaveConfirm,
      onSaveCancel,
      onDelete,
      onDeleteConfirm,
      onDeleteCancel
    });
  } else if (type === QUESTION_TYPES.QuestionsAndAnswers) {
    return questionsAndAnswersFields({
      editField,
      deleteField,
      textEditCell,
      optionalTextEditCell,
      numberEditCell,
      onEdit,
      onSaveConfirm,
      onSaveCancel,
      onDelete,
      onDeleteConfirm,
      onDeleteCancel
    });
  } else {
    return matrixFields({
      editField,
      deleteField,
      textEditCell,
      numberEditCell,
      onEdit,
      onSaveConfirm,
      onSaveCancel,
      onDelete,
      onDeleteConfirm,
      onDeleteCancel
    });
  }
};

export { getColumnsByType };
