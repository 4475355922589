import React, { useState } from "react";
import {
  ListView,
  ListViewHeader,
  ListViewFooter
} from "@progress/kendo-react-listview";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import Input from "../../../../../components/Form/Input";
import styles from "../../../../../assets/jss/pages/questionsFormStyle";

const useStyles = makeStyles(styles);

const SubquestionsList = ({ subquestions = [], onAdd }) => {
  const classes = useStyles();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [newSubquestion, setNewSubquestion] = useState("");

  const handleAddClick = () => {
    setIsFormVisible(true);
  };

  const handleAddConfirm = () => {
    onAdd(newSubquestion);

    setIsFormVisible(false);
    setNewSubquestion("");
  };

  const handleAddCancel = () => {
    setIsFormVisible(false);
    setNewSubquestion("");
  };

  const handleInputChange = event => {
    setNewSubquestion(event.target.value);
  };

  const renderHeader = () => {
    return (
      <ListViewHeader
        className={classnames({
          "pl-3": true,
          "pb-2": true,
          "pt-2": true,
          [classes.subquestionsListHeaderBottom]: true
        })}
      >
        Subquestions:
      </ListViewHeader>
    );
  };

  const renderFooter = () => {
    return subquestions.length || isFormVisible ? null : (
      <ListViewFooter
        className={classnames({
          "pl-3": true,
          "pb-2": true,
          "pt-2": true,
          [classes.subquestionsListHeaderBottom]: true
        })}
      >
        There are no subquestions.
      </ListViewFooter>
    );
  };

  const renderItem = ({ dataItem }) => {
    return (
      <div className={classes.subquestionsListItem}>
        <div>{dataItem.subtitle}</div>
      </div>
    );
  };

  return (
    <>
      <ListView
        className={classes.subquestionsList}
        data={subquestions}
        header={renderHeader}
        footer={renderFooter}
        item={renderItem}
      />

      {isFormVisible ? (
        <div className={classes.formWrapper}>
          <div className={classes.subtitleInputWrapper}>
            <Input
              label="Subtitle"
              value={newSubquestion}
              onChange={handleInputChange}
            />
          </div>

          <div>
            <Button
              primary
              className={classes.formButton}
              disabled={newSubquestion.length === 0}
              onClick={handleAddConfirm}
            >
              Confirm
            </Button>

            <Button className={classes.formButton} onClick={handleAddCancel}>
              Cancel
            </Button>
          </div>
        </div>
      ) : null}

      <Button
        className={classes.dialogAddButton}
        primary
        onClick={handleAddClick}
        disabled={isFormVisible}
      >
        Add subquestion
      </Button>
    </>
  );
};

export default SubquestionsList;
