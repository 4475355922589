import React, { useEffect, useState } from "react";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import useForm from "../../../../hooks/useForm";
import {
  addUser,
  updateUser,
  getRoles,
  getGenders,
  checkEmail,
  checkUserName,
  loadSelectedUser
} from "../../../../actions/users";
import styles from "../../../../assets/jss/pages/formStyle";
import useObjectMapper from "../../../../hooks/useObjectMapper";
import initialState from "./UserFields";
import Form from "../../../../components/PageForm/PageForm";
import { getCoursesLookup } from "../../../../actions/courses";
import {
  getSelectedUser,
  getIsUserSaved,
  getUsersLookupList,
  getUsersRadioLookupList,
  getIsEmailAvailable,
  getIsUsernameAvailable
} from "../../../../reducers/users";
import { getCoursesLookupList } from "../../../../reducers/courses";

const useStyles = makeStyles(styles);

const propertiesWithChangingRequiredValueOnCreateAndEdit = [
  "password",
  "confirmPassword"
];

const UsersFormContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [serverValidations, setServerValidations] = useState({});
  const selectedUser = useSelector(getSelectedUser);
  const rolesLookup = useSelector(getUsersLookupList);
  const gendersLookup = useSelector(getUsersRadioLookupList);
  const coursesLookup = useSelector(getCoursesLookupList);
  const emailUnique = useSelector(getIsEmailAvailable);
  const userNameUnique = useSelector(getIsUsernameAvailable);
  const isSaved = useSelector(getIsUserSaved);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getGenders());
  }, [dispatch]);

  const onInputChange = () => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };
  const { getInitialState: userState } = useObjectMapper({
    initialState,
    selectedItem: selectedUser,
    changeRequirement: id && propertiesWithChangingRequiredValueOnCreateAndEdit
  });

  const {
    values: user,
    validations: userValidations,
    handleInputChange,
    handleInputFocus
  } = useForm({
    initialValues: userState,
    onInputChange
  });

  const isFormValid =
    Object.values(userValidations)
      .filter(x => x !== null)
      .every(x => x?.isValid === true) &&
    Object.values(serverValidations)
      .filter(x => x !== null)
      .every(x => x?.isValid === true);

  useEffect(() => {
    if (id) {
      dispatch(loadSelectedUser(id));
      dispatch(getCoursesLookup());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isSaved && hasUnsavedChanges) {
      setHasUnsavedChanges(false);
    }

    if (isSaved && !hasUnsavedChanges) {
      history.push("/admin/users");
    }
  }, [dispatch, history, isSaved, hasUnsavedChanges]);

  const handleSave = () => {
    if (id) {
      dispatch(updateUser({ id: selectedUser.id, ...user }));
    } else {
      dispatch(addUser(user));
    }
  };

  const isAvailable = event => {
    const { name, value } = event.target;
    if (
      userValidations[name]?.isValid &&
      value &&
      value !== selectedUser?.[name]
    ) {
      name === "email"
        ? dispatch(checkEmail(value))
        : dispatch(checkUserName(value));
    }
  };

  useEffect(() => {
    setServerValidations(prevState => {
      return {
        ...prevState,
        email: {
          isValid: emailUnique,
          errorMessage: !emailUnique ? `This email is already in use!` : ""
        },
        userName: {
          isValid: userNameUnique,
          errorMessage: !userNameUnique
            ? `This username is already in use!`
            : ""
        }
      };
    });
  }, [emailUnique, userNameUnique]);

  const handleCancel = () => {
    history.push("/admin/users");
  };
  return (
    <div className={classes.container}>
      <Prompt
        when={hasUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      <Form
        initialData={userState}
        data={user}
        roles={rolesLookup}
        genders={gendersLookup}
        courses={coursesLookup}
        validations={userValidations}
        serverValidations={serverValidations}
        handleServerCheck={isAvailable}
        isFormValid={isFormValid}
        isEditMode={selectedUser?.id ? true : false}
        hasUnsavedChanges={hasUnsavedChanges}
        onInputFocus={handleInputFocus}
        onInputChange={handleInputChange}
        onSave={handleSave}
        onReturn={handleCancel}
        divideFormByGroups={true}
        course={
          selectedUser?.id
            ? {
                title: `Currently Assigned courses`,
                data: selectedUser?.courseListInfo || []
              }
            : null
        }
      />
    </div>
  );
};

export default UsersFormContainer;
