import { callApi } from "../services/api/api";

import {
  getStaticPages as getStaticPagesApi,
  getStaticPageDetails as getStaticPageDetailsApi,
  getStaticPageTranslation as getStaticPageTranslationApi,
  addStaticPage as addStaticPageApi,
  updateStaticPage as updateStaticPageApi,
  deleteStaticPage as deleteStaticPageApi,
  addStaticPageTranslation as addStaticPageTranslationApi,
  updateStaticPageTranslation as updateStaticPageTranslationApi,
  getStaticPageTranslationsLookup as getStaticPageTranslationsLookupApi
} from "../pages/admin/static-pages/apiConfig";
import generateActions from "../services/utilities/generateActions";

export const GET_STATIC_PAGES = generateActions("GET_STATIC_PAGES");
export const GET_STATIC_PAGE_DETAILS = generateActions(
  "GET_STATIC_PAGE_DETAILS"
);
export const GET_STATIC_PAGE_TRANSLATION = generateActions(
  "GET_STATIC_PAGE_TRANSLATION"
);
export const ADD_STATIC_PAGE = generateActions("ADD_STATIC_PAGE");
export const UPDATE_STATIC_PAGE = generateActions("UPDATE_STATIC_PAGE");
export const DELETE_STATIC_PAGE = generateActions("DELETE_STATIC_PAGE");

export const ADD_STATIC_PAGE_TRANSLATION = generateActions(
  "ADD_STATIC_PAGE_TRANSLATION"
);
export const UPDATE_STATIC_PAGE_TRANSLATION = generateActions(
  "UPDATE_STATIC_PAGE_TRANSLATION"
);

export const GET_STATIC_PAGE_TRANSLATIONS_LOOKUP = generateActions(
  "GET_STATIC_PAGE_TRANSLATIONS_LOOKUP"
);

export const CHANGE_STATIC_PAGE_DATA_STATE = "CHANGE_STATIC_PAGE_DATA_STATE";
export const RESET_STATIC_PAGE_STATE = "RESET_STATIC_PAGE_STATE";
export const RESET_STATIC_PAGE_FORM_STATE = "RESET_STATIC_PAGE_FORM_STATE";
export const RESET_STATIC_PAGE_TRANSLATION_FORM_STATE =
  "RESET_STATIC_PAGE_TRANSLATION_FORM_STATE";

export function getStaticPages(dataState) {
  return {
    type: GET_STATIC_PAGES.ACTION,
    promise: callApi(getStaticPagesApi(dataState)),
    payload: dataState
  };
}

export function getStaticPageDetails(id) {
  return {
    type: GET_STATIC_PAGE_DETAILS.ACTION,
    promise: callApi(getStaticPageDetailsApi(id)),
    payload: id
  };
}

export function addStaticPage(data) {
  return {
    type: ADD_STATIC_PAGE.ACTION,
    promise: callApi(addStaticPageApi(data)),
    payload: data
  };
}

export function updateStaticPage(data) {
  return {
    type: UPDATE_STATIC_PAGE.ACTION,
    promise: callApi(updateStaticPageApi(data)),
    payload: data
  };
}

export function deleteStaticPage(id) {
  return {
    type: DELETE_STATIC_PAGE.ACTION,
    promise: callApi(deleteStaticPageApi(id)),
    payload: id
  };
}
export function deleteStaticPageAndUpdateGrid(id, dataState) {
  return dispatch => {
    return dispatch(deleteStaticPage(id)).then(() =>
      dispatch(getStaticPages(dataState))
    );
  };
}

export function getStaticPageTranslation(data) {
  return {
    type: GET_STATIC_PAGE_TRANSLATION.ACTION,
    promise: callApi(getStaticPageTranslationApi(data)),
    payload: data
  };
}

export function addStaticPageTranslation(data) {
  return {
    type: ADD_STATIC_PAGE_TRANSLATION.ACTION,
    promise: callApi(addStaticPageTranslationApi(data)),
    payload: data
  };
}

export function updateStaticPageTranslation(data) {
  return {
    type: UPDATE_STATIC_PAGE_TRANSLATION.ACTION,
    promise: callApi(updateStaticPageTranslationApi(data)),
    payload: data
  };
}

export function changeStaticPageDataState(dataState) {
  return {
    type: CHANGE_STATIC_PAGE_DATA_STATE,
    payload: dataState
  };
}

/**
 * Resets the whole static page state in store to initial state.
 */
export function resetStaticPageState() {
  return {
    type: RESET_STATIC_PAGE_STATE
  };
}

/**
 * Resets all data connected to static page and translation forms.
 */
export function resetStaticPageFormState() {
  return {
    type: RESET_STATIC_PAGE_FORM_STATE
  };
}

/**
 * Only resets the fields related to the translation form.
 * Accepts new language ID for memorizing it while
 * changing languages in dropdown and reseting the rest
 * of the form.
 * @param {number} [data]
 */
export function resetStaticPageTranslationFormState(data) {
  return {
    type: RESET_STATIC_PAGE_TRANSLATION_FORM_STATE,
    payload: data
  };
}

export function getStaticPageTranslationsLookup() {
  return {
    type: GET_STATIC_PAGE_TRANSLATIONS_LOOKUP.ACTION,
    promise: callApi(getStaticPageTranslationsLookupApi())
  };
}

/**
 * Used for autosaving changes to the selected translation
 * when changing languages in the dropdown. It first creates
 * the translation and then resets fields related to the
 * translation form, with the ID of the newly selected language.
 * @param {Object} data
 * @param {Object} data.translation
 * @param {number} data.newLanguageId
 */
export function addStaticPageTranslationThenResetTranslationForm({
  translation,
  newLanguageId
}) {
  return dispatch => {
    return dispatch(addStaticPageTranslation(translation)).then(() =>
      dispatch(resetStaticPageTranslationFormState(newLanguageId))
    );
  };
}

/**
 * Used for autosaving changes to the selected translation
 * when changing languages in the dropdown. It first updates
 * the translation and then resets fields related to the
 * translation form, with the ID of the newly selected language.
 * @param {Object} data
 * @param {Object} data.translation
 * @param {number} data.newLanguageId
 */
export function updateStaticPageTranslationThenResetTranslationForm({
  translation,
  newLanguageId
}) {
  return dispatch => {
    return dispatch(updateStaticPageTranslation(translation)).then(() =>
      dispatch(resetStaticPageTranslationFormState(newLanguageId))
    );
  };
}

/**
 * Used when saving changes on the static page form and navigating
 * back to list. It first creates the static page and then resets all
 * fields related to both forms.
 * @param {Object} data
 * @param {Object} data.page
 */
export function addStaticPageThenResetForm({ page }) {
  return dispatch => {
    return dispatch(addStaticPage(page)).then(() =>
      dispatch(resetStaticPageFormState())
    );
  };
}

/**
 * Used when saving changes on the static page form and navigating
 * back to list. It first updates the static page and then resets
 * all fields related to both forms.
 * @param {Object} data
 * @param {Object} data.page
 */
export function updateStaticPageThenResetForm({ page }) {
  return dispatch => {
    return dispatch(updateStaticPage(page)).then(() =>
      dispatch(resetStaticPageFormState())
    );
  };
}

/**
 * Used when saving changes on the translation form and navigating back to list.
 * It first creates the translation and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.translation
 */
export function addStaticPageTranslationThenResetForm({ translation }) {
  return dispatch => {
    return dispatch(addStaticPageTranslation(translation)).then(() =>
      dispatch(resetStaticPageFormState())
    );
  };
}

/**
 * Used when saving changes on the translation form and navigating back to list.
 * It first updates the translation and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.translation
 */
export function updateStaticPageTranslationThenResetForm({ translation }) {
  return dispatch => {
    return dispatch(updateStaticPageTranslation(translation)).then(() =>
      dispatch(resetStaticPageFormState())
    );
  };
}

/**
 * Used when saving changes on both forms and navigating back to list.
 * It first updates the static page, then creates the translation
 * and in the end resets all fields related to both forms.
 * @param {Object} data
 * @param {Object} data.page
 * @param {Object} data.translation
 */
export function updateStaticPageThenAddTranslationThenResetForm({
  page,
  translation
}) {
  return dispatch => {
    return dispatch(updateStaticPage(page)).then(() =>
      dispatch(addStaticPageTranslation(translation)).then(() =>
        dispatch(resetStaticPageFormState())
      )
    );
  };
}

/**
 * Used when saving changes on both forms and navigating back to list.
 * It first updates the static page, then updates the translation
 * and in the end resets all fields related to both forms.
 * @param {Object} data
 * @param {Object} data.page
 * @param {Object} data.translation
 */
export function updateStaticPageThenUpdateTranslationThenResetForm({
  page,
  translation
}) {
  return dispatch => {
    return dispatch(updateStaticPage(page)).then(() =>
      dispatch(updateStaticPageTranslation(translation)).then(() =>
        dispatch(resetStaticPageFormState())
      )
    );
  };
}
