import {
  GET_LECTURE_QUESTIONS,
  GET_QUESTION_ANSWERS,
  ADD_OR_UPDATE_LECTURE_QUESTIONS,
  ADD_OR_UPDATE_QUESTION_ANSWERS,
  DELETE_LECTURE_QUESTION,
  DELETE_QUESTION_ANSWER,
  RESET_ANSWERS_STATE
} from "../actions/questions";

const extractSubquestionsFromQuestionsInApiResponse = response => {
  let questionsResult = [];

  const subquestionsList = response.filter(
    x => Number.isInteger(x.number) === false
  );

  if (subquestionsList.length === 0) {
    return response;
  }

  questionsResult = [...response.filter(x => Number.isInteger(x.number))];

  let questionsWithSubquestionsNumbers = new Set();
  subquestionsList.forEach(x =>
    questionsWithSubquestionsNumbers.add(Math.floor(x.number))
  );

  for (let num of questionsWithSubquestionsNumbers) {
    const subquestionsInGroup = subquestionsList.filter(
      x => Math.floor(x.number) === num
    );
    let firstSubquestion = subquestionsInGroup.find(x => x.title.length);

    if (!firstSubquestion) {
      const minNumber = Math.min(...subquestionsInGroup.map(x => x.number));
      firstSubquestion = subquestionsInGroup.find(x => x.number === minNumber);
    }

    questionsResult.push({
      id: firstSubquestion?.id,
      name: "",
      title: firstSubquestion?.title,
      subtitle: "",
      typeId: firstSubquestion?.typeId,
      number: num,
      subquestions: subquestionsInGroup
        .sort((a, b) => a.number - b.number)
        .map(x => ({
          ...x,
          title: "",
          parentQuestionTitle: firstSubquestion?.title,
          isFirstSubquestion: x.id === firstSubquestion?.id
        }))
    });
  }

  return questionsResult;
};

const INITIAL_STATE = {
  questionsList: [],
  answersList: [],
  addedOrUpdatedQuestionId: null,
  isQuestionsListFetching: false,
  isQuestionsListFetched: false,
  isQuestionsListFetchError: false,
  areQuestionsSaving: false,
  areQuestionsSaved: false,
  isQuestionsSaveError: false,
  isAnswersListFetching: false,
  isAnswersListFetched: false,
  isAnswersListFetchError: false,
  areAnswersSaving: false,
  areAnswersSaved: false,
  isAnswersSaveError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LECTURE_QUESTIONS.REQUEST: {
      return {
        ...state,
        questionsList: [],
        isQuestionsListFetching: true,
        isQuestionsListFetched: false,
        isQuestionsListFetchError: false
      };
    }

    case GET_QUESTION_ANSWERS.REQUEST: {
      return {
        ...state,
        answersList: action.payload.isFetchAfterDelete ? state.answersList : [],
        isAnswersListFetching: true,
        isAnswersListFetched: false,
        isAnswersListFetchError: false
      };
    }

    case ADD_OR_UPDATE_LECTURE_QUESTIONS.REQUEST: {
      return {
        ...state,
        addedOrUpdatedQuestionId: null,
        areQuestionsSaving: true
      };
    }

    case GET_LECTURE_QUESTIONS.SUCCESS: {
      const questions = extractSubquestionsFromQuestionsInApiResponse(
        action.payload
      );

      return {
        ...state,
        questionsList: questions,
        isQuestionsListFetching: false,
        isQuestionsListFetched: true,
        isQuestionsListFetchError: false
      };
    }

    case GET_QUESTION_ANSWERS.SUCCESS: {
      return {
        ...state,
        answersList: action.payload,
        isAnswersListFetching: false,
        isAnswersListFetched: true,
        isAnswersListFetchError: false
      };
    }

    case ADD_OR_UPDATE_LECTURE_QUESTIONS.SUCCESS: {
      const newOrUpdatedQuestions = extractSubquestionsFromQuestionsInApiResponse(
        action.payload
      );

      if (
        action.payload.length === 1 &&
        !Number.isInteger(action.payload[0].number)
      ) {
        const updatedSubquestion = action.payload[0];

        const parentQuestionNumber = Math.floor(updatedSubquestion.number);
        const parentQuestionId = state.questionsList.find(
          x => x.number === parentQuestionNumber
        ).id;

        return {
          ...state,
          questionsList: state.questionsList.map(x => {
            if (x.id === parentQuestionId) {
              return {
                ...x,
                subquestions: x.subquestions.map(y => {
                  if (y.id === updatedSubquestion.id) {
                    return {
                      ...updatedSubquestion,
                      title: y.title,
                      parentQuestionTitle: y.parentQuestionTitle,
                      isFirstSubquestion: y.isFirstSubquestion
                    };
                  }

                  return y;
                })
              };
            }

            return x;
          }),
          addedOrUpdatedQuestionId: updatedSubquestion.id,
          areQuestionsSaving: false,
          areQuestionsSaved: true,
          isQuestionsSaveError: false
        };
      } else {
        const newOrUpdatedQuestionIds = new Set();
        newOrUpdatedQuestions.forEach(x => newOrUpdatedQuestionIds.add(x.id));

        const newQuestionsList = state.questionsList.map(x => {
          if (newOrUpdatedQuestionIds.has(x.id)) {
            newOrUpdatedQuestionIds.delete(x.id);
            return newOrUpdatedQuestions.find(y => y.id === x.id);
          }

          return x;
        });

        newOrUpdatedQuestionIds.forEach(x =>
          newQuestionsList.push(newOrUpdatedQuestions.find(y => y.id === x))
        );

        return {
          ...state,
          questionsList: newQuestionsList,
          addedOrUpdatedQuestionId: action.payload[0].id,
          areQuestionsSaving: false,
          areQuestionsSaved: true,
          isQuestionsSaveError: false
        };
      }
    }

    case ADD_OR_UPDATE_QUESTION_ANSWERS.SUCCESS: {
      return {
        ...state,
        answersList: action.payload,
        areAnswersSaving: false,
        areAnswersSaved: true,
        isAnswersSaveError: false
      };
    }

    case DELETE_LECTURE_QUESTION.SUCCESS: {
      return {
        ...state,
        questionsList: state.questionsList.filter(
          x => x.id !== action.payload.id
        )
      };
    }

    case DELETE_QUESTION_ANSWER.SUCCESS: {
      return {
        ...state,
        answersList: state.answersList.filter(x => x.id !== action.payload.id)
      };
    }

    case GET_LECTURE_QUESTIONS.ERROR: {
      return {
        ...state,
        isQuestionsListFetching: false,
        isQuestionsListFetched: false,
        isQuestionsListFetchError: true
      };
    }

    case GET_QUESTION_ANSWERS.ERROR: {
      return {
        ...state,
        isAnswersListFetching: false,
        isAnswersListFetched: false,
        isAnswersListFetchError: true
      };
    }

    case ADD_OR_UPDATE_LECTURE_QUESTIONS.ERROR:
    case DELETE_LECTURE_QUESTION.ERROR: {
      return {
        ...state,
        areQuestionsSaving: false,
        areQuestionsSaved: false,
        isQuestionsSaveError: true
      };
    }

    case ADD_OR_UPDATE_QUESTION_ANSWERS.ERROR:
    case DELETE_QUESTION_ANSWER.ERROR: {
      return {
        ...state,
        areAnswersSaving: false,
        areAnswersSaved: false,
        isAnswersSaveError: true
      };
    }

    case RESET_ANSWERS_STATE: {
      return {
        ...state,
        answersList: [],
        addedOrUpdatedQuestionId: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getQuestionsList = state => state.questions.questionsList;

export const getAnswersList = state => state.questions.answersList;
