import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { getIsUserAuthenticated } from "../reducers/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(getIsUserAuthenticated);
  let location = useLocation();
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <Component {...rest} />
      ) : (
        <Redirect from={location.pathname} to="/auth/login" />
      )}
    </Route>
  );
};

export default PrivateRoute;
