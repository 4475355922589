import {
  GET_COURSES,
  GET_COURSES_LOOKUP,
  GET_COURSE_DETAILS,
  ADD_COURSE,
  UPDATE_COURSE,
  DELETE_COURSE,
  CHANGE_COURSE_DATA_STATE,
  RESET_COURSE_STATE,
  RESET_COURSE_FORM_STATE,
  ADD_COURSE_TRANSLATION,
  GET_COURSE_TRANSLATION,
  RESET_COURSE_TRANSLATION_FORM_STATE,
  UPDATE_COURSE_TRANSLATION
} from "../actions/courses";

const INITIAL_STATE = {
  list: [],
  lookupList: [],
  total: 0,
  dataState: {
    sort: [],
    filter: undefined,
    skip: 0,
    take: 10
  },
  selectedItem: null,
  selectedChildItem: null,
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
  isChildSaving: false,
  isChildSaved: false,
  isError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COURSES.REQUEST:
    case GET_COURSES_LOOKUP.REQUEST:
    case GET_COURSE_DETAILS.REQUEST:
    case GET_COURSE_TRANSLATION.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }

    case ADD_COURSE.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }

    case UPDATE_COURSE.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false,
        selectedItem: { ...state.selectedItem, ...action.payload }
      };
    }

    case ADD_COURSE_TRANSLATION.REQUEST: {
      return {
        ...state,
        isChildSaving: true,
        isSaved: true,
        isChildSaved: false,
        isError: false
      };
    }
    case UPDATE_COURSE_TRANSLATION.REQUEST: {
      return {
        ...state,
        isChildSaving: true,
        isSaved: true,
        isChildSaved: false,
        isError: false,
        selectedChildItem: { ...state.selectedChildItem, ...action.payload }
      };
    }
    case GET_COURSE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        selectedChildItem: action.payload,
        isFetching: false,
        isFetched: true,
        isError: false,
        isSaved: true,
        isChildSaved: true
      };
    }

    case GET_COURSES.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        list: action.payload.data,
        total: action.payload.total
      };
    }

    case GET_COURSES_LOOKUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        lookupList: action.payload
      };
    }

    case GET_COURSE_DETAILS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        selectedItem: action.payload,
        selectedChildItem: {
          languageId:
            action.payload.allLanguagesWithOrWithoutThisCourse?.[0].languageId
        }
      };
    }

    case ADD_COURSE.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        isSaving: false,
        isSaved: true,
        isError: false,
        selectedChildItem: {
          languageId:
            action.payload.allLanguagesWithOrWithoutThisCourse?.[0].languageId
        }
      };
    }

    case UPDATE_COURSE.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }

    case ADD_COURSE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        isChildSaving: false,
        isSaved: true,
        isChildSaved: true,
        isError: false,
        selectedChildItem: action.payload,
        selectedItem: {
          ...state.selectedItem,
          allLanguagesWithOrWithoutThisCourse: state.selectedItem.allLanguagesWithOrWithoutThisCourse.map(
            lang => {
              return lang.languageId === action.payload.languageId
                ? { ...lang, courseLanguageId: action.payload.id }
                : lang;
            }
          )
        }
      };
    }
    case UPDATE_COURSE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        isChildSaving: false,
        isSaved: true,
        isChildSaved: true,
        isError: false
      };
    }

    case DELETE_COURSE.SUCCESS: {
      return {
        ...state,
        isError: false
        // list: state.list.filter((item) => item.id !== action.payload.id),
      };
    }

    case GET_COURSES.ERROR:
    case GET_COURSE_DETAILS.ERROR:
    case ADD_COURSE.ERROR:
    case ADD_COURSE_TRANSLATION.ERROR:
    case UPDATE_COURSE.ERROR:
    case DELETE_COURSE.ERROR:
    case GET_COURSES_LOOKUP.ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isError: true
      };
    }

    case CHANGE_COURSE_DATA_STATE: {
      return {
        ...state,
        dataState: action.payload
      };
    }

    case RESET_COURSE_STATE: {
      return {
        ...INITIAL_STATE
      };
    }

    case RESET_COURSE_FORM_STATE: {
      return {
        ...state,
        selectedItem: null,
        selectedChildItem: null,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isChildSaving: false,
        isChildSaved: false,
        isError: false
      };
    }

    case RESET_COURSE_TRANSLATION_FORM_STATE: {
      return {
        ...state,
        selectedChildItem: action.payload
          ? { languageId: action.payload }
          : null,
        isChildSaving: false,
        isChildSaved: false
      };
    }

    default:
      return state;
  }
}

export const getCoursesList = state => state.courses.list;
export const getCoursesListDataState = state => state.courses.dataState;
export const getCoursesListTotalNumber = state => state.courses.total;

export const getSelectedCourse = state => state.courses.selectedItem;
export const getIsCourseSaved = state => state.courses.isSaved;
export const getLanguagesForSelectedCourse = state =>
  state.courses.selectedItem?.allLanguagesWithOrWithoutThisCourse;

export const getSelectedCourseTranslation = state =>
  state.courses.selectedChildItem;

export const getCoursesLookupList = state => state.courses.lookupList;
