import { GridCell } from "@progress/kendo-react-grid";
import BooleanCell from "../../../../components/GridCell/BooleanCell";
export default [
  {
    name: "id",
    title: "ID",
    cellType: GridCell,
    sortable: true,
    filterable: false
  },
  {
    name: "name",
    title: "Name",
    cellType: GridCell,
    sortable: true,
    filterable: true
  },
  {
    name: "course",
    title: "Course",
    cellType: GridCell,
    sortable: true,
    filterable: true
  },
  {
    name: "isHomePage",
    title: "Home Page",
    cellType: BooleanCell,
    sortable: true,
    filterable: false
  },
  {
    name: "isActive",
    title: "Active",
    cellType: BooleanCell,
    sortable: true,
    filterable: false
  },
  {
    name: "isShownInMenu",
    title: "Shown in menu",
    cellType: BooleanCell,
    sortable: true,
    filterable: false
  },
  {
    name: "isDeleteOption",
    title: "Can be deleted",
    cellType: BooleanCell,
    sortable: true,
    filterable: false
  },
  {
    name: "currentTranslations",
    title: "Translations",
    cellType: GridCell,
    sortable: false,
    filterable: false
  }
];
