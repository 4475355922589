import { useState, useEffect, useMemo } from "react";
import { formatDate } from "@telerik/kendo-intl";

import {
  isValidText,
  isValidNumber,
  isValidEmail,
  passwordPolicyValidation,
  comparePasswords,
  validatePhoneNumber
} from "../services/utilities/validations";
import {
  TEXT,
  NUMBER,
  EMAIL,
  PASSWORD,
  PHONE,
  CHECKBOX,
  MULTILINE_TEXT,
  RICH_TEXT
} from "../constants/formInputTypes";
import { RADIO } from "../constants/dropDownTypes";

const validate = ({ meta, value, currentValidations = {} }) => {
  const { type, isRequired, lookupType } = meta;
  switch (type) {
    case TEXT:
    case MULTILINE_TEXT:
    case RICH_TEXT: {
      if (isRequired) {
        const isValidTextType = isValidText(value);
        return {
          ...currentValidations,
          type,
          isValid: isValidTextType,
          errorMessage: !isValidTextType ? "This field is required." : ""
        };
      }

      return null;
    }

    case NUMBER: {
      if (isRequired) {
        return {
          ...currentValidations,
          type,
          isValid: isValidNumber(value),
          errorMessage: !isValidNumber(value)
            ? lookupType
              ? "This field is required."
              : "Wrong input - should be number."
            : ""
        };
      }

      return null;
    }
    case EMAIL: {
      if (isRequired) {
        const isValidTextType = isValidText(value);
        const isValidEmailFormat = isValidEmail(value);
        return {
          ...currentValidations,
          type,
          isValid: isValidTextType && isValidEmailFormat,
          errorMessage: !isValidTextType
            ? "This field is required."
            : !isValidEmailFormat
            ? "Email format is invalid."
            : ""
        };
      }

      return null;
    }
    case PASSWORD: {
      const isValidTextType = isValidText(value);
      const isValidPasswordFormat = passwordPolicyValidation(value);
      if (isRequired) {
        return {
          ...currentValidations,
          type,
          isValid: isValidTextType && isValidPasswordFormat,
          errorMessage: !isValidTextType
            ? "This field is required."
            : !isValidPasswordFormat
            ? "Password requires at least one uppercase letter, one number, one special character and min length of 8 characters."
            : ""
        };
      }

      return null;
    }
    case PHONE: {
      const isValidPhoneNumber = validatePhoneNumber(value);

      return {
        ...currentValidations,
        type,
        isValid: isValidPhoneNumber,
        errorMessage: !isValidPhoneNumber
          ? "Please enter valid phone number or leave field blank."
          : ""
      };
    }

    default: {
      return null;
    }
  }
};

const validatePasswords = ({
  password,
  confirmPassword,
  currentValidations = {}
}) => {
  let passwordMatch = comparePasswords(password, confirmPassword);
  if (passwordMatch) {
    return {
      ...currentValidations,
      type: PASSWORD,
      isValid: passwordMatch,
      errorMessage: ""
    };
  } else {
    return {
      ...currentValidations,
      type: PASSWORD,
      isValid: passwordMatch,
      errorMessage: "Password and confirm password do not match!"
    };
  }
};

const useForm = ({
  initialValues = {},
  defaultEmptyValues,
  onInputChange = () => {},
  onSubmit = () => {}
}) => {
  const [values, setValues] = useState({});
  const [validations, setValidations] = useState({});

  const startValues = useMemo(() => {
    return Object.keys(initialValues).reduce((result, key) => {
      result[key] = initialValues[key]?.value;
      return result;
    }, {});
  }, [initialValues]);
  const startValidations = useMemo(() => {
    return Object.entries(initialValues).reduce((result, [key, value]) => {
      if (result.hasOwnProperty(key)) return result;

      result[key] = validate({
        meta: value?.meta,
        value: value?.value,
        currentValidations: {
          isTouched: false,
          isFocused: false
        }
      });

      // Check if this property excludes any other.
      // If it does and if it's valid, set all excluded
      // properties to valid in order not to block form submitting.
      if (value?.meta.excludes && result[key].isValid) {
        let propNames = value.meta.excludes.split(",");
        propNames.forEach(prop => {
          result[prop] = validate({
            meta: initialValues[prop].meta,
            value: initialValues[prop].value,
            currentValidations: {
              isTouched: false,
              isFocused: false
            }
          });
          result[prop].isValid = true;
          result[prop].errorMessage = "";
        });
      }

      return result;
    }, {});
  }, [initialValues]);

  useEffect(() => {
    setValues(startValues);
    setValidations(startValidations);
  }, [startValues, startValidations]);

  const checkAndChangeNewPropertyValueIfItViolatesMinOrMaxRestrictions = (
    changedPropName,
    changedPropNewValue
  ) => {
    let referentPropertyName;
    let referentPropertyValue;

    if (
      (referentPropertyName =
        initialValues[changedPropName].meta.fieldForMinValue) &&
      (referentPropertyValue = values[referentPropertyName]) &&
      changedPropNewValue < referentPropertyValue
    ) {
      return {
        [changedPropName]: referentPropertyValue
      };
    }

    if (
      (referentPropertyName =
        initialValues[changedPropName].meta.fieldForMaxValue) &&
      (referentPropertyValue = values[referentPropertyName]) &&
      changedPropNewValue > referentPropertyValue
    ) {
      return {
        [changedPropName]: referentPropertyValue
      };
    }
  };

  const checkAndSetDependentPropertiesBoundWithMinOrMaxValue = (
    changedPropName,
    changedPropNewValue
  ) => {
    return Object.entries(initialValues).reduce((result, [key, value]) => {
      if (
        value.meta.fieldForMinValue === changedPropName &&
        values[key] < changedPropNewValue
      ) {
        result[key] = changedPropNewValue;
      }

      if (
        value.meta.fieldForMaxValue === changedPropName &&
        values[key] > changedPropNewValue
      ) {
        result[key] = changedPropNewValue;
      }

      return result;
    }, {});
  };

  const handleInputChange = (event, valueExtractorKey) => {
    const { name, type, checked, value, dateInput } = event.target;

    // Some used input types (e.g. FileUpload) don't have
    // the 'name' prop (they don't pass it in event.target),
    // so we just call parent's onInputChange method.
    if (!name) {
      onInputChange();
      return;
    }
    const inputValue =
      type === CHECKBOX
        ? checked
        : type === RADIO && value !== null
        ? parseInt(value)
        : dateInput // this is kendo date object property which we check if exists
        ? // if the user manually types dates (without opening the datepicker),
          // value becomes null if the date is not valid (e.g. if one of the parts is from placeholder)
          value
          ? formatDate(value, "yyyy-MM-ddTHH:mm:ss")
          : value
        : // we added the check for value because typeof null === 'object'
        typeof value === "object" &&
          !Array.isArray(value) &&
          value &&
          valueExtractorKey
        ? value[valueExtractorKey]
        : typeof value === "object" &&
          Array.isArray(value) &&
          value &&
          valueExtractorKey
        ? Array.from(value.map(x => x[valueExtractorKey]))
        : value;

    // Check if there are properties excluded by the current one.
    // If there are, reset their value.
    let excludedProperties = {};
    if (initialValues[name].meta.excludes) {
      let propNames = initialValues[name].meta.excludes.split(",");
      propNames.reduce((result, key) => {
        if (!initialValues.hasOwnProperty(key)) return result;

        result[key] = defaultEmptyValues
          ? defaultEmptyValues[key]
          : initialValues[key].value;
        return result;
      }, excludedProperties);
    }

    setValues(prevState => {
      return {
        ...prevState,
        [name]: inputValue,
        ...checkAndChangeNewPropertyValueIfItViolatesMinOrMaxRestrictions(
          name,
          inputValue
        ),
        ...checkAndSetDependentPropertiesBoundWithMinOrMaxValue(
          name,
          inputValue
        ),
        ...excludedProperties
      };
    });

    if (initialValues[name]) {
      let newValidations = {
        [name]:
          name === "confirmPassword" ||
          (name === "password" && values.confirmPassword)
            ? validatePasswords({
                password: name === "password" ? inputValue : values.password,
                confirmPassword:
                  name !== "password" ? inputValue : values.confirmPassword,
                currentValidations: validations[name]
              })
            : validate({
                meta: initialValues[name]?.meta,
                value: inputValue,
                currentValidations: validations[name]
              })
      };

      // If there are properties excluded by the current one,
      // set their validity and error message to match the current one's.
      // (If the current one is valid, all others have to be valid in order
      // not to block form submitting.)
      Object.keys(excludedProperties).forEach(
        key =>
          (newValidations[key] = {
            ...validations[key],
            isValid: newValidations[name].isValid,
            errorMessage: newValidations[name].errorMessage
          })
      );

      setValidations(prevState => {
        return {
          ...prevState,
          ...newValidations
        };
      });
    }

    onInputChange(name, inputValue);
  };

  const onContentEditorChange = (name, content) => {
    setValues(prevState => {
      return {
        ...prevState,
        [name]: content
      };
    });
    if (initialValues[name]) {
      setValidations(prevState => {
        return {
          ...prevState,
          [name]: validate({
            meta: initialValues[name]?.meta,
            value: content,
            currentValidations: validations[name]
          })
        };
      });
    }
    onInputChange();
  };

  const handleInputFocus = prop => () => {
    setValidations({
      ...validations,
      [prop]: {
        ...validations[prop],
        isTouched: !validations[prop].isTouched
          ? true
          : validations[prop].isTouched,
        isFocused: !validations[prop].isFocused
      }
    });
  };

  const isFormValid = Object.values(validations)
    .filter(x => x !== null)
    .every(x => x?.isValid === true);

  const handleFormSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    onSubmit();
  };

  const resetForm = () => {
    setValues(startValues);
    setValidations(startValidations);
  };

  return {
    values,
    validations,
    handleInputFocus,
    handleInputChange,
    onContentEditorChange,
    isFormValid,
    handleFormSubmit,
    resetForm
  };
};

export default useForm;
