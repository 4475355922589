import { callApi } from "../services/api/api";
import generateActions from "../services/utilities/generateActions";
import {
  loginUser as loginUserApi,
  refreshToken as refreshTokenApi
} from "../pages/auth/apiConfig";

export const LOGIN_USER = generateActions("LOGIN_USER");
export const REFRESH_TOKEN = generateActions("REFRESH_TOKEN");
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";

export const loginUser = authData => {
  return {
    type: LOGIN_USER.ACTION,
    promise: callApi(loginUserApi(authData)),
    payload: authData
  };
};

export const refreshToken = tokens => {
  return {
    type: REFRESH_TOKEN.ACTION,
    promise: callApi(refreshTokenApi(tokens)),
    payload: tokens
  };
};

export const logoutUser = () => {
  return {
    type: RESET_AUTH_STATE
  };
};

export const resetAuthState = () => {
  return {
    type: RESET_AUTH_STATE
  };
};
