export const stringTruncate = initialString => {
  let truncatedString = "";
  if (initialString.length > 30) {
    truncatedString = initialString.substring(0, 30) + "...";
  }

  return truncatedString;
};

export const avatarInitials = string => {
  if (string) {
    let initials = string.match(/\b(\w)/g);
    return initials.join("");
  } else {
    return null;
  }
};

// export const formatString = (string, required = false) => {
//   if (string) {
//     if (string.indexOf("Id") !== -1) {
//       string = string.replace("Id", "");
//     }
//     let splitStringByCapitalLetters = string.split(/(?=[A-Z])/).join(" ");

//     if (splitStringByCapitalLetters.indexOf("Id") !== -1) {
//       splitStringByCapitalLetters = splitStringByCapitalLetters.replace(
//         "Id",
//         ""
//       );
//     }
//     let formattedString =
//       splitStringByCapitalLetters.charAt(0).toUpperCase() +
//       splitStringByCapitalLetters.slice(1).toLowerCase();
//     if (required) {
//       return `${formattedString} *`;
//     } else {
//       return formattedString;
//     }
//   }
// };

export const formatLabel = (label, required = false) => {
  return required ? `${label} *` : label;
};
