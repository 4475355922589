export const TEXT = "text";
export const MULTILINE_TEXT = "multiline_text";
export const RICH_TEXT = "rich_text";
export const NUMBER = "number";
export const EMAIL = "email";
export const DATE = "date";
export const PASSWORD = "password";
export const BOOL = "bool";
export const PHONE = "phone";
export const CHECKBOX = "checkbox";
export const FILE = "file";
