const gridStyle = {
  container: {
    margin: "0 -15px",
    width: "calc(100% + 30px)"
  },
  item: {
    padding: "0 15px !important"
  }
};

export default gridStyle;
