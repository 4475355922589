import { LOGIN_USER, RESET_AUTH_STATE, REFRESH_TOKEN } from "../actions/auth";

const INITIAL_STATE = {
  token: null,
  refreshToken: null,
  tokenExpiry: null,
  user: {},
  isLogging: false,
  isAuthenticated: false,
  isError: false
};
export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER.REQUEST:
      return { ...state, isLogging: true };
    case LOGIN_USER.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLogging: false,
        isAuthenticated: true
      };
    case REFRESH_TOKEN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true
      };
    case LOGIN_USER.ERROR:
    case REFRESH_TOKEN.ERROR:
      return { ...INITIAL_STATE, isError: true };
    case RESET_AUTH_STATE:
      return { ...INITIAL_STATE };
    default:
      return { ...state };
  }
};

export default auth;

export const getUser = state => state.auth?.user;
export const getIsUserAuthenticated = state => state.auth.isAuthenticated;

export const getToken = state => state.auth.token;
