import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/components/Card/cardTextStyle";

const useStyles = makeStyles(styles);

const CardText = props => {
  const classes = useStyles();

  const { className, children, color, ...rest } = props;

  const cardTextClasses = classnames({
    [classes.cardText]: true,
    [classes[color + "CardHeader"]]: color,
    [className]: className !== undefined
  });

  return (
    <div className={cardTextClasses} {...rest}>
      {children}
    </div>
  );
};

CardText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  children: PropTypes.node
};

export default CardText;
