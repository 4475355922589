import React from "react";
import { Upload } from "@progress/kendo-react-upload";
import { makeStyles } from "@material-ui/core/styles";

import FormElement from "./FormElement";
import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const FileUpload = ({
  files = [],
  label = "",
  saveUrl = "",
  removeUrl = "",
  isDisabled = false,
  onAdd = () => {},
  onRemove = () => {}
}) => {
  const classes = useStyles();

  return (
    <FormElement label={label}>
      <Upload
        className={classes.fileUpload}
        files={files}
        disabled={isDisabled}
        autoUpload={false}
        multiple={false}
        showFileList={true}
        showActionButtons={false}
        saveUrl={saveUrl}
        removeUrl={removeUrl}
        withCredentials={false}
        restrictions={{
          allowedExtensions: [
            ".jpg",
            ".png",
            ".jpeg",
            ".gif",
            ".pdf",
            ".docx",
            ".pptx",
            ".xlsx"
          ],
          // max file size: 10 MB
          maxFileSize: 10485760
        }}
        onAdd={onAdd}
        onRemove={onRemove}
      />
    </FormElement>
  );
};

export default FileUpload;
