import { callApi } from "../services/api/api";

import { getLanguages as getLanguagesApi } from "../features/languages/apiConfig";
import generateActions from "../services/utilities/generateActions";
export const GET_LANGUAGES = generateActions("GET_LANGUAGES");

export const getLanguages = data => {
  return {
    type: GET_LANGUAGES.ACTION,
    promise: callApi(getLanguagesApi(data)),
    payload: data
  };
};
