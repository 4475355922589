const linkListStyles = {
  root: {
    marginTop: "2rem"
  },
  containerHeader: {
    color: "rgb(160, 160, 160)",
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: "10px"
  },
  main: {
    padding: "10px",
    display: "flex",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    margin: 0
  },
  copyButton: {
    alignSelf: "flex-end",
    fontSize: "1rem",
    marginLeft: "10px",
    paddingTop: "2px",
    fontStyle: "italic"
  },
  containerFooter: {
    color: "rgb(160, 160, 160)",
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: "10px"
  },
  infoText: {
    fontSize: 14,
    color: "#454545",
    marginBottom: 0
  }
};

export default linkListStyles;
