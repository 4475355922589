import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import promiseMiddleware from "../middlewares/promiseMiddleware";
import rootReducer from "../reducers";
import { getAuthStateFromStorage } from "../services/utilities/storage";

const authStorage = getAuthStateFromStorage();
const auth = authStorage?.token && { ...authStorage, isAuthenticated: true };
export const store = createStore(
  rootReducer,
  { ...rootReducer, auth },
  composeWithDevTools(applyMiddleware(thunk, promiseMiddleware))
);
