import { callApi } from "../services/api/api";

import {
  getCourses as getCoursesApi,
  getCoursesLookup as getCoursesLookupApi,
  getCourseDetails as getCourseDetailsApi,
  getCourseTranslation as getCourseTranslationApi,
  addCourse as addCourseApi,
  updateCourse as updateCourseApi,
  deleteCourse as deleteCourseApi,
  addCourseTranslation as addCourseTranslationApi,
  updateCourseTranslation as updateCourseTranslationApi
} from "../pages/admin/courses/apiConfig";
import generateActions from "../services/utilities/generateActions";

export const GET_COURSES = generateActions("GET_COURSES");
export const GET_COURSES_LOOKUP = generateActions("GET_COURSES_LOOKUP");
export const GET_COURSE_DETAILS = generateActions("GET_COURSE_DETAILS");
export const GET_COURSE_TRANSLATION = generateActions("GET_COURSE_TRANSLATION");
export const ADD_COURSE = generateActions("ADD_COURSE");
export const UPDATE_COURSE = generateActions("UPDATE_COURSE");
export const DELETE_COURSE = generateActions("DELETE_COURSE");

export const ADD_COURSE_TRANSLATION = generateActions("ADD_COURSE_TRANSLATION");
export const UPDATE_COURSE_TRANSLATION = generateActions(
  "UPDATE_COURSE_TRANSLATION"
);

export const CHANGE_COURSE_DATA_STATE = "CHANGE_COURSE_DATA_STATE";
export const RESET_COURSE_STATE = "RESET_COURSE_STATE";
export const RESET_COURSE_FORM_STATE = "RESET_COURSE_FORM_STATE";
export const RESET_COURSE_TRANSLATION_FORM_STATE =
  "RESET_COURSE_TRANSLATION_FORM_STATE";

export function getCourses(dataState) {
  return {
    type: GET_COURSES.ACTION,
    promise: callApi(getCoursesApi(dataState)),
    payload: dataState
  };
}
export function getCoursesLookup() {
  return {
    type: GET_COURSES_LOOKUP.ACTION,
    promise: callApi(getCoursesLookupApi())
  };
}

export function getCourseDetails(id) {
  return {
    type: GET_COURSE_DETAILS.ACTION,
    promise: callApi(getCourseDetailsApi(id)),
    payload: id
  };
}

export function addCourse(data) {
  return {
    type: ADD_COURSE.ACTION,
    promise: callApi(addCourseApi(data)),
    payload: data
  };
}

export function updateCourse(data) {
  return {
    type: UPDATE_COURSE.ACTION,
    promise: callApi(updateCourseApi(data)),
    payload: data
  };
}

export function deleteCourse(id) {
  return {
    type: DELETE_COURSE.ACTION,
    promise: callApi(deleteCourseApi(id)),
    payload: id
  };
}
export function deleteCourseAndUpdateGrid(id, dataState) {
  return dispatch => {
    return dispatch(deleteCourse(id)).then(() =>
      dispatch(getCourses(dataState))
    );
  };
}

export function getCourseTranslation(data) {
  return {
    type: GET_COURSE_TRANSLATION.ACTION,
    promise: callApi(getCourseTranslationApi(data)),
    payload: data
  };
}

export function addCourseTranslation(data) {
  return {
    type: ADD_COURSE_TRANSLATION.ACTION,
    promise: callApi(addCourseTranslationApi(data)),
    payload: data
  };
}

export function updateCourseTranslation(data) {
  return {
    type: UPDATE_COURSE_TRANSLATION.ACTION,
    promise: callApi(updateCourseTranslationApi(data)),
    payload: data
  };
}

export function changeCourseDataState(dataState) {
  return {
    type: CHANGE_COURSE_DATA_STATE,
    payload: dataState
  };
}

/**
 * Resets the whole course state in store to initial state.
 */
export function resetCourseState() {
  return {
    type: RESET_COURSE_STATE
  };
}

/**
 * Resets all data connected to course and translation forms.
 */
export function resetCourseFormState() {
  return {
    type: RESET_COURSE_FORM_STATE
  };
}

/**
 * Only resets the fields related to the translation form.
 * Accepts new language ID for memorizing it while
 * changing languages in dropdown and reseting the rest
 * of the form.
 * @param {Object} [data]
 * @param {number} data.data
 */
export function resetCourseTranslationFormState({ data }) {
  return {
    type: RESET_COURSE_TRANSLATION_FORM_STATE,
    payload: data
  };
}

/**
 * Used for autosaving changes to the selected translation
 * when changing languages in the dropdown. It first creates
 * the translation and then resets fields related to the
 * translation form, with the ID of the newly selected language.
 * @param {Object} data
 * @param {Object} data.translation
 * @param {number} data.newLanguageId
 */
export function addCourseTranslationThenResetTranslationForm({
  translation,
  newLanguageId
}) {
  return dispatch => {
    return dispatch(addCourseTranslation(translation)).then(() =>
      dispatch(resetCourseTranslationFormState({ data: newLanguageId }))
    );
  };
}

/**
 * Used for autosaving changes to the selected translation
 * when changing languages in the dropdown. It first updates
 * the translation and then resets fields related to the
 * translation form, with the ID of the newly selected language.
 * @param {Object} data
 * @param {Object} data.translation
 * @param {number} data.newLanguageId
 */
export function updateCourseTranslationThenResetTranslationForm({
  translation,
  newLanguageId
}) {
  return dispatch => {
    return dispatch(updateCourseTranslation(translation)).then(() =>
      dispatch(resetCourseTranslationFormState({ data: newLanguageId }))
    );
  };
}

/**
 * Used when saving changes on the course form and navigating back to list.
 * It first creates the course and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.course
 */
export function addCourseThenResetForm({ course }) {
  return dispatch => {
    return dispatch(addCourse(course)).then(() =>
      dispatch(resetCourseFormState())
    );
  };
}

/**
 * Used when saving changes on the course form and navigating back to list.
 * It first updates the course and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.course
 */
export function updateCourseThenResetForm({ course }) {
  return dispatch => {
    return dispatch(updateCourse(course)).then(() =>
      dispatch(resetCourseFormState())
    );
  };
}

/**
 * Used when saving changes on the translation form and navigating back to list.
 * It first creates the translation and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.translation
 */
export function addCourseTranslationThenResetForm({ translation }) {
  return dispatch => {
    return dispatch(addCourseTranslation(translation)).then(() =>
      dispatch(resetCourseFormState())
    );
  };
}

/**
 * Used when saving changes on the translation form and navigating back to list.
 * It first updates the translation and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.translation
 */
export function updateCourseTranslationThenResetForm({ translation }) {
  return dispatch => {
    return dispatch(updateCourseTranslation(translation)).then(() =>
      dispatch(resetCourseFormState())
    );
  };
}

/**
 * Used when saving changes on both forms and navigating back to list.
 * It first updates the course, then creates the translation
 * and in the end resets all fields related to both forms.
 * @param {Object} data
 * @param {Object} data.course
 * @param {Object} data.translation
 */
export function updateCourseThenAddTranslationThenResetForm({
  course,
  translation
}) {
  return dispatch => {
    return dispatch(updateCourse(course)).then(() =>
      dispatch(addCourseTranslation(translation)).then(() =>
        dispatch(resetCourseFormState())
      )
    );
  };
}

/**
 * Used when saving changes on both forms and navigating back to list.
 * It first updates the course, then updates the translation
 * and in the end resets all fields related to both forms.
 * @param {Object} data
 * @param {Object} data.course
 * @param {Object} data.translation
 */
export function updateCourseThenUpdateTranslationThenResetForm({
  course,
  translation
}) {
  return dispatch => {
    return dispatch(updateCourse(course)).then(() =>
      dispatch(updateCourseTranslation(translation)).then(() =>
        dispatch(resetCourseFormState())
      )
    );
  };
}
