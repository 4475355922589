import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import Lecture from "./Lecture";
import Questions from "./questions/Questions";
import styles from "../../../../assets/jss/pages/formStyle";

const useStyles = makeStyles(styles);

const LecturesFormContainer = () => {
  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { id } = useParams();

  const handleTabSelect = event => {
    setSelectedTabIndex(event.selected);
  };

  return (
    <TabStrip
      className={classes.tabStrip}
      selected={selectedTabIndex}
      onSelect={handleTabSelect}
    >
      <TabStripTab title="Lecture">
        <Lecture />
      </TabStripTab>

      <TabStripTab title="Questions" disabled={!id}>
        <Questions />
      </TabStripTab>
    </TabStrip>
  );
};

export default LecturesFormContainer;
