import React from "react";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FormElement from "./FormElement";
import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const MaskedInput = ({
  label = "",
  name = "",
  value = "",
  isRequired = false,
  isTouched = false,
  isFocused = false,
  isValid = false,
  errorMessage = "",
  onFocus = () => {},
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyles();

  const handleBlur = event => {
    isRequired && onFocus(event);
  };

  return (
    <FormElement label={label}>
      <MaskedTextBox
        className={classes.maskedInput}
        name={name}
        value={value || ""}
        onChange={onChange}
        required={isRequired}
        onBlur={handleBlur}
        // TODO: odkomentirati nakon sto se refaktorira user forma
        // validityStyles={isRequired && isTouched ? true : false}
        valid={isValid}
        // valid={isRequired && isTouched ? isValid : undefined}
        {...rest}
      />

      {/* TODO: zamijeniti komponentu sa zakomentiranom
      nakon sto se refaktorira user forma */}
      {!isValid || (isRequired && isTouched) ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}

      {/* {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null} */}
    </FormElement>
  );
};

export default MaskedInput;
