import React, { useRef, useState } from "react";
import {
  ListView,
  ListViewHeader,
  ListViewFooter
} from "@progress/kendo-react-listview";

import InfoListItem from "./InfoListItem";
import LinkListItem from "./LinkListItem";

import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/components/linkListStyle";

const useStyles = makeStyles(styles);

const List = ({ data = [], title = "", listType = "" }) => {
  const [copySuccess, setCopySuccess] = useState([]);
  const linkRef = useRef([]);

  const copyToClipBoard = (e, id) => {
    setCopySuccess([]);
    window.getSelection().removeAllRanges();
    var range = document.createRange();
    range.selectNode(linkRef.current[id]);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    setCopySuccess(prevState => {
      return {
        ...prevState,
        [id]: "Copied to clipboard!"
      };
    });
  };

  const classes = useStyles();
  const Header = () => {
    return (
      <ListViewHeader className={classes.containerHeader}>
        {title}
      </ListViewHeader>
    );
  };

  const Footer = () => {
    return (
      <ListViewFooter className={classes.containerFooter}>
        {`Total count: ${data.length}`}
      </ListViewFooter>
    );
  };
  return (
    <div className={classes.root}>
      <ListView
        data={data}
        item={
          listType === "info"
            ? InfoListItem
            : listType === "link"
            ? props => (
                <LinkListItem
                  copyToClipBoard={copyToClipBoard}
                  copySuccess={copySuccess}
                  linkRef={linkRef}
                  {...props}
                />
              )
            : null
        }
        style={{ width: "100%" }}
        header={Header}
        footer={Footer}
      />
    </div>
  );
};

export default List;
