import { useState } from "react";

const useFocus = () => {
  const [isTouched, setIsTouched] = useState(false);

  const handleFocus = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  return [isTouched, handleFocus];
};

export default useFocus;
