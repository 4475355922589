export const loginUser = authData => {
  return {
    path: "auth/login",
    method: "POST",
    data: authData,
    successNotificationConfig: {
      message: `Welcome to MedOpen ${authData?.userName || ""}`
    }
  };
};

export const refreshToken = tokens => {
  return {
    path: "auth/token",
    method: "POST",
    data: tokens
  };
};
