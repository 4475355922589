import { displayNotification } from "../utilities/notifications";

export function handleResponseSuccess(config = {}) {
  displayNotification({
    content: config.message || "Success!",
    type: "success",
    autoClose: config.duration || 5000
  });
}

export function handleResponseError(error = null) {
  // Server error or network connection error
  if (error?.status === 500) {
    displayNotification({
      content: error?.data?.detail || "Server error!",
      type: "error",
      autoClose: 5000
    });
  } else if (error?.status === 400) {
    displayNotification({
      content: error?.data?.detail || "Something is wrong with provided data!",
      type: "error",
      autoClose: 5000
    });
  } else if (error?.status === 401) {
    displayNotification({
      content: error?.data?.detail || "Session expired!",
      type: "error",
      autoClose: 5000,
      notificationId: "session-expired"
    });
  } else if (error?.status === 404) {
    displayNotification({
      content: error?.data?.detail || "Item not found!",
      type: "error",
      autoClose: 5000
    });
  } else {
    displayNotification({
      content: error?.data?.detail || "Something went wrong!",
      type: "error",
      autoClose: 5000
    });
  }
}
