import {
  GET_LECTURES,
  GET_LECTURE_DETAILS,
  ADD_LECTURE,
  UPDATE_LECTURE,
  DELETE_LECTURE,
  CHANGE_LECTURE_DATA_STATE,
  RESET_LECTURE_STATE,
  RESET_LECTURE_FORM_STATE,
  GET_LECTURE_TRANSLATION,
  ADD_LECTURE_TRANSLATION,
  UPDATE_LECTURE_TRANSLATION,
  RESET_LECTURE_TRANSLATION_FORM_STATE,
  GET_LECTURE_TRANSLATIONS_LOOKUP
} from "../actions/lectures";

const INITIAL_STATE = {
  list: [],
  lookupList: [],
  total: 0,
  dataState: {
    sort: [],
    filter: undefined,
    skip: 0,
    take: 10
  },
  selectedItem: null,
  selectedChildItem: null,
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
  isChildSaving: false,
  isChildSaved: false,
  isError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LECTURES.REQUEST:
    case GET_LECTURE_DETAILS.REQUEST:
    case GET_LECTURE_TRANSLATION.REQUEST:
    case GET_LECTURE_TRANSLATIONS_LOOKUP.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }

    case GET_LECTURES.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        list: action.payload.data,
        total: action.payload.total
      };
    }

    case GET_LECTURE_DETAILS.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        selectedChildItem: {
          languageId:
            action.payload.allLanguagesWithOrWithoutThisLecture?.[0].languageId
        },
        isFetching: false,
        isFetched: true,
        isError: false
      };
    }

    case GET_LECTURE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        selectedChildItem: action.payload,
        isFetching: false,
        isFetched: true,
        isError: false,
        isSaved: true,
        isChildSaved: true
      };
    }

    case GET_LECTURE_TRANSLATIONS_LOOKUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        lookupList: action.payload
      };
    }

    case ADD_LECTURE.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }

    case ADD_LECTURE_TRANSLATION.REQUEST: {
      return {
        ...state,
        isChildSaving: true,
        isSaved: true,
        isChildSaved: false,
        isError: false
      };
    }

    case ADD_LECTURE.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        selectedChildItem: {
          languageId:
            action.payload.allLanguagesWithOrWithoutThisLecture?.[0].languageId
        },
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }

    case ADD_LECTURE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        isChildSaving: false,
        isSaved: true,
        isChildSaved: true,
        isError: false,
        selectedChildItem: action.payload,
        selectedItem: {
          ...state.selectedItem,
          allLanguagesWithOrWithoutThisLecture: state.selectedItem.allLanguagesWithOrWithoutThisLecture.map(
            lang => {
              return lang.languageId === action.payload.languageId
                ? { ...lang, lectureLanguageId: action.payload.id }
                : lang;
            }
          )
        }
      };
    }

    case UPDATE_LECTURE.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false,
        selectedItem: { ...state.selectedItem, ...action.payload }
      };
    }

    case UPDATE_LECTURE_TRANSLATION.REQUEST: {
      return {
        ...state,
        isChildSaving: true,
        isSaved: true,
        isChildSaved: false,
        isError: false,
        selectedChildItem: { ...state.selectedChildItem, ...action.payload }
      };
    }

    case UPDATE_LECTURE.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }

    case UPDATE_LECTURE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        isChildSaving: false,
        isSaved: true,
        isChildSaved: true,
        isError: false
      };
    }

    case DELETE_LECTURE.SUCCESS: {
      return {
        ...state,
        isError: false
        // list: state.list.filter(item => item.id !== action.payload.id)
      };
    }

    case GET_LECTURES.ERROR:
    case GET_LECTURE_DETAILS.ERROR:
    case GET_LECTURE_TRANSLATION.ERROR:
    case GET_LECTURE_TRANSLATIONS_LOOKUP.ERROR:
    case ADD_LECTURE.ERROR:
    case ADD_LECTURE_TRANSLATION.ERROR:
    case UPDATE_LECTURE.ERROR:
    case UPDATE_LECTURE_TRANSLATION.ERROR:
    case DELETE_LECTURE.ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isError: true
      };
    }

    case CHANGE_LECTURE_DATA_STATE: {
      return {
        ...state,
        dataState: action.payload
      };
    }

    case RESET_LECTURE_STATE: {
      return {
        ...INITIAL_STATE
      };
    }

    case RESET_LECTURE_FORM_STATE: {
      return {
        ...state,
        selectedItem: null,
        selectedChildItem: null,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isChildSaving: false,
        isChildSaved: false,
        isError: false
      };
    }

    case RESET_LECTURE_TRANSLATION_FORM_STATE: {
      return {
        ...state,
        selectedChildItem: action.payload
          ? { languageId: action.payload }
          : null,
        isChildSaved: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getLecturesList = state => state.lectures.list;
export const getLecturesListDataState = state => state.lectures.dataState;
export const getLecturesListTotalNumber = state => state.lectures.total;

export const getSelectedLecture = state => state.lectures.selectedItem;
export const getIsLectureSaved = state => state.lectures.isSaved;
export const getLanguagesForSelectedLecture = state =>
  state.lectures.selectedItem?.allLanguagesWithOrWithoutThisLecture;

export const getSelectedLectureTranslation = state =>
  state.lectures.selectedChildItem;

export const getLecturesLookupList = state => state.lectures.lookupList;
