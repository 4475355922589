import React from "react";

import Form from "../Form/FormContainer";
import Input from "../Form/Input";
import Switch from "../Form/Switch";
import DropDownList from "../Form/DropDownList";
import MultiSelect from "../Form/MultiSelectList";
import Radio from "../Form/RadioInput";
import ComboBox from "../Form/ComboBox";
import NumericInput from "../Form/NumericInput";
import DatePicker from "../Form/DatePicker";
import HTMLEditor from "../Editor/HTMLEditor";
import FileUpload from "../Form/FileUpload";
import MultilineInput from "../Form/MultilineInput";
import ActionButtons from "../Form/ActionButtons";
import {
  TEXT,
  RICH_TEXT,
  NUMBER,
  BOOL,
  DATE,
  MULTILINE_TEXT,
  FILE,
  PASSWORD,
  EMAIL,
  PHONE
} from "../../constants/formInputTypes";
import {
  DROPDOWN,
  COMBOBOX,
  RADIO,
  MULTISELECT
} from "../../constants/dropDownTypes";
import { formatLabel } from "../../services/utilities/stringManipulation";
import FormGroup from "../Form/FormGroup";
import MaskedInput from "../Form/MaskedInput";
import { CRUD_TYPES } from "../../enums/crudTypes";
import List from "../List/ListContainer";

const PageForm = ({
  title = "",
  initialData = {},
  data = {},
  validations = {},
  serverValidations = {},
  languages,
  filesForUpload,
  isFormValid,
  isEditMode,
  hasUnsavedChanges,
  onInputFocus = () => {},
  onInputChange = () => {},
  handleServerCheck = () => {},
  onLanguageChange = () => {},
  onContentEditorChange = () => {},
  onFileAdd = () => {},
  onFileRemove,
  onReset,
  onCancel,
  onReturn,
  onSave,
  folderName,
  divideFormByGroups = false,
  links = {},
  ...rest
}) => {
  const handleLanguageChange = event => {
    onInputChange(event);
    onLanguageChange(event);
  };

  const handleFileAdd = event => {
    onInputChange(event);
    onFileAdd(event);
  };

  const handleFileRemove = event => {
    onInputChange(event);
    onFileRemove(event);
  };
  const renderFields = initialData => {
    return Object.entries(initialData).map(([key, value], index) => {
      let {
        label,
        meta,
        meta: {
          type,
          isRequired,
          isUnique,
          lookupType,
          lookupKey,
          crud,
          fieldForMinValue: fieldForUsingAsMinValue,
          fieldForMaxValue: fieldForUsingAsMaxValue,
          isDateOfBirthField
        }
      } = value;

      if (
        key === "languageId" &&
        languages &&
        Array.isArray(languages) &&
        languages.length
      ) {
        return (
          <DropDownList
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            data={languages}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={handleLanguageChange}
          />
        );
      }

      if (lookupType && lookupType === COMBOBOX) {
        return (
          <ComboBox
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            data={rest[lookupKey]}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (lookupType && lookupType === DROPDOWN) {
        return (
          <DropDownList
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            data={rest[lookupKey]}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (
        lookupType &&
        lookupType === MULTISELECT &&
        ((crud &&
          isEditMode &&
          crud.includes(CRUD_TYPES.Edit || CRUD_TYPES.Insert)) ||
          !crud)
      ) {
        return (
          <MultiSelect
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            data={rest[lookupKey]}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (lookupType && lookupType === RADIO) {
        return (
          <Radio
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            data={rest[lookupKey]}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (type === TEXT) {
        return (
          <Input
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            isDisabled={isEditMode && isUnique}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={
              isUnique
                ? serverValidations[key]?.isValid && validations[key]?.isValid
                : validations[key]?.isValid
            }
            errorMessage={
              isUnique
                ? !validations[key]?.isValid
                  ? validations[key]?.errorMessage
                  : serverValidations[key]?.errorMessage
                : validations[key]?.errorMessage
            }
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
            onBlur={isUnique ? handleServerCheck : null}
          />
        );
      } else if (type === EMAIL) {
        return (
          <Input
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={
              isUnique
                ? serverValidations[key]?.isValid && validations[key]?.isValid
                : validations[key]?.isValid
            }
            errorMessage={
              isUnique
                ? !validations[key]?.isValid
                  ? validations[key]?.errorMessage
                  : serverValidations[key]?.errorMessage
                : validations[key]?.errorMessage
            }
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
            onBlur={isUnique ? handleServerCheck : null}
          />
        );
      } else if (type === PASSWORD) {
        return (
          <Input
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (type === MULTILINE_TEXT) {
        return (
          <MultilineInput
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (
        type === BOOL &&
        ((crud &&
          isEditMode &&
          crud.includes(CRUD_TYPES.Edit || CRUD_TYPES.Insert)) ||
          !crud)
      ) {
        return (
          <Switch
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (type === NUMBER) {
        return (
          <NumericInput
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (type === DATE) {
        return (
          <DatePicker
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            min={
              fieldForUsingAsMinValue
                ? data[fieldForUsingAsMinValue]
                : undefined
            }
            max={
              fieldForUsingAsMaxValue
                ? data[fieldForUsingAsMaxValue]
                : undefined
            }
            isDateOfBirthField={!!isDateOfBirthField}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else if (type === RICH_TEXT) {
        return (
          <HTMLEditor
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            defaultValue={initialData[key].value}
            folderName={folderName}
            onBlur={onContentEditorChange}
          />
        );
      } else if (type === FILE) {
        return (
          <FileUpload
            key={index}
            label={formatLabel(label, isRequired)}
            files={filesForUpload}
            isDisabled={data[key] ? true : false}
            onAdd={handleFileAdd}
            onRemove={handleFileRemove}
          />
        );
      } else if (type === PHONE) {
        return (
          <MaskedInput
            key={index}
            name={key}
            label={formatLabel(label, isRequired)}
            value={data[key]}
            {...meta}
            isTouched={validations[key]?.isTouched}
            isFocused={validations[key]?.isFocused}
            isValid={validations[key]?.isValid}
            errorMessage={validations[key]?.errorMessage}
            onFocus={onInputFocus(key)}
            onChange={onInputChange}
          />
        );
      } else {
        return null;
      }
    });
  };

  const renderGoupInfoList = groupInfo => {
    return (
      <List title={groupInfo?.title} data={groupInfo?.data} listType="info" />
    );
  };

  const renderFieldsByGroup = initialData => {
    let splitInitialDataByGroups = Object.entries(initialData).reduce(
      (result, [key, value]) => {
        if (!result[value.meta.group]) {
          result[value.meta.group] = {};
        }
        result[value.meta.group] = {
          ...result[value.meta.group],
          [key]: { ...value }
        };
        return result;
      },
      {}
    );
    return Object.entries(splitInitialDataByGroups).map(
      ([key, value], index) => {
        let capitalizedGroupName = key.charAt(0).toUpperCase() + key.slice(1);
        return (
          <FormGroup key={index} title={`${capitalizedGroupName} information`}>
            {renderFields(value)}
            {Object.keys(rest).includes(key) && rest[key] && (
              <>
                <br />
                {renderGoupInfoList(rest[key])}
              </>
            )}
          </FormGroup>
        );
      }
    );
  };

  return (
    <Form title={title || "General Information"}>
      {rest.preForm && typeof rest.preForm === "function" && rest.preForm()}

      {divideFormByGroups
        ? renderFieldsByGroup(initialData)
        : renderFields(initialData)}
      {links?.list?.length > 0 && isEditMode ? (
        <List title={links.title} data={links.list} listType="link" />
      ) : null}

      {rest.postForm && typeof rest.postForm === "function" && rest.postForm()}

      <ActionButtons
        label="Save"
        isSubmitDisabled={!hasUnsavedChanges || !isFormValid}
        isResetDisabled={!hasUnsavedChanges}
        handleSubmit={onSave}
        handleCancel={!isEditMode ? onCancel : undefined}
        handleReset={isEditMode ? onReset : undefined}
        handleReturn={onReturn}
      />
    </Form>
  );
};

export default PageForm;
