import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "../Grid/GridItem";

import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const ActionButtons = ({
  label,
  isSubmitDisabled = false,
  isResetDisabled= false,
  handleSubmit,
  handleCancel,
  handleReturn,
  handleReset
}) => {
  const classes = useStyles();

  return (
    <GridItem container className={classes.actionButtonsContainer}>
      {handleSubmit && typeof handleSubmit === "function" && (
        <GridItem>
          <Button primary disabled={isSubmitDisabled} onClick={handleSubmit}>
            {label}
          </Button>
        </GridItem>
      )}

      {handleCancel && typeof handleCancel === "function" && (
        <GridItem>
          <Button onClick={handleCancel}>Cancel</Button>
        </GridItem>
      )}
      {handleReturn && typeof handleReturn === "function" && (
        <GridItem>
          <Button onClick={handleReturn}>Return to list</Button>
        </GridItem>
      )}
      {handleReset && typeof handleReset === "function" && (
        <GridItem>
          <Button disabled={isResetDisabled} onClick={handleReset}>Reset</Button>
        </GridItem>
      )}
    </GridItem>
  );
};

export default ActionButtons;
