import {
  GET_STATIC_PAGES,
  GET_STATIC_PAGE_DETAILS,
  ADD_STATIC_PAGE,
  UPDATE_STATIC_PAGE,
  DELETE_STATIC_PAGE,
  CHANGE_STATIC_PAGE_DATA_STATE,
  RESET_STATIC_PAGE_STATE,
  RESET_STATIC_PAGE_FORM_STATE,
  ADD_STATIC_PAGE_TRANSLATION,
  GET_STATIC_PAGE_TRANSLATION,
  RESET_STATIC_PAGE_TRANSLATION_FORM_STATE,
  UPDATE_STATIC_PAGE_TRANSLATION,
  GET_STATIC_PAGE_TRANSLATIONS_LOOKUP
} from "../actions/staticPages";

const INITIAL_STATE = {
  list: [],
  lookupList: [],
  total: 0,
  dataState: {
    sort: [],
    filter: undefined,
    skip: 0,
    take: 10
  },
  selectedItem: null,
  selectedChildItem: null,
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
  isChildSaving: false,
  isChildSaved: false,
  isError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_STATIC_PAGES.REQUEST:
    case GET_STATIC_PAGE_DETAILS.REQUEST:
    case GET_STATIC_PAGE_TRANSLATION.REQUEST:
    case GET_STATIC_PAGE_TRANSLATIONS_LOOKUP.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }

    case ADD_STATIC_PAGE.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }

    case UPDATE_STATIC_PAGE.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false,
        selectedItem: { ...state.selectedItem, ...action.payload }
      };
    }

    case ADD_STATIC_PAGE_TRANSLATION.REQUEST: {
      return {
        ...state,
        isChildSaving: true,
        isSaved: true,
        isChildSaved: false,
        isError: false
      };
    }
    case UPDATE_STATIC_PAGE_TRANSLATION.REQUEST: {
      return {
        ...state,
        isChildSaving: true,
        isSaved: true,
        isChildSaved: false,
        isError: false,
        selectedChildItem: { ...state.selectedChildItem, ...action.payload }
      };
    }

    case GET_STATIC_PAGE_TRANSLATIONS_LOOKUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        lookupList: action.payload
      };
    }

    case GET_STATIC_PAGE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        selectedChildItem: action.payload,
        isFetching: false,
        isFetched: true,
        isError: false,
        isSaved: true,
        isChildSaved: true
      };
    }

    case GET_STATIC_PAGES.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        list: action.payload.data,
        total: action.payload.total
      };
    }

    case GET_STATIC_PAGE_DETAILS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        selectedItem: action.payload,
        selectedChildItem: {
          languageId:
            action.payload.allLanguagesWithOrWithoutThisStaticPage?.[0]
              .languageId
        }
      };
    }

    case ADD_STATIC_PAGE.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        isSaving: false,
        isSaved: true,
        isError: false,
        selectedChildItem: {
          languageId:
            action.payload.allLanguagesWithOrWithoutThisStaticPage?.[0]
              .languageId
        }
      };
    }

    case UPDATE_STATIC_PAGE.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }

    case ADD_STATIC_PAGE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        isChildSaving: false,
        isSaved: true,
        isChildSaved: true,
        isError: false,
        selectedChildItem: action.payload,
        selectedItem: {
          ...state.selectedItem,
          allLanguagesWithOrWithoutThisStaticPage: state.selectedItem.allLanguagesWithOrWithoutThisStaticPage.map(
            lang => {
              return lang.languageId === action.payload.languageId
                ? { ...lang, staticPageLanguageId: action.payload.id }
                : lang;
            }
          )
        }
      };
    }
    case UPDATE_STATIC_PAGE_TRANSLATION.SUCCESS: {
      return {
        ...state,
        isChildSaving: false,
        isSaved: true,
        isChildSaved: true,
        isError: false
      };
    }

    case DELETE_STATIC_PAGE.SUCCESS: {
      return {
        ...state,
        isError: false
        // list: state.list.filter(item => item.id !== action.payload.id)
      };
    }

    case GET_STATIC_PAGES.ERROR:
    case GET_STATIC_PAGE_DETAILS.ERROR:
    case GET_STATIC_PAGE_TRANSLATIONS_LOOKUP.ERROR:
    case ADD_STATIC_PAGE.ERROR:
    case ADD_STATIC_PAGE_TRANSLATION.ERROR:
    case UPDATE_STATIC_PAGE.ERROR:
    case DELETE_STATIC_PAGE.ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isError: true
      };
    }

    case CHANGE_STATIC_PAGE_DATA_STATE: {
      return {
        ...state,
        dataState: action.payload
      };
    }

    case RESET_STATIC_PAGE_STATE: {
      return {
        ...INITIAL_STATE
      };
    }

    case RESET_STATIC_PAGE_FORM_STATE: {
      return {
        ...state,
        selectedItem: null,
        selectedChildItem: null,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isChildSaving: false,
        isChildSaved: false,
        isError: false
      };
    }

    case RESET_STATIC_PAGE_TRANSLATION_FORM_STATE: {
      return {
        ...state,
        selectedChildItem: action.payload
          ? { languageId: action.payload }
          : null,
        isChildSaving: false,
        isChildSaved: false
      };
    }

    default:
      return state;
  }
}

export const getStaticPagesList = state => state.staticPages.list;
export const getStaticPagesListDataState = state => state.staticPages.dataState;
export const getStaticPagesListTotalNumber = state => state.staticPages.total;

export const getSelectedStaticPage = state => state.staticPages.selectedItem;
export const getIsStaticPageSaved = state => state.staticPages.isSaved;
export const getLanguagesForSelectedStaticPage = state =>
  state.staticPages.selectedItem?.allLanguagesWithOrWithoutThisStaticPage;

export const getSelectedStaticPageTranslation = state =>
  state.staticPages.selectedChildItem;

export const getStaticPagesLookupList = state => state.staticPages.lookupList;
