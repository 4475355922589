import React from "react";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";
import classnames from "classnames";

import FormElement from "./FormElement";
import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const MultilineInput = ({
  name = "",
  label = "",
  value = "",
  isRequired = false,
  isDisabled = false,
  autoFocus,
  isTouched = false,
  isFocused = false,
  isValid = false,
  errorMessage = "",
  onFocus = () => {},
  onChange = () => {},
  onBlur,
  ...rest
}) => {
  const classes = useStyles();

  const underlineClasses = classnames({
    [classes.underline]:
      !isRequired ||
      (isRequired && !isTouched) ||
      (isRequired && isTouched && isValid),
    [classes.underlineError]: isRequired && isTouched && !isValid
  });

  const handleBlur = event => {
    isRequired && onFocus(event);
  };

  return (
    <FormElement label={label}>
      <Input
        multiline={true}
        autoFocus={autoFocus}
        autoComplete="off"
        className={classes.input}
        classes={{
          underline: underlineClasses
        }}
        name={name}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        // onFocus={onFocus}
        onBlur={handleBlur}
        {...rest}
      />

      {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormElement>
  );
};

export default MultilineInput;
