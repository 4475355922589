import React from "react";

import Input from "../Form/Input";

const TextEditCell = ({
  dataItem,
  field,
  isRequired = false,
  isTouched = false,
  isValid = false,
  onChange
}) => (
  <td>
    <Input
      name={field}
      value={dataItem[field]}
      isRequired={isRequired}
      isTouched={isTouched}
      isValid={isValid}
      onChange={onChange}
    />
  </td>
);

export default TextEditCell;
