import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Person from "@material-ui/icons/Person";

import Button from "../Button";

import styles from "../../assets/jss/components/Navbar/navbarLinksStyle";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../actions/auth";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(styles);

const NavbarLinks = props => {
  const classes = useStyles();

  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const buttonRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClickProfile = () => {
    setIsOpenProfile(!isOpenProfile);
  };

  const handleCloseProfile = () => {
    setIsOpenProfile(false);
  };

  const handleLogOut = () => {
    dispatch(logoutUser());
    return <Redirect from={location.pathname} to="/auth/login" />;
  };

  const dropdownItem = classnames(classes.dropdownItem, classes.primaryHover);
  const managerClasses = classnames({
    [classes.managerClasses]: true
  });

  return (
    <div className={managerClasses}>
      <Button
        ref={buttonRef}
        color="transparent"
        aria-label="Person"
        justIcon={true}
        aria-owns={isOpenProfile ? "profile-menu-list" : null}
        aria-haspopup="true"
        onClick={handleClickProfile}
        className={classes.buttonLink}
        muiClasses={{
          label: ""
        }}
      >
        <Person
          className={`
              ${classes.headerLinksSvg} ${classes.links}`}
        />

        <Hidden mdUp implementation="css">
          <span onClick={handleClickProfile} className={classes.linkText}>
            {"Profile"}
          </span>
        </Hidden>
      </Button>

      <Popper
        open={isOpenProfile}
        anchorEl={buttonRef.current}
        transition
        disablePortal
        placement="bottom"
        className={classnames({
          [classes.popperClose]: !isOpenProfile,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                <MenuList role="menu">
                  <MenuItem onClick={handleLogOut} className={dropdownItem}>
                    {"Log out"}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default NavbarLinks;
