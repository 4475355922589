import { useMemo } from "react";

const useObjectMapper = ({ initialState, selectedItem, changeRequirement }) => {
  const getInitialState = useMemo(() => {
    if (!selectedItem) {
      return initialState;
    } else {
      let newObject = Object.keys(initialState).reduce((result, key) => {
        if (selectedItem.hasOwnProperty(key)) {
          result[key] = {
            label: initialState[key].label,
            value: selectedItem[key],
            meta: {
              ...initialState[key].meta,
              isRequired: changeRequirement?.includes(key)
                ? !initialState[key].meta.isRequired
                : initialState[key].meta.isRequired
            }
          };
          return result;
        } else {
          result[key] = {
            label: initialState[key].label,
            value: initialState[key].value,
            meta: {
              ...initialState[key].meta,
              isRequired: changeRequirement?.includes(key)
                ? !initialState[key].meta.isRequired
                : initialState[key].meta.isRequired
            }
          };
        }
        return result;
      }, {});
      return newObject;
    }
  }, [initialState, selectedItem, changeRequirement]);

  return { getInitialState };
};

export default useObjectMapper;
