export const isValidText = value => {
  return !!value && typeof value === "string" && value.length > 0;
};

export const isValidNumber = value => {
  return !!value && Number.isFinite(value);
};

export const isValidDate = value => {
  return !!value;
};

export const isValidEmail = value => {
  let emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegEx.test(value);
};

export const passwordPolicyValidation = value => {
  //let passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;
  let passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  return passwordRegEx.test(value);
};

export const validatePhoneNumber = value => {
  if (isEmpty(value)) return true;
  let phoneRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/;
  return phoneRegEx.test(value);
};

export const comparePasswords = (password, confirmPassword) => {
  return (
    isValidText(password) &&
    isValidText(confirmPassword) && confirmPassword.length > 0
    && password === confirmPassword
  );
};

export const isEmpty = value => {
  return value === undefined || value === null || value === "";
};
