import { blackColor, hexToRgb } from "../commonStyle";

const editorStyles = {
  editorElementTop: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "2rem auto"
  },
  editor: {
    minHeight: "300px",
    maxWidth: "100%",
    marginTop: "10px"
  },
  labelHorizontal: {
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    textAlign: "left",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "39px",
    marginRight: "0"
  }
};

export default editorStyles;
