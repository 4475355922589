import { callApi } from "../services/api/api";

import {
  getComments as getCommentsApi,
  getCommentDetails as getCommentDetailsApi,
  addComment as addCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
  getCommentsLookup as getCommentsLookupApi
} from "../pages/admin/comments/apiConfig";
import generateActions from "../services/utilities/generateActions";

export const GET_COMMENTS = generateActions("GET_COMMENTS");
export const GET_COMMENT_DETAILS = generateActions("GET_COMMENT_DETAILS");
export const GET_COMMENTS_LOOKUP = generateActions("GET_COMMENTS_LOOKUP");
export const ADD_COMMENT = generateActions("ADD_COMMENT");
export const UPDATE_COMMENT = generateActions("UPDATE_COMMENT");
export const DELETE_COMMENT = generateActions("DELETE_COMMENT");

export const CHANGE_COMMENT_DATA_STATE = "CHANGE_COMMENT_DATA_STATE";
export const RESET_COMMENT_STATE = "RESET_COMMENT_STATE";
export const RESET_COMMENT_FORM_STATE = "RESET_COMMENT_FORM_STATE";

/**
 * COMMENT ACTION CREATORS
 */

export function getComments({ dataState }) {
  return {
    type: GET_COMMENTS.ACTION,
    promise: callApi(getCommentsApi(dataState)),
    payload: dataState
  };
}

export function getCommentDetails({ id }) {
  return {
    type: GET_COMMENT_DETAILS.ACTION,
    promise: callApi(getCommentDetailsApi(id)),
    payload: id
  };
}

export function getCommentsLookup() {
  return {
    type: GET_COMMENTS_LOOKUP.ACTION,
    promise: callApi(getCommentsLookupApi())
  };
}

export function addComment({ data }) {
  return {
    type: ADD_COMMENT.ACTION,
    promise: callApi(addCommentApi(data)),
    payload: data
  };
}

export function updateComment({ data }) {
  return {
    type: UPDATE_COMMENT.ACTION,
    promise: callApi(updateCommentApi(data)),
    payload: data
  };
}

export function deleteComment(id) {
  return {
    type: DELETE_COMMENT.ACTION,
    promise: callApi(deleteCommentApi(id)),
    payload: id
  };
}
export function deleteCommentAndUpdateGrid(id, dataState) {
  return dispatch => {
    return dispatch(deleteComment(id)).then(() =>
      dispatch(getComments({ dataState }))
    );
  };
}

export function changeCommentDataState({ dataState }) {
  return {
    type: CHANGE_COMMENT_DATA_STATE,
    payload: dataState
  };
}

/**
 * Resets the whole comment state in store to initial state.
 */
export function resetCommentState() {
  return {
    type: RESET_COMMENT_STATE
  };
}

/**
 * Resets all data connected to comment form.
 */
export function resetCommentFormState() {
  return {
    type: RESET_COMMENT_FORM_STATE
  };
}

/**
 * THUNKS
 */

/**
 * Used when saving changes on the comment form and navigating
 * back to list. It first creates the comment and then resets all
 * fields related to the form.
 * @param {Object} data
 * @param {Object} data.data
 */
export function addCommentThenResetForm({ data }) {
  return dispatch => {
    return dispatch(addComment({ data })).then(() =>
      dispatch(resetCommentFormState())
    );
  };
}

/**
 * Used when saving changes on the comment form and navigating
 * back to list. It first updates the comment and then resets all
 * fields related to the form.
 * @param {Object} data
 * @param {Object} data.data
 */
export function updateCommentThenResetForm({ data }) {
  return dispatch => {
    return dispatch(updateComment({ data })).then(() =>
      dispatch(resetCommentFormState())
    );
  };
}
