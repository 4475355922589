import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

const CommandCell = ({
  handleEdit,
  handleDelete,
  isDeleteOption = true,
  dataItem
}) => {
  const onEditClick = () => {
    handleEdit(dataItem);
  };

  const onDeleteClick = () => {
    handleDelete(dataItem);
  };

  return (
    <td>
      {handleEdit && typeof handleEdit === "function" && (
        <IconButton aria-label="Edit" onClick={onEditClick}>
          <Edit />
        </IconButton>
      )}

      {isDeleteOption && handleDelete && typeof handleDelete === "function" && (
        <IconButton aria-label="Delete" onClick={onDeleteClick}>
          <Delete />
        </IconButton>
      )}
    </td>
  );
};

export default CommandCell;
