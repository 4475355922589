import { useEffect } from "react";

const useScrollToTop = ({ dataState }) => {
  useEffect(() => {
    const container = document.querySelector("#main");
    container.scrollTop = 0;
  }, [dataState]);

  return null;
};

export default useScrollToTop;
