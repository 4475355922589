import {
  GET_USERS,
  GET_USER_DETAILS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  CHANGE_USER_DATA_STATE,
  RESET_USER_STATE,
  GET_ROLES,
  CHECK_EMAIL,
  CHECK_USERNAME,
  GET_GENDERS,
  GET_USERS_LOOKUP,
  GET_ASSIGNED_COURSES
} from "../actions/users";

const INITIAL_STATE = {
  list: [],
  lookupList: [],
  radioLookupList: [],
  total: 0,
  dataState: {
    sort: [],
    filter: undefined,
    skip: 0,
    take: 10
  },
  selectedItem: null,
  isAvailable: { email: true, userName: true },
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
  isError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USERS.REQUEST:
    case GET_ROLES.REQUEST:
    case GET_GENDERS.REQUEST:
    case GET_USER_DETAILS.REQUEST:
    case GET_USERS_LOOKUP.REQUEST:
    case GET_ASSIGNED_COURSES.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }

    case ADD_USER.REQUEST:
    case UPDATE_USER.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }
    case GET_USERS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        list: action.payload.data,
        total: action.payload.total
      };
    }

    case GET_USER_DETAILS.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        selectedItem: action.payload
      };
    }
    case GET_ROLES.SUCCESS: {
      return { ...state, isFetched: true, lookupList: action.payload };
    }
    case GET_GENDERS.SUCCESS: {
      return { ...state, isFetched: true, radioLookupList: action.payload };
    }

    case GET_USERS_LOOKUP.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        lookupList: action.payload
      };
    }

    case GET_ASSIGNED_COURSES.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isError: false,
        selectedItem: {
          ...state.selectedItem,
          courseListInfo: [...action.payload]
        }
      };
    }

    case ADD_USER.SUCCESS:
    case UPDATE_USER.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }

    case DELETE_USER.SUCCESS: {
      return {
        ...state,
        isError: false
        // list: state.list.filter(item => item.id !== action.payload.id)
      };
    }

    case CHECK_EMAIL.SUCCESS:
      return {
        ...state,
        isAvailable: { ...state.isAvailable, email: action.payload }
      };
    case CHECK_USERNAME.SUCCESS: {
      return {
        ...state,
        isAvailable: { ...state.isAvailable, userName: action.payload }
      };
    }

    case GET_USERS.ERROR:
    case GET_USER_DETAILS.ERROR:
    case GET_USERS_LOOKUP.ERROR:
    case ADD_USER.ERROR:
    case GET_ROLES.ERROR:
    case GET_GENDERS.ERROR:
    case UPDATE_USER.ERROR:
    case DELETE_USER.ERROR:
    case GET_ASSIGNED_COURSES.ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isError: true
      };
    }

    case CHANGE_USER_DATA_STATE: {
      return {
        ...state,
        dataState: action.payload
      };
    }

    case RESET_USER_STATE: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
}

export const getUsersList = state => state.users.list;
export const getUsersListDataState = state => state.users.dataState;
export const getUsersListTotalNumber = state => state.users.total;

export const getSelectedUser = state => state.users.selectedItem;
export const getIsUserSaved = state => state.users.isSaved;

export const getIsEmailAvailable = state => state.users.isAvailable.email;
export const getIsUsernameAvailable = state => state.users.isAvailable.userName;

export const getUsersLookupList = state => state.users.lookupList;
export const getUsersRadioLookupList = state => state.users.radioLookupList;
