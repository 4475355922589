import React, { useRef, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import routes from "../routes/routes";
import { getIsUserAuthenticated } from "../reducers/auth";

import backgroundImage from "../assets/img/login.jpeg";

import styles from "../assets/jss/layouts/authStyle";

const useStyles = makeStyles(styles);

const AuthLayout = props => {
  const classes = useStyles();

  const isAuthenticated = useSelector(getIsUserAuthenticated);
  const location = useLocation();

  // ref for the wrapper div
  const wrapperRef = useRef();

  useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.pages);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (isAuthenticated) {
    return <Redirect from={location.pathname} to="/admin/static-pages" />;
  }

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <div
        className={classes.fullPage}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/auth" to="/auth/login" />
        </Switch>
      </div>
    </div>
  );
};

export default AuthLayout;
