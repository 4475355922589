import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import styles from "../../assets/jss/components/gridStyle";

const useStyles = makeStyles(styles);

const GridContainer = props => {
  const classes = useStyles();

  const { children, className, ...rest } = props;

  return (
    <Grid
      container
      {...rest}
      className={`${classes.container} ${className ? className : ""}`}
    >
      {children}
    </Grid>
  );
};

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default GridContainer;
