import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/components/gridCellStyle";

const useStyles = makeStyles(styles);

const HeaderWithHintCell = ({ title, hint }) => {
  const classes = useStyles();

  return (
    <>
      <span>{title}</span>

      {hint ? (
        <>
          <br />
          <span className={classes.hintText}>{hint}</span>
        </>
      ) : null}
    </>
  );
};

export default HeaderWithHintCell;
