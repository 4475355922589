import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";

import CommandCell from "../../../../components/GridCell/CommandCell";
import Dialog from "../../../../components/Dialog";
import styles from "../../../../assets/jss/pages/listStyle";
import {
  getCourses,
  changeCourseDataState,
  resetCourseFormState,
  deleteCourseAndUpdateGrid
} from "../../../../actions/courses";
import columns from "./GridFields";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import {
  getCoursesList,
  getCoursesListDataState,
  getCoursesListTotalNumber
} from "../../../../reducers/courses";

const useStyles = makeStyles(styles);

const CoursesListContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const idToDelete = useRef();

  const courses = useSelector(getCoursesList);
  const dataState = useSelector(getCoursesListDataState);
  const total = useSelector(getCoursesListTotalNumber);

  const dispatch = useDispatch();

  const handleDataStateChange = useCallback(
    event => dispatch(changeCourseDataState(event.dataState)),
    [dispatch]
  );

  useEffect(() => {
    dispatch(getCourses(dataState));
  }, [dispatch, dataState]);

  useScrollToTop({ dataState });

  const handleInsertClick = () => {
    dispatch(resetCourseFormState());
    history.push(`${location.pathname}/form`);
  };

  const handleEditIconClick = dataItem => {
    dispatch(resetCourseFormState());
    history.push(`${location.pathname}/form/${dataItem.id}`);
  };

  const handleDeleteIconClick = dataItem => {
    setIsDialogVisible(true);

    idToDelete.current = dataItem.id;
  };

  const closeDialog = () => {
    setIsDialogVisible(false);

    idToDelete.current = null;
  };

  const confirmDelete = () => {
    dispatch(deleteCourseAndUpdateGrid(idToDelete.current, dataState));
    closeDialog();
  };

  return (
    <div className={classes.container}>
      <Grid
        scrollable="none"
        data={courses}
        total={total}
        {...dataState}
        sortable={true}
        pageable={true}
        filterable
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
        }}
        onDataStateChange={handleDataStateChange}
      >
        {columns.map((field, index) => {
          const { name, title, cellType, filterable, sortable } = field;
          return (
            <Column
              key={index}
              field={name}
              title={title}
              cell={cellType}
              sortable={sortable}
              filterable={filterable}
            />
          );
        })}
        <Column
          field="_command"
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title="Edit / Delete"
          cell={props => (
            <CommandCell
              {...props}
              isDeleteOption={true}
              handleEdit={handleEditIconClick}
              handleDelete={handleDeleteIconClick}
            />
          )}
        />
      </Grid>

      <Button
        primary
        className={classes.insertButton}
        onClick={handleInsertClick}
      >
        Insert new
      </Button>

      {isDialogVisible && (
        <Dialog handleConfirm={confirmDelete} handleCancel={closeDialog} />
      )}
    </div>
  );
};

export default CoursesListContainer;
