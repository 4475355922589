import React, { useState, useRef, useEffect } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import classnames from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import routes from "../routes/routes";
import styles from "../assets/jss/layouts/adminStyle";
import PrivateRoute from "../routes/privateRoute";
import { getUser } from "../reducers/auth";

const useStyles = makeStyles(styles);

let ps;

const AdminLayout = props => {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector(getUser);

  const sidebarLinkColor = useRef("blue");
  const sidebarBgColor = useRef("black");

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);

  const mainPanelClasses = `${classes.mainPanel} ${classnames({
    [classes.mainPanelSidebarMini]: miniActive,
    [classes.mainPanelWithPerfectScrollbar]:
      navigator.platform.indexOf("Win") > -1
  })}`;

  // ref for main panel div
  const mainPanelRef = useRef();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  useEffect(() => {
    // const container = document.querySelector("#main");
    mainPanelRef.current.scrollTop = 0;
  }, [location]);

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = routes => {
    let activeRoute = "Paprac MedOpen";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].pages);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        const routePath = routes[i].layout + routes[i].path;
        if (
          location.pathname.indexOf(routePath) !== -1 ||
          (routePath.indexOf(":") !== -1 &&
            location.pathname.indexOf(
              routePath.slice(0, routePath.indexOf(":"))
            ) !== -1)
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = routes => {
    return routes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.pages);
      }
      if (route.layout === "/admin") {
        return (
          <PrivateRoute
            path={route.layout + route.path}
            component={route.component}
            key={key}
            exact={route.exact}
          />
        );
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"MedOpen"}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={sidebarLinkColor.current}
        bgColor={sidebarBgColor.current}
        miniActive={miniActive}
        user={user}
        {...props}
      />
      <div id="main" className={mainPanelClasses} ref={mainPanelRef}>
        <Navbar
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...props}
        />

        <div className={classes.map}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/admin" to="/admin/static-pages" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
