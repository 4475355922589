import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/components/infoListStyle";

const useStyles = makeStyles(styles);

const InfoListItem = props => {
  const classes = useStyles();
  const { courseName, whyThisCourse, whatsExpected } = props.dataItem;
  return (
    <div className={classes.main}>
      <div className="col-6">
        <h2 className={classes.infoText}>{courseName}</h2>
        <div>{`Why this course: ${whyThisCourse || ""}`}</div>
      </div>
      <div>
        <div>{`What do you expect from this course: ${
          whatsExpected || ""
        }`}</div>
      </div>
    </div>
  );
};

export default InfoListItem;
