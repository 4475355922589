import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import AuthLayout from "./layouts/Auth";
import AdminLayout from "./layouts/Admin";

import "./assets/styles/medOpen_material.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/App.css";
import refreshTokenInterceptor from "./services/api/refreshTokenIntercept";

toast.configure();

const App = () => {
  refreshTokenInterceptor();
  return (
    <>
      <Switch>
        <Route path="/auth">
          <AuthLayout />
        </Route>
        <Route path="/admin">
          <AdminLayout />
        </Route>
        <Redirect from="/" to="/admin/static-pages" />
      </Switch>
    </>
  );
};

export default App;
