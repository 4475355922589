import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../assets/jss/components/dialogStyle";

const useStyles = makeStyles(styles);

const CustomDialog = ({
  title = "Please confirm",
  message = "Are you sure you want to continue?",
  handleCancel = () => {},
  handleConfirm = () => {}
}) => {
  const classes = useStyles();

  return (
    <Dialog title={title} onClose={handleCancel}>
      <p className={classes.content}>{message}</p>
      <DialogActionsBar>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button primary onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default CustomDialog;
