import { container } from "../commonStyle";

const listStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
    paddingTop: "30px",
    marginBottom: "30px",
  },
  insertButton: {
    height: "50px",
    marginTop: "20px",
  },
  linkButton: {
    height: "50px",
    marginTop: "20px",
    display: "flex",
  },
});

export default listStyle;
