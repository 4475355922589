import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import classnames from "classnames";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Input from "../../components/Form/Input";

import styles from "../../assets/jss/pages/loginStyle";
import useForm from "../../hooks/useForm";

import { loginUser } from "../../actions/auth";

const useStyles = makeStyles(styles);

const initialState = {
  userName: {
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100
    }
  },
  password: {
    value: "",
    meta: {
      type: "password",
      isRequired: true,
      maxLength: 100
    }
  }
};

const Login = () => {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  const dispatch = useDispatch();

  const {
    values,
    validations,
    handleInputChange,
    handleInputFocus,
    isFormValid
  } = useForm({
    initialValues: initialState
  });

  useEffect(() => {
    const timeoutRef = setTimeout(function () {
      setCardAnimation("");
    }, 700);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(loginUser(values));
  };

  const cardHeaderClasses = classnames({
    [classes.cardHeader]: true,
    [classes.textCenter]: true
  });

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={cardHeaderClasses} color="primary">
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>

              <CardBody>
                <Input
                  containerStyles={classes.usernameInput}
                  autoComplete={true}
                  name="userName"
                  floatingLabel="Username*"
                  value={values.userName}
                  isRequired={true}
                  isTouched={validations.userName?.isTouched}
                  isValid={validations.userName?.isValid}
                  onFocus={handleInputFocus("userName")}
                  onChange={handleInputChange}
                />

                <Input
                  containerStyles={classes.passwordInput}
                  type="password"
                  autoComplete={true}
                  name="password"
                  floatingLabel="Password*"
                  value={values.password}
                  isRequired={true}
                  isTouched={validations.password?.isTouched}
                  isValid={validations.password?.isValid}
                  onFocus={handleInputFocus("password")}
                  onChange={handleInputChange}
                />
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  look="flat"
                  primary
                  type="submit"
                  disabled={!isFormValid}
                >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Login;
