import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { parseDate } from "@telerik/kendo-intl";
import { FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FormElement from "./FormElement";
import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const DatePickerInput = ({
  name = "",
  value = null,
  label = "",
  min,
  max,
  isDateOfBirthField = false,
  isRequired = false,
  isTouched = false,
  isFocused = false,
  isValid = false,
  errorMessage = "",
  onFocus = () => {},
  onChange = () => {}
}) => {
  const classes = useStyles();

  const handleBlur = event => {
    isRequired && onFocus(event);
  };

  return (
    <FormElement label={label}>
      <DatePicker
        formatPlaceholder="formatPattern"
        name={name}
        value={
          value
            ? parseDate(
                value.slice(-1) === "Z" ? value.slice(0, -1) : value,
                "yyyy-MM-ddTHH:mm:ss"
              )
            : value
        }
        onChange={onChange}
        min={min ? new Date(min) : undefined}
        max={
          max
            ? new Date(max)
            : isDateOfBirthField
            ? new Date()
            : new Date("2099-01-01T00:00")
        }
        format="dd-MM-yyyy"
        // onFocus={onFocus}
        onBlur={handleBlur}
        validityStyles={isRequired && isTouched ? true : false}
        valid={isRequired && isTouched ? isValid : undefined}
      />

      {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormElement>
  );
};

export default DatePickerInput;
