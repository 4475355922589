import { container } from "../commonStyle";

const questionsFormStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    },
    paddingTop: "30px",
    marginBottom: "30px"
  },
  lectureDropDownWrapper: {
    width: 700
  },
  questionsList: {
    width: "100%",
    marginTop: 40
  },
  addButton: {
    height: "50px",
    marginTop: "20px"
  },
  dialogContentWrapper: {
    position: "relative",
    paddingRight: 20,
    minHeight: 500,
    maxHeight: "calc(100vh - 210px)"
  },
  subquestionsList: {
    marginTop: 25
  },
  subquestionsListHeaderBottom: {
    color: "rgb(160, 160, 160)",
    fontSize: 14,
    padding: 5
  },
  subquestionsListItem: {
    padding: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    height: 60
  },
  formWrapper: {
    marginTop: 0,
    padding: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid rgba(0,0,0,0.12)",
    height: 60
  },
  subtitleInputWrapper: {
    width: "70%"
  },
  formButton: {
    margin: "auto 5px"
  },
  dialogAddButton: {
    marginTop: 20
  },
  answersList: {
    width: "100%"
  }
});

export default questionsFormStyle;
