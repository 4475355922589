import React from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FormElement from "./FormElement";
import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const FormSwitch = ({
  name = "",
  label = "",
  value = false,
  isRequired = false,
  isTouched = false,
  isFocused = false,
  isValid = false,
  isDisabled = false,
  errorMessage = "",
  onFocus = () => {},
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyles();

  const handleBlur = event => {
    isRequired && onFocus(event);
  };

  return (
    <FormElement label={label}>
      <Switch
        name={name}
        checked={value}
        disabled={isDisabled}
        onChange={onChange}
        // onFocus={onFocus}
        onBlur={handleBlur}
        validityStyles={isRequired && isTouched ? true : false}
        valid={isRequired && isTouched ? isValid : undefined}
        {...rest}
      />

      {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormElement>
  );
};

export default FormSwitch;
