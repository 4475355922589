import React, { useEffect, useRef, forwardRef, useLayoutEffect } from "react";
import Jodit from "jodit";

import "jodit/build/jodit.min.css";

const JoditEditor = forwardRef(
  ({ defaultValue, config, onChange, onBlur, tabIndex, name }, ref) => {
    const textArea = useRef(null);

    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === "function") {
          ref(textArea.current);
        } else {
          ref.current = textArea.current;
        }
      }
    }, [ref]);

    useEffect(() => {
      const blurHandler = value => {
        let valueWithoutScrollbarContent = value.slice();

        if (valueWithoutScrollbarContent.includes(`<div class="ps__rail`)) {
          valueWithoutScrollbarContent = valueWithoutScrollbarContent.slice(
            0,
            valueWithoutScrollbarContent.indexOf(`<div class="ps__rail`)
          );
        }

        if (valueWithoutScrollbarContent.includes(`<div class="ps__thumb`)) {
          valueWithoutScrollbarContent = valueWithoutScrollbarContent.slice(
            0,
            valueWithoutScrollbarContent.indexOf(`<div class="ps__thumb`)
          );
        }

        onBlur && onBlur(name, valueWithoutScrollbarContent);
      };

      const changeHandler = value => {
        let valueWithoutScrollbarContent = value.slice();

        if (valueWithoutScrollbarContent.includes(`<div class="ps__rail`)) {
          valueWithoutScrollbarContent = valueWithoutScrollbarContent.slice(
            0,
            valueWithoutScrollbarContent.indexOf(`<div class="ps__rail`)
          );
        }

        if (valueWithoutScrollbarContent.includes(`<div class="ps__thumb`)) {
          valueWithoutScrollbarContent = valueWithoutScrollbarContent.slice(
            0,
            valueWithoutScrollbarContent.indexOf(`<div class="ps__thumb`)
          );
        }

        onChange && onChange(name, valueWithoutScrollbarContent);
      };

      const element = textArea.current;
      textArea.current = Jodit.make(element, config);

      textArea.current.value = defaultValue;
      textArea.current.events.on("blur", () =>
        blurHandler(textArea.current.value)
      );
      textArea.current.events.on("change", () =>
        changeHandler(textArea.current.value)
      );

      textArea.current.workplace.tabIndex = tabIndex || -1;

      return () => {
        textArea.current.destruct();
        textArea.current = element;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    useEffect(() => {
      if (textArea && textArea.current) {
        textArea.current.value = defaultValue;
      }
    }, [textArea, defaultValue]);

    return <textarea ref={textArea} name={name} />;
  }
);

export default JoditEditor;
