import { GridCell } from "@progress/kendo-react-grid";
import BooleanCell from "../../../../components/GridCell/BooleanCell";

export default [
  {
    name: "id",
    title: "ID",
    cellType: GridCell,
    filterable: false
  },
  {
    name: "course",
    title: "Course Name",
    cellType: GridCell,
    filterable: true,
    sortable: false
  },
  {
    name: "staticPageLanguage",
    title: "Static Page",
    cellType: GridCell,
    filterable: true
  },
  {
    name: "lectureLanguage",
    title: "Lecture",
    cellType: GridCell,
    filterable: true
  },
  {
    name: "user",
    title: "User",
    cellType: GridCell,
    filterable: true
  },
  {
    name: "text",
    title: "Text",
    cellType: GridCell,
    filterable: true
  },
  {
    name: "isNew",
    title: "Is New",
    cellType: BooleanCell,
    filterable: false
  }
];
