import React, { useEffect, useState, useRef } from "react";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { UploadFileStatus } from "@progress/kendo-react-upload";

import Form from "../../../../components/PageForm/PageForm";
import useForm from "../../../../hooks/useForm";
import useObjectMapper from "../../../../hooks/useObjectMapper";
import commentInitialState from "./CommentFields";
import {
  getCommentDetails,
  addCommentThenResetForm,
  updateCommentThenResetForm,
  getCommentsLookup
} from "../../../../actions/comments";
import { getStaticPageTranslationsLookup } from "../../../../actions/staticPages";
import { getLectureTranslationsLookup } from "../../../../actions/lectures";
import { getUsersLookup } from "../../../../actions/users";
import {
  getSelectedComment,
  getIsCommentSaved,
  getCommentsLookupList
} from "../../../../reducers/comments";
import { getStaticPagesLookupList } from "../../../../reducers/staticPages";
import { getLecturesLookupList } from "../../../../reducers/lectures";
import { getUsersLookupList } from "../../../../reducers/users";
import styles from "../../../../assets/jss/pages/formStyle";

const useStyles = makeStyles(styles);

const commentStateDefaultValues = Object.entries(commentInitialState).reduce(
  (result, [key, value]) => {
    result[key] = value.value;
    return result;
  },
  {}
);

const allowedFileExtensions = [
  ".jpg",
  ".png",
  ".jpeg",
  ".gif",
  ".pdf",
  ".docx",
  ".pptx",
  ".xlsx"
];
const maxAllowedFileSizeInBytes = 10485760;

const CommentsFormContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const selectedComment = useSelector(getSelectedComment);
  const isCommentSaved = useSelector(getIsCommentSaved);

  const staticPagesLookup = useSelector(getStaticPagesLookupList);
  const lecturesLookup = useSelector(getLecturesLookupList);
  const usersLookup = useSelector(getUsersLookupList);
  const commentsLookup = useSelector(getCommentsLookupList);

  const dispatch = useDispatch();

  const [filesForUpload, setFilesForUpload] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  /**
   * We set this flag to detect when the 'save all'
   * option was clicked and we need to navigate back to list.
   */
  const needsNavigationToList = useRef(false);

  const onInputChange = () => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  /**
   * Initialize lecture values for form.
   */
  const { getInitialState: commentState } = useObjectMapper({
    initialState: commentInitialState,
    selectedItem: selectedComment
  });

  const {
    values: comment,
    validations,
    handleInputChange,
    handleInputFocus,
    isFormValid
  } = useForm({
    initialValues: commentState,
    defaultEmptyValues: commentStateDefaultValues,
    onInputChange: onInputChange
  });

  useEffect(() => {
    dispatch(getStaticPageTranslationsLookup());
    dispatch(getLectureTranslationsLookup());
    dispatch(getUsersLookup());
    dispatch(getCommentsLookup());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getCommentDetails({ id }));
    }
  }, [dispatch, id]);

  /**
   * The unsaved changes flag has changed back to false
   * after the 'save' option was clicked, so we need to navigate
   * back to list.
   */
  useEffect(() => {
    if (!hasUnsavedChanges && needsNavigationToList.current) {
      history.push("/admin/comments");
    }
  }, [history, hasUnsavedChanges]);

  /**
   * Update route on create lecture action.
   */
  useEffect(() => {
    if (
      selectedComment?.id &&
      history.location.pathname === "/admin/comments/form"
    ) {
      history.replace(`/admin/comments/form/${selectedComment.id}`);
    }
  }, [selectedComment, history]);

  useEffect(() => {
    if (selectedComment?.id && selectedComment?.fileName) {
      const uploadedFileInfo = {
        uid: `comment_${selectedComment.id}_file`,
        name: selectedComment.fileName,
        extension: selectedComment.fileName.slice(
          selectedComment.fileName.lastIndexOf(".") - 1
        ),
        progress: 100,
        status: UploadFileStatus.Uploaded
      };

      setFilesForUpload([uploadedFileInfo]);
    }
  }, [selectedComment]);

  const handleAddFileForUpload = event => {
    setFilesForUpload(event.newState);
  };

  const handleRemoveFileForUpload = event => {
    setFilesForUpload(event.newState);
  };

  const handleSave = () => {
    if (hasUnsavedChanges) {
      // We extract fileName because it is used only for displaying.
      const { fileName, ...rest } = comment;

      const formData = new FormData();

      // Don't upload files with disallowed extensions
      // (everything except .jpg, .png, .jpeg, .gif, .pdf, .docx, .pptx, .xlsx)
      // or ones that exceed max file size (10 MB).
      if (
        filesForUpload?.length &&
        filesForUpload[0].size <= maxAllowedFileSizeInBytes &&
        allowedFileExtensions.includes(filesForUpload[0].extension)
      ) {
        formData.append("file", filesForUpload[0].getRawFile());
      }

      Object.entries(rest).forEach(([key, value]) => {
        if (
          value ||
          (!value && commentInitialState[key].meta.type !== "number")
        ) {
          formData.append(key, value);
        }
      });

      if (!id && !selectedComment?.id) {
        dispatch(addCommentThenResetForm({ data: formData }));
      } else {
        formData.append("id", id || selectedComment.id);

        dispatch(
          updateCommentThenResetForm({
            data: formData
          })
        );
      }

      setHasUnsavedChanges(false);
      needsNavigationToList.current = true;
    }
  };

  const handleCancel = () => {
    history.push("/admin/comments");
  };

  return (
    <div className={classes.container}>
      <Prompt
        when={hasUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      <Form
        initialData={commentInitialState}
        data={comment}
        validations={validations}
        staticPages={staticPagesLookup}
        lectures={lecturesLookup}
        users={usersLookup}
        comments={commentsLookup}
        filesForUpload={filesForUpload}
        isFormValid={isFormValid}
        isEditMode={isCommentSaved || selectedComment?.id ? true : false}
        hasUnsavedChanges={hasUnsavedChanges}
        onInputFocus={handleInputFocus}
        onInputChange={handleInputChange}
        onFileAdd={handleAddFileForUpload}
        onFileRemove={handleRemoveFileForUpload}
        onSave={handleSave}
        onReturn={handleCancel}
      />
    </div>
  );
};

export default CommentsFormContainer;
