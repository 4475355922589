import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch, useSelector } from "react-redux";
import PerfectScrollbar from "perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";

import SubquestionsList from "./SubquestionsList";
import Answers from "./Answers";
import DropDownList from "../../../../../components/Form/DropDownList";
import Form from "../../../../../components/PageForm/PageForm";
import { formatLabel } from "../../../../../services/utilities/stringManipulation";
import useForm from "../../../../../hooks/useForm";
import {
  generalQuestionInitialState,
  findHiddenWordsTypeInitialState,
  questionsAndAnswersParentTypeInitialState,
  subquestionEditInitialState
} from "../LectureTranslationFields";
import useObjectMapper from "../../../../../hooks/useObjectMapper";
import { QUESTION_TYPES } from "../../../../../enums/questionTypes";
import Switch from "../../../../../components/Form/Switch";
import { CRUD_TYPES } from "../../../../../enums/crudTypes";
import {
  getQuestionAnswers,
  deleteAnswerThenGetAnswers
} from "../../../../../actions/questions";
import { getAnswersList } from "../../../../../reducers/questions";
import styles from "../../../../../assets/jss/pages/questionsFormStyle";

const useStyles = makeStyles(styles);

let ps;

const QuestionDialog = ({
  question = null,
  mode,
  types = [],
  numOfQuestions = 0,
  onSave = () => {},
  onCancel = () => {}
}) => {
  const classes = useStyles();
  const [selectedTypeId, setSelectedTypeId] = useState();
  const [hasSubquestions, setHasSubquestions] = useState(
    question?.isParentQuestion || false
  );
  const [subquestions, setSubquestions] = useState(
    question?.subquestions || []
  );
  const dialogContentRef = useRef();

  const remoteAnswers = useSelector(getAnswersList);
  const [answers, setAnswers] = useState(remoteAnswers || []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(dialogContentRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelSpeed: 2,
        wheelPropagation: false
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        ps = null;
      }
    };
  }, []);

  useEffect(() => {
    if (question) {
      setSelectedTypeId(types.find(x => x.id === question.typeId)?.id);
    }
  }, [question, types]);

  useEffect(() => {
    if (mode === CRUD_TYPES.Edit && question && !question.isParentQuestion) {
      dispatch(getQuestionAnswers({ data: { questionId: question.id } }));
    }
  }, [dispatch, mode, question]);

  useEffect(() => {
    setAnswers(answersList =>
      remoteAnswers?.[0]?.questionId !== answersList?.[0]?.questionId
        ? remoteAnswers
        : answersList.map(x => {
            let remoteAnswer;
            if ((remoteAnswer = remoteAnswers.find(y => y.id === x.id))) {
              return { ...x, number: remoteAnswer.number };
            }

            return x;
          })
    );
  }, [remoteAnswers]);

  const updateParentQuestionTitleOnInputChangeInCreateMode = (_name, value) => {
    if (mode === CRUD_TYPES.Insert && subquestions.length) {
      setSubquestions(
        subquestions.map(subq => ({
          ...subq,
          title: subq.title.length ? value : subq.title
        }))
      );
    }
  };

  const getInitialStateBasedOnType = () =>
    selectedTypeId === QUESTION_TYPES.FindHiddenWords
      ? findHiddenWordsTypeInitialState
      : selectedTypeId === QUESTION_TYPES.QuestionsAndAnswers
      ? hasSubquestions
        ? questionsAndAnswersParentTypeInitialState
        : question?.isParentQuestion === false &&
          Number.isInteger(question?.number) === false
        ? subquestionEditInitialState
        : generalQuestionInitialState
      : generalQuestionInitialState;

  const { getInitialState: questionState } = useObjectMapper({
    initialState: getInitialStateBasedOnType(),
    selectedItem: question
  });

  const {
    values,
    validations,
    isFormValid,
    handleInputFocus,
    handleInputChange,
    resetForm
  } = useForm({
    initialValues: questionState,
    onInputChange: updateParentQuestionTitleOnInputChangeInCreateMode
  });

  const handleChangeType = event => {
    const newValue = event.target.value;
    setSelectedTypeId(newValue.id);

    if (hasSubquestions) {
      setHasSubquestions(false);
      setSubquestions([]);
    }

    resetForm();
  };

  const toggleSubquestions = () => {
    setHasSubquestions(!hasSubquestions);
    setSubquestions([]);
  };

  const addSubquestion = newSubquestion => {
    setSubquestions([
      ...subquestions,
      mode === CRUD_TYPES.Insert
        ? {
            name: "",
            title: subquestions.length === 0 ? values.title : "",
            subtitle: newSubquestion,
            number: subquestions.length + 1
          }
        : {
            name: `Q${question?.number + (subquestions.length + 1) * 0.1}`,
            title: subquestions.length === 0 ? values.title : "",
            subtitle: newSubquestion,
            number: question?.number + (subquestions.length + 1) * 0.1,
            typeId: selectedTypeId,
            gridSize: null
          }
    ]);
  };

  const addAnswer = newAnswer => {
    const { id, inEdit, forDelete, ...answerData } = newAnswer;

    setAnswers([
      ...answers,
      {
        id: `temp-${answerData.number}`,
        ...answerData
      }
    ]);
  };

  const updateAnswer = answer => {
    const { inEdit, forDelete, ...answerData } = answer;

    setAnswers(
      answers.map(x => {
        if (x.id === answer.id) {
          return answerData;
        }

        return x;
      })
    );
  };

  const updateFollowingUnsavedAnswersNumberAndIdAfterDeletion = answerId => {
    const answerIndexInArray = answers.findIndex(x => x.id === answerId);

    const answersWithoutDeleted = [
      ...answers.slice(0, answerIndexInArray),
      ...answers.slice(answerIndexInArray + 1).map(x => ({
        ...x,
        id: Number.isFinite(x.id) ? x.id : `temp-${x.number - 1}`,
        number: x.number - 1
      }))
    ];

    setAnswers(answersWithoutDeleted);
  };

  const deleteAnswer = answerId => {
    updateFollowingUnsavedAnswersNumberAndIdAfterDeletion(answerId);

    if (Number.isFinite(answerId)) {
      dispatch(
        deleteAnswerThenGetAnswers({
          data: {
            id: answerId,
            questionId: question.id,
            isFetchAfterDelete: true
          }
        })
      );
    }
  };

  const handleSave = () => {
    const questionsPayload = [];

    if (
      selectedTypeId === QUESTION_TYPES.QuestionsAndAnswers &&
      hasSubquestions
    ) {
      const parentQuestionNumber = question
        ? question.number
        : numOfQuestions + 1;

      subquestions.forEach(subq => {
        questionsPayload.push(
          mode === CRUD_TYPES.Insert
            ? {
                title: subq.title,
                subtitle: subq.subtitle,
                number: parentQuestionNumber + subq.number * 0.1,
                name: `Q${parentQuestionNumber + subq.number * 0.1}`,
                typeId: selectedTypeId,
                gridSize: null
              }
            : {
                ...subq,
                title: subq.isFirstSubquestion ? values.title : subq.title
              }
        );
      });
    } else {
      const {
        expanded,
        isParentQuestion,
        parentQuestionTitle,
        isFirstSubquestion,
        ...originalValues
      } = question || {};

      const questionObj = question
        ? {
            ...originalValues,
            ...values,
            title:
              !isFirstSubquestion && typeof parentQuestionTitle === "string"
                ? ""
                : values.title
          }
        : {
            typeId: selectedTypeId,
            number: numOfQuestions + 1,
            name: `Q${numOfQuestions + 1}`,
            gridSize: null,
            ...values
          };

      questionsPayload.push(questionObj);
    }

    const answersPayload =
      selectedTypeId !== QUESTION_TYPES.QuestionsAndAnswers ||
      (selectedTypeId === QUESTION_TYPES.QuestionsAndAnswers &&
        !hasSubquestions)
        ? answers.map(x => {
            if (Number.isFinite(x.id) === false) {
              const { id, ...rest } = x;

              return rest;
            }

            return x;
          })
        : null;

    onSave({ questions: questionsPayload, answers: answersPayload });
  };

  const renderQuestionForm = () => {
    return (
      <Form
        title="Question Information"
        initialData={getInitialStateBasedOnType()}
        data={values}
        validations={validations}
        isFormValid={isFormValid}
        isEditMode={mode === CRUD_TYPES.Edit ? true : false}
        onInputFocus={handleInputFocus}
        onInputChange={handleInputChange}
        preForm={() => (
          <>
            {selectedTypeId === QUESTION_TYPES.QuestionsAndAnswers &&
            (!question || question?.isParentQuestion) ? (
              <Switch
                label="Does this question have subquestions?"
                value={question ? question.isParentQuestion : hasSubquestions}
                isDisabled={question ? true : false}
                onChange={toggleSubquestions}
              />
            ) : null}
          </>
        )}
        postForm={() => (
          <>
            {hasSubquestions ? (
              <SubquestionsList
                subquestions={subquestions}
                onAdd={addSubquestion}
              />
            ) : null}
          </>
        )}
      />
    );
  };

  return (
    <Dialog width={1020}>
      <div ref={dialogContentRef} className={classes.dialogContentWrapper}>
        <DropDownList
          label={formatLabel("Type", true)}
          name="typeId"
          data={types}
          value={selectedTypeId}
          isDisabled={question ? true : false}
          onChange={handleChangeType}
        />

        {selectedTypeId ? (
          <>
            {renderQuestionForm()}

            {selectedTypeId !== QUESTION_TYPES.QuestionsAndAnswers ||
            (selectedTypeId === QUESTION_TYPES.QuestionsAndAnswers &&
              !hasSubquestions) ? (
              <Answers
                answers={answers.sort((a, b) => a.number - b.number)}
                type={selectedTypeId}
                onAdd={addAnswer}
                onUpdate={updateAnswer}
                onDelete={deleteAnswer}
              />
            ) : null}
          </>
        ) : null}
      </div>

      <DialogActionsBar>
        <Button onClick={onCancel}>Cancel</Button>

        <Button
          primary
          disabled={
            !isFormValid ||
            (isFormValid &&
              selectedTypeId === QUESTION_TYPES.QuestionsAndAnswers &&
              hasSubquestions &&
              subquestions.length === 0)
          }
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default QuestionDialog;
