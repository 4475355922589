export const getAuthStateFromStorage = () => {
  try {
    const stringifiedState = localStorage.getItem("@paprac:authData");
    if (stringifiedState === null) {
      return undefined;
    }
    return JSON.parse(stringifiedState);
  } catch (error) {
    return undefined;
  }
};

export const setAuthStateToStorage = authData => {
  try {
    localStorage.setItem("@paprac:authData", JSON.stringify(authData));
  } catch (error) {}
};

export const removeAuthStateFromStorage = () => {
  return localStorage.removeItem("@paprac:authData");
};
