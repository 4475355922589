import React, { useState, useEffect, useCallback } from "react";
import { TreeList } from "@progress/kendo-react-treelist";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";

import { QUESTION_TYPES } from "../../../../../enums/questionTypes";
import FormContainer from "../../../../../components/Form/FormContainer";
import TextEditCell from "../../../../../components/GridCell/TextEditCell";
import NumberEditCell from "../../../../../components/GridCell/NumberEditCell";
import { getColumnsByType } from "./AnswerListFields";
import styles from "../../../../../assets/jss/pages/questionsFormStyle";

const useStyles = makeStyles(styles);

const newAnswerTempId = "temp";

const getInitialState = numOfAnswers => ({
  id: newAnswerTempId,
  answer: null,
  points: null,
  number: numOfAnswers + 1,
  hiddenComment: null,
  sentence1: null,
  sentence2: null,
  questionOrStatement: null,
  inEdit: true
});

const Answers = ({
  answers: originalAnswers = [],
  type,
  onAdd,
  onUpdate,
  onDelete
}) => {
  const classes = useStyles();
  const [answers, setAnswers] = useState(originalAnswers);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [idInEdit, setIdInEdit] = useState();
  const [idForDelete, setIdForDelete] = useState();
  const [newAnswer, setNewAnswer] = useState(
    getInitialState(originalAnswers.length)
  );

  useEffect(() => {
    setAnswers(originalAnswers);
    setNewAnswer(getInitialState(originalAnswers.length));
  }, [originalAnswers]);

  const handleAddClick = () => {
    setIsFormVisible(true);
    setIdInEdit(newAnswerTempId);
  };

  const handleAddConfirm = () => {
    onAdd(newAnswer);

    setIsFormVisible(false);
    setIdInEdit(null);
    setNewAnswer(getInitialState(originalAnswers.length));
  };

  const handleAddCancel = () => {
    setNewAnswer(getInitialState(originalAnswers.length));

    setIsFormVisible(false);
    setIdInEdit(null);
  };

  const handleEdit = dataItem => {
    setIdInEdit(dataItem.id);

    if (isFormVisible) {
      setIsFormVisible(false);
      setNewAnswer(getInitialState(originalAnswers.length));
    }

    if (idForDelete) {
      handleDeleteCancel();
    }
  };

  const handleEditConfirm = () => {
    onUpdate(answers.find(x => x.id === idInEdit));

    setIdInEdit(null);
  };

  const handleEditCancel = () => {
    setIdInEdit(null);

    setAnswers(originalAnswers);
  };

  const handleDelete = dataItem => {
    setIdForDelete(dataItem.id);

    if (isFormVisible) {
      handleAddCancel();
    }

    if (idInEdit) {
      handleEditCancel();
    }
  };

  const handleDeleteConfirm = () => {
    onDelete(idForDelete);

    setIdForDelete(null);
  };

  const handleDeleteCancel = () => {
    setIdForDelete(null);
  };

  const handleItemChange = useCallback(
    event => {
      const fieldName = event.target.name;
      const fieldValue = event.target.value;

      if (idInEdit === newAnswerTempId) {
        setNewAnswer(x => ({ ...x, [fieldName]: fieldValue }));
      } else {
        setAnswers(x =>
          x.map(y => ({
            ...y,
            [fieldName]: y.id === idInEdit ? fieldValue : y[fieldName]
          }))
        );
      }
    },
    [idInEdit]
  );

  const textEditCell = useCallback(
    props => (
      <TextEditCell
        {...props}
        isRequired={true}
        isTouched={true}
        isValid={props.dataItem[props.field]?.length > 0}
        onChange={handleItemChange}
      />
    ),
    [handleItemChange]
  );

  const optionalTextEditCell = useCallback(
    props => <TextEditCell {...props} onChange={handleItemChange} />,
    [handleItemChange]
  );

  const numberEditCell = useCallback(
    props => (
      <NumberEditCell
        {...props}
        isRequired={true}
        isTouched={true}
        isValid={
          props.dataItem[props.field] || props.dataItem[props.field] === 0
            ? true
            : false
        }
        onChange={handleItemChange}
      />
    ),
    [handleItemChange]
  );

  const getColumns = () =>
    getColumnsByType({
      type,
      editField: "inEdit",
      deleteField: "forDelete",
      textEditCell,
      optionalTextEditCell,
      numberEditCell,
      onEdit: handleEdit,
      onSaveConfirm:
        idInEdit === newAnswerTempId ? handleAddConfirm : handleEditConfirm,
      onSaveCancel:
        idInEdit === newAnswerTempId ? handleAddCancel : handleEditCancel,
      onDelete: handleDelete,
      onDeleteConfirm: handleDeleteConfirm,
      onDeleteCancel: handleDeleteCancel
    });

  const answersForDisplay = isFormVisible
    ? [...answers, newAnswer].map(x => ({
        ...x,
        inEdit: x.id === idInEdit ? true : false,
        forDelete: x.id === idForDelete ? true : false
      }))
    : [...answers].map(x => ({
        ...x,
        inEdit: x.id === idInEdit ? true : false,
        forDelete: x.id === idForDelete ? true : false
      }));

  return (
    <FormContainer title="Answers">
      <TreeList
        className={classes.answersList}
        data={answersForDisplay}
        editField="inEdit"
        columns={getColumns()}
      />

      {type === QUESTION_TYPES.FindHiddenWords && answers.length > 0 ? null : (
        <Button
          className={classes.dialogAddButton}
          primary
          onClick={handleAddClick}
          disabled={isFormVisible}
        >
          Add answer
        </Button>
      )}
    </FormContainer>
  );
};

export default Answers;
