import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink, Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

import avatar from "../assets/img/default-avatar.png";
import logo from "../assets/img/logo.gif";

import styles from "../assets/jss/components/sidebarStyle";
// import { avatarInitials } from "../services/utilities/stringManipulation";

let ps;

// Comment from Creative Tim:
// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapperRef = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapperRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapperRef}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(this.props.routes)
    };
  }
  mainPanelRef = React.createRef();

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.pages),
          ...this.getCollapseStates(prop.pages),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page
  getCollapseInitialState = routes => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].pages)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  openCollapse = collapse => {
    let st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color } = this.props;
    return routes.map((route, key) => {
      if (
        route.redirect ||
        route.layout === "/auth" ||
        route.removeFromSidebar
      ) {
        return null;
      }

      if (route.collapse) {
        let st = {};
        st[route["state"]] = !this.state[route.state];

        const navLinkClasses = `${classes.itemLink} ${classnames({
          [" " + classes.collapseActive]: this.getCollapseInitialState(
            route.pages
          )
        })}`;
        const itemText = `${classes.itemText} ${classnames({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
        })}`;
        const collapseItemText = `${classes.collapseItemText} ${classnames({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive
        })}`;
        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        const collapseItemMini = classes.collapseItemMini;
        return (
          <ListItem
            key={key}
            className={classnames(
              { [classes.item]: route.icon !== undefined },
              { [classes.collapseItem]: route.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {route.icon !== undefined ? (
                typeof route.icon === "string" ? (
                  <Icon className={itemIcon}>{route.icon}</Icon>
                ) : (
                  <route.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{route.mini}</span>
              )}
              <ListItemText
                primary={route.name}
                secondary={
                  <b
                    className={`${caret} ${
                      this.state[route.state] ? classes.caretActive : ""
                    }`}
                  />
                }
                disableTypography={true}
                className={classnames(
                  { [itemText]: route.icon !== undefined },
                  { [collapseItemText]: route.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[route.state]} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                {this.createLinks(route.pages)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      const innerNavLinkClasses = `${classes.collapseItemLink} ${classnames({
        [" " + classes[color]]: this.activeRoute(route.path)
      })}`;
      const collapseItemMini = classes.collapseItemMini;
      const navLinkClasses = `${classes.itemLink} ${classnames({
        [" " + classes[color]]: this.activeRoute(route.path)
      })}`;
      const itemText = `${classes.itemText} ${classnames({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
      })}`;
      const collapseItemText = `${classes.collapseItemText} ${classnames({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive
      })}`;
      const itemIcon = classes.itemIcon;

      return (
        <ListItem
          key={key}
          className={classnames(
            { [classes.item]: route.icon !== undefined },
            { [classes.collapseItem]: route.icon === undefined }
          )}
        >
          <NavLink
            to={route.layout + route.path}
            className={classnames(
              { [navLinkClasses]: route.icon !== undefined },
              { [innerNavLinkClasses]: route.icon === undefined }
            )}
          >
            {route.icon !== undefined ? (
              typeof route.icon === "string" ? (
                <Icon className={itemIcon}>{route.icon}</Icon>
              ) : (
                <route.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>{route.mini}</span>
            )}
            <ListItemText
              primary={route.name}
              disableTypography={true}
              className={classnames(
                { [itemText]: route.icon !== undefined },
                { [collapseItemText]: route.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  render() {
    const { classes, image, logoText, routes, bgColor } = this.props;

    const itemText = `${classes.itemText} ${classnames({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
    })}`;
    // const collapseItemText = `${classes.collapseItemText} ${classnames({
    //   [classes.collapseItemTextMini]:
    //     this.props.miniActive && this.state.miniActive
    // })}`;
    const userWrapperClass = `${classes.user} ${classnames({
      [classes.whiteAfter]: bgColor === "white"
    })}`;
    // const caret = classes.caret;
    // const collapseItemMini = classes.collapseItemMini;
    const photo = classes.photo;

    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="avatar" />
        </div>
        <List className={classes.list}>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <NavLink
              to={"#"}
              className={`${classes.itemLink} ${classes.userCollapseButton}`}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={this.props.user?.userName || "User"}
                // secondary={
                //   <b
                //     className={`${caret} ${classes.userCaret} ${
                //       this.state.openAvatar ? classes.caretActive : ""
                //     }`}
                //   />
                // }
                disableTypography={true}
                className={`${itemText} ${classes.userItemText}`}
              />
            </NavLink>
            {/* <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  >
                    <span className={collapseItemMini}>
                      {avatarInitials(this.props.user?.name) || "MP"}
                    </span>
                    <ListItemText
                      primary={this.props.user?.name || "My Profile"}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse> */}
          </ListItem>
        </List>
      </div>
    );

    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal = `${classes.logoNormal} ${classnames({
      [classes.logoNormalSidebarMini]:
        this.props.miniActive && this.state.miniActive
    })}`;
    const logoMini = classes.logoMini;
    const logoClasses = `${classes.logo} ${classnames({
      [classes.whiteAfter]: bgColor === "white"
    })}`;

    const brand = (
      <div className={logoClasses}>
        <Link to="/" rel="noopener noreferrer" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </Link>
        <Link to="/" rel="noopener noreferrer" className={logoNormal}>
          {logoText}
        </Link>
      </div>
    );
    const drawerPaper = `${classes.drawerPaper} ${classnames({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive
    })}`;
    const sidebarWrapper = `${classes.sidebarWrapper} ${classnames({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    })}`;

    return (
      <div ref={this.mainPanelRef}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.props.open}
            classes={{
              paper: `${drawerPaper} ${classes[bgColor + "Background"]}`
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              // headerLinks={<AdminNavbarLinks />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={"left"}
            variant="permanent"
            open
            classes={{
              paper: `${drawerPaper} ${classes[bgColor + "Background"]}`
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["black"]),
  color: PropTypes.oneOf(["blue"]),
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object
};

export default withStyles(styles)(Sidebar);
