export const lectureInitialState = {
  name: {
    label: "Name",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100
    }
  },
  courseId: {
    label: "Course",
    value: null,
    meta: {
      type: "number",
      dataItemKey: "id",
      isRequired: true,
      lookupType: "dropDown",
      lookupKey: "courses"
    }
  },
  number: {
    label: "Number",
    value: null,
    meta: {
      type: "number",
      isRequired: true
    }
  },
  isActive: {
    label: "Active?",
    value: false,
    meta: {
      type: "bool"
    }
  }
};

export const translationInitialState = {
  languageId: {
    label: "Select language",
    value: null,
    meta: {
      type: "number",
      dataItemKey: "languageId",
      textField: "languageName",
      isRequired: true,
      lookupType: "dropDown",
      lookupKey: "languages"
    }
  },
  title: {
    label: "Title",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 255
    }
  },
  subtitle: {
    label: "Subtitle",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 255
    }
  },
  informativeIntroductionBasicText: {
    label: "Informative Introduction Text Basic",
    value: "",
    meta: {
      type: "rich_text"
    }
  },
  informativeIntroductionAdvancedText: {
    label: "Informative Introduction Text Advanced",
    value: "",
    meta: {
      type: "rich_text"
    }
  },
  basicText: {
    label: "Text (Basic)",
    value: "",
    meta: {
      type: "rich_text"
    }
  },
  advancedText: {
    label: "Text (Advanced)",
    value: "",
    meta: {
      type: "rich_text"
    }
  },
  durationDays: {
    label: "Duration in Days",
    value: 7,
    meta: {
      type: "number"
    }
  },
  isActive: {
    label: "Active?",
    value: false,
    meta: {
      type: "bool"
    }
  }
};

export const generalQuestionInitialState = {
  title: {
    label: "Title",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 255
    }
  },
  subtitle: {
    label: "Subtitle",
    value: "",
    meta: {
      type: "text",
      maxLength: 255
    }
  }
};

export const findHiddenWordsTypeInitialState = {
  ...generalQuestionInitialState,
  gridSize: {
    label: "Grid size (square grid)",
    value: null,
    meta: {
      type: "number",
      isRequired: true,
      min: 0,
      step: 1,
      format: "n0"
    }
  }
};

export const questionsAndAnswersParentTypeInitialState = {
  title: { ...generalQuestionInitialState.title }
};

export const subquestionEditInitialState = {
  title: {
    ...generalQuestionInitialState.title,
    meta: {
      ...generalQuestionInitialState.title.meta,
      disabled: true
    }
  },
  subtitle: { ...generalQuestionInitialState.subtitle }
};
