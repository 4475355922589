import { toDataSourceRequestString } from "@progress/kendo-data-query";

export function getComments(dataState) {
  return {
    path: `comments?${toDataSourceRequestString(dataState)}`
  };
}

export function getCommentDetails(id) {
  return {
    path: `comments/${id}`
  };
}

export function addComment(data) {
  return {
    path: "comments",
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    successNotificationConfig: {
      message: "Comment successfully created!"
    }
  };
}

export function updateComment(data) {
  return {
    path: `comments/${data.id}`,
    method: "PUT",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    successNotificationConfig: {
      message: "Comment successfully updated!"
    }
  };
}

export function deleteComment(id) {
  return {
    path: `comments/${id}`,
    method: "DELETE",
    data: id,
    successNotificationConfig: {
      message: "Comment successfully deleted!"
    }
  };
}

export function getCommentsLookup() {
  return {
    path: "comments/lookup"
  };
}
export function getCommentsByLanguage({ data }) {
  return {
    path: "comments/lookup",
    data
  };
}
