import React from "react";
import { RadioGroup } from "@progress/kendo-react-inputs";

import FormElement from "./FormElement";
import { RADIO } from "../../constants/dropDownTypes";

const RadioInput = ({
  name = "",
  label = "",
  dataItemKey = "id",
  textField = "name",
  data = [],
  value = null,
  isDisabled = false,
  isRequired = false,
  isTouched = false,
  isFocused = false,
  isValid,
  errorMessage = "",
  onChange = () => {},
  ...rest
}) => {
  const dataForRadioGroup = data.map(x => ({
    label: x[textField],
    value: x[dataItemKey]
  }));

  const handleChange = event => {
    const payload = {
      target: {
        name,
        type: RADIO,
        value: event.value
      }
    };

    onChange(payload);
  };

  return (
    <FormElement label={label}>
      <RadioGroup
        layout="horizontal"
        name={name}
        data={dataForRadioGroup}
        value={value}
        valid={isRequired && isTouched ? isValid : undefined}
        onChange={handleChange}
      />
    </FormElement>
  );
};

export default RadioInput;
