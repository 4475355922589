import { callApi } from "../services/api/api";

import {
  getLectureQuestions as getLectureQuestionsApi,
  getQuestionAnswers as getQuestionAnswersApi,
  addOrUpdateQuestions as addOrUpdateQuestionsApi,
  addOrUpdateQuestionAnswers as addOrUpdateQuestionAnswersApi,
  deleteQuestion as deleteQuestionApi,
  deleteQuestionAnswer as deleteQuestionAnswerApi
} from "../pages/admin/lectures/apiConfig";
import generateActions from "../services/utilities/generateActions";

export const GET_LECTURE_QUESTIONS = generateActions("GET_LECTURE_QUESTIONS");
export const GET_QUESTION_ANSWERS = generateActions("GET_QUESTION_ANSWERS");
export const ADD_OR_UPDATE_LECTURE_QUESTIONS = generateActions(
  "ADD_OR_UPDATE_LECTURE_QUESTIONS"
);
export const ADD_OR_UPDATE_QUESTION_ANSWERS = generateActions(
  "ADD_OR_UPDATE_QUESTION_ANSWERS"
);
export const DELETE_LECTURE_QUESTION = generateActions(
  "DELETE_LECTURE_QUESTION"
);
export const DELETE_QUESTION_ANSWER = generateActions("DELETE_QUESTION_ANSWER");

export const RESET_ANSWERS_STATE = "RESET_ANSWERS_STATE";
/**
 * QUESTION AND ANSWER ACTION CREATORS
 */

export function getLectureQuestions({ data }) {
  return {
    type: GET_LECTURE_QUESTIONS.ACTION,
    promise: callApi(getLectureQuestionsApi(data)),
    payload: data
  };
}

export function getQuestionAnswers({ data }) {
  return {
    type: GET_QUESTION_ANSWERS.ACTION,
    promise: callApi(getQuestionAnswersApi(data)),
    payload: data
  };
}

export function addOrUpdateQuestions({ data }) {
  return {
    type: ADD_OR_UPDATE_LECTURE_QUESTIONS.ACTION,
    promise: callApi(addOrUpdateQuestionsApi(data)),
    payload: data
  };
}

export function addOrUpdateQuestionAnswers({ data }) {
  return {
    type: ADD_OR_UPDATE_QUESTION_ANSWERS.ACTION,
    promise: callApi(addOrUpdateQuestionAnswersApi(data)),
    payload: data
  };
}

export function deleteQuestion({ data }) {
  return {
    type: DELETE_LECTURE_QUESTION.ACTION,
    promise: callApi(deleteQuestionApi(data)),
    payload: data
  };
}

export function deleteQuestionAnswer({ data }) {
  return {
    type: DELETE_QUESTION_ANSWER.ACTION,
    promise: callApi(deleteQuestionAnswerApi(data)),
    payload: data
  };
}

export function resetAnswersState() {
  return {
    type: RESET_ANSWERS_STATE
  };
}

/**
 * THUNKS
 */

export function addOrUpdateQuestionsAndAnswers({ questionData, answerData }) {
  return (dispatch, getState) => {
    return dispatch(addOrUpdateQuestions({ data: questionData })).then(() => {
      const addedOrUpdatedQuestionId = getState().questions
        .addedOrUpdatedQuestionId;

      return dispatch(
        addOrUpdateQuestionAnswers({
          data: {
            ...answerData,
            questionId: addedOrUpdatedQuestionId
          }
        })
      );
    });
  };
}

export function deleteQuestionThenGetQuestions({ data }) {
  return dispatch => {
    return dispatch(deleteQuestion({ data })).then(() => {
      return dispatch(getLectureQuestions({ data }));
    });
  };
}

export function deleteAnswerThenGetAnswers({ data }) {
  return dispatch => {
    return dispatch(deleteQuestionAnswer({ data })).then(() => {
      return dispatch(getQuestionAnswers({ data }));
    });
  };
}
