import axios from "axios";

import { authorizationHeader } from "./authorizationHeader";
import {
  handleResponseSuccess,
  handleResponseError
} from "./notificationHandler";

export function callApi({
  method = "get",
  path,
  data,
  headers = {},
  successNotificationConfig = null,
  customErrorCallback = null
}) {
  const authHeader = authorizationHeader();
  const apiCallHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...authHeader,
    ...headers
  };

  return axios({
    method,
    headers: apiCallHeaders,
    url: `/api/${path}`,
    data,
    responseType: "json"
  })
    .then(res => {
      if (!res || res.status === 500) {
        return Promise.reject({
          status: 500,
          message: res?.data?.errors || ""
        });
      }

      if (successNotificationConfig) {
        handleResponseSuccess(successNotificationConfig);
      }
      return res.data;
    })
    .catch(error => {
      if (customErrorCallback && typeof customErrorCallback === "function") {
        customErrorCallback(error);
      } else {
        handleResponseError(error.response);
      }

      return Promise.reject(error);
    });
}
