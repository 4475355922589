import { toast } from "react-toastify";

export function displayNotification(config) {
  toast(config.content, {
    type: config.type || "info",
    autoClose: config.autoClose || 5000,
    toastId: config.notificationId
  });
}

export function handleFormVerificationError(config) {
  displayNotification({
    content: config?.message || "Please fill out all required fields!",
    type: "info",
    autoClose: 3000
  });
}
