import { container } from "../commonStyle";

const formStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    },
    paddingTop: "30px",
    marginBottom: "30px",
    "& div.k-widget.k-upload": {
      marginTop: 10
    }
  },
  tabStrip: {
    "& ul:first-of-type": {
      marginLeft: 40,
      marginRight: 40,
      "& + div.k-content": {
        padding: "14px 0px 14px 24px"
      }
    }
  }
});

export default formStyle;
