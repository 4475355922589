import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

import {
  getLectures,
  changeLectureDataState,
  resetLectureFormState,
  deleteLectureAndUpdateGrid
} from "../../../../actions/lectures";
import CommandCell from "../../../../components/GridCell/CommandCell";
import Dialog from "../../../../components/Dialog";
import columns from "./GridFields";
import styles from "../../../../assets/jss/pages/listStyle";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import {
  getLecturesList,
  getLecturesListDataState,
  getLecturesListTotalNumber
} from "../../../../reducers/lectures";

const useStyles = makeStyles(styles);

const LecturesListContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const idToDelete = useRef();

  const lectures = useSelector(getLecturesList);
  const dataState = useSelector(getLecturesListDataState);
  const total = useSelector(getLecturesListTotalNumber);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLectures({ dataState: dataState }));
  }, [dispatch, dataState]);

  useScrollToTop({ dataState });

  const handleDataStateChange = useCallback(
    event => dispatch(changeLectureDataState({ dataState: event.dataState })),
    [dispatch]
  );

  const handleInsertClick = () => {
    dispatch(resetLectureFormState());
    history.push(`${location.pathname}/form`);
  };

  const handleEditIconClick = dataItem => {
    dispatch(resetLectureFormState());
    history.push(`${location.pathname}/form/${dataItem.id}`);
  };

  const handleDeleteIconClick = dataItem => {
    setIsDialogVisible(true);

    idToDelete.current = dataItem.id;
  };

  const closeDialog = () => {
    setIsDialogVisible(false);

    idToDelete.current = null;
  };

  const confirmDelete = () => {
    dispatch(deleteLectureAndUpdateGrid(idToDelete.current, dataState));
    closeDialog();
  };

  return (
    <div className={classes.container}>
      <Grid
        scrollable="none"
        data={lectures}
        total={total}
        {...dataState}
        sortable={true}
        pageable={true}
        filterable
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
        }}
        onDataStateChange={handleDataStateChange}
      >
        {columns.map((field, index) => {
          const { name, title, cellType, filterable, sortable } = field;
          return (
            <Column
              key={index}
              field={name}
              title={title}
              cell={cellType}
              filterable={filterable}
              sortable={sortable}
            />
          );
        })}
        <Column
          field="_command"
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title="Edit / Delete"
          cell={props => (
            <CommandCell
              {...props}
              handleEdit={handleEditIconClick}
              handleDelete={handleDeleteIconClick}
            />
          )}
        />
      </Grid>

      <Button
        primary
        className={classes.insertButton}
        onClick={handleInsertClick}
      >
        Insert new
      </Button>

      {isDialogVisible && (
        <Dialog handleConfirm={confirmDelete} handleCancel={closeDialog} />
      )}
    </div>
  );
};

export default LecturesListContainer;
