import React, { useEffect, useState, useRef, useMemo } from "react";
// TODO: vidjeti ima li potrebe za mijenjanjem izgleda prompta prilikom navigacije.
// Opcije su ili napraviti custom dialog koji ce pratiti potrebne eventove
// ili koristiti neki 3rd party paket, npr. https://github.com/ZacharyRSmith/react-router-navigation-prompt
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Form from "../../../../components/PageForm/PageForm";
import ActionButtons from "../../../../components/Form/ActionButtons";
import {
  getStaticPageDetails,
  addStaticPage,
  updateStaticPage,
  getStaticPageTranslation,
  addStaticPageTranslation,
  updateStaticPageTranslation,
  resetStaticPageTranslationFormState,
  addStaticPageTranslationThenResetTranslationForm,
  updateStaticPageTranslationThenResetTranslationForm,
  addStaticPageThenResetForm,
  updateStaticPageThenResetForm,
  addStaticPageTranslationThenResetForm,
  updateStaticPageTranslationThenResetForm,
  updateStaticPageThenAddTranslationThenResetForm,
  updateStaticPageThenUpdateTranslationThenResetForm
} from "../../../../actions/staticPages";
import styles from "../../../../assets/jss/pages/formStyle";
import FormInfoText from "../../../../components/Form/FormInfoText";
import useObjectMapper from "../../../../hooks/useObjectMapper";
import useForm from "../../../../hooks/useForm";
import {
  staticPageInitialState,
  translationInitialState
} from "./StaticPageTranslationFields";
import { getCoursesLookup } from "../../../../actions/courses";
import {
  getSelectedStaticPage,
  getIsStaticPageSaved,
  getLanguagesForSelectedStaticPage,
  getSelectedStaticPageTranslation
} from "../../../../reducers/staticPages";
import { getCoursesLookupList } from "../../../../reducers/courses";

const useStyles = makeStyles(styles);

const StaticPagesFormContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const selectedPage = useSelector(getSelectedStaticPage);
  const isSaved = useSelector(getIsStaticPageSaved);
  const languages = useSelector(getLanguagesForSelectedStaticPage);

  const selectedTranslation = useSelector(getSelectedStaticPageTranslation);
  const coursesLookup = useSelector(getCoursesLookupList);

  const dispatch = useDispatch();

  const [
    hasStaticPageUnsavedChanges,
    setHasStaticPageUnsavedChanges
  ] = useState(false);
  const [
    hasTranslationUnsavedChanges,
    setHasTranslationUnsavedChanges
  ] = useState(false);
  /**
   * We set this flag to detect when the 'save all'
   * option was clicked and we need to navigate back to list.
   */
  const needsNavigationToList = useRef(false);

  const onStaticPageInputChange = () => {
    if (!hasStaticPageUnsavedChanges) {
      setHasStaticPageUnsavedChanges(true);
    }
  };

  const onTranslationInputChange = propKey => {
    // Exclude language ID value change from setting
    // unsaved changes flag.
    if (propKey === "languageId") {
      return;
    }

    if (!hasTranslationUnsavedChanges) {
      setHasTranslationUnsavedChanges(true);
    }
  };

  /**
   * Initialize static page values for form.
   */
  const { getInitialState: staticPageState } = useObjectMapper({
    initialState: staticPageInitialState,
    selectedItem: selectedPage
  });

  const {
    values: page,
    validations: pageValidations,
    handleInputFocus: handlePageInputFocus,
    handleInputChange: handlePageInputChange,
    isFormValid: isPageFormValid,
    resetForm: resetStaticPageForm
  } = useForm({
    initialValues: staticPageState,
    onInputChange: onStaticPageInputChange
  });

  /**
   * Initialize translation values for form.
   */
  const { getInitialState: translationState } = useObjectMapper({
    initialState: translationInitialState,
    selectedItem: selectedTranslation
  });

  const {
    values: translation,
    validations: translationValidations,
    handleInputFocus: handleTranslationInputFocus,
    handleInputChange: handleTranslationInputChange,
    onContentEditorChange,
    isFormValid: isTranslationFormValid,
    resetForm: resetTranslationForm
  } = useForm({
    initialValues: translationState,
    onInputChange: onTranslationInputChange
  });

  useEffect(() => {
    if (id) {
      dispatch(getStaticPageDetails(id));
    }
    dispatch(getCoursesLookup());
  }, [dispatch, id]);

  /**
   * One or both of the unsaved changes flags have changed back to false
   * after the 'save all' option was clicked, so we need to navigate
   * back to list.
   */
  useEffect(() => {
    if (
      (!hasStaticPageUnsavedChanges || !hasTranslationUnsavedChanges) &&
      needsNavigationToList.current
    ) {
      history.push("/admin/static-pages");
    }
  }, [history, hasStaticPageUnsavedChanges, hasTranslationUnsavedChanges]);

  /**
   * Track changes in page and translation objects in state.
   * If something changes, check if page has the translation
   * in the currently selected language. If it does, fetch it from api.
   */
  useEffect(() => {
    const staticPageLanguageId = selectedPage?.allLanguagesWithOrWithoutThisStaticPage?.find(
      x => x.languageId === selectedTranslation?.languageId
    )?.staticPageLanguageId;

    if (
      selectedTranslation?.languageId &&
      staticPageLanguageId &&
      !selectedTranslation?.id
    ) {
      dispatch(
        getStaticPageTranslation({
          id: selectedPage?.id,
          staticPageLanguageId
        })
      );
    }
  }, [dispatch, selectedPage, selectedTranslation]);

  // update route on create page action
  useEffect(() => {
    if (
      selectedPage?.id &&
      history.location.pathname === "/admin/static-pages/form"
    ) {
      history.replace(`/admin/static-pages/form/${selectedPage?.id}`);
    }
  }, [selectedPage, history]);

  /**
   * Automatically save translation if form is valid.
   * Otherwise, reset form.
   */
  const handleLanguageChange = e => {
    const newLanguageId = Object.values(e.target.value)[0];

    /**
     * If translation doesn't exist and the form is valid,
     * create it then reset form with new language.
     */
    if (
      isTranslationFormValid &&
      hasTranslationUnsavedChanges &&
      !selectedTranslation.id
    ) {
      let payload = {
        id: selectedPage?.id,
        ...translation,
        staticPageId: selectedPage?.id
      };
      dispatch(
        addStaticPageTranslationThenResetTranslationForm({
          translation: payload,
          newLanguageId: newLanguageId
        })
      );
      setHasTranslationUnsavedChanges(false);
    }

    /**
     * If translation already exists and the form is valid,
     * update it then reset form with new language.
     */
    if (
      isTranslationFormValid &&
      hasTranslationUnsavedChanges &&
      selectedTranslation.id
    ) {
      let payload = {
        id: selectedTranslation.id,
        ...translation,
        staticPageId: selectedPage?.id
      };

      dispatch(
        updateStaticPageTranslationThenResetTranslationForm({
          translation: payload,
          newLanguageId: newLanguageId
        })
      );
      setHasTranslationUnsavedChanges(false);
    }

    /**
     * If there were no changes or if the form isn't valid,
     * cancel changes and reset form.
     */
    if (!hasTranslationUnsavedChanges) {
      dispatch(resetStaticPageTranslationFormState(newLanguageId));
      setHasTranslationUnsavedChanges(false);
    } else if (hasTranslationUnsavedChanges && !isTranslationFormValid) {
      setHasTranslationUnsavedChanges(false);
      dispatch(resetStaticPageTranslationFormState(newLanguageId));
    }
  };

  const handleSavePage = () => {
    if (id || selectedPage) {
      dispatch(updateStaticPage({ id: id || selectedPage.id, ...page }));
    } else {
      dispatch(addStaticPage(page));
    }

    if (hasStaticPageUnsavedChanges) {
      setHasStaticPageUnsavedChanges(false);
    }
  };

  const handleSaveTranslation = () => {
    if (!selectedTranslation.id) {
      dispatch(
        addStaticPageTranslation({
          id: selectedPage?.id,
          ...translation,
          staticPageId: selectedPage?.id
        })
      );
    } else {
      dispatch(
        updateStaticPageTranslation({
          id: selectedTranslation.id,
          ...translation,
          staticPageId: selectedPage?.id
        })
      );
    }

    if (hasTranslationUnsavedChanges) {
      setHasTranslationUnsavedChanges(false);
    }
  };

  const handleSaveAll = e => {
    // There are several possible scenarios:
    // 1. only page form was modified
    // 1.1. page didn't previously exist so we need to create it
    // 1.2. page exists so we need to update it
    // 2. only translation form was modified
    // 2.1. translation didn't previously exist so we need to create it
    // 2.2. translation exists so we need to update it
    // 3. both forms were modified
    // 3.1. translation didn't previously exist
    //      so we need to update page and create translation
    // 3.2. translation exists
    //      so we need to update both page and translation
    if (hasStaticPageUnsavedChanges && !hasTranslationUnsavedChanges) {
      if (!id && !selectedPage?.id) {
        dispatch(addStaticPageThenResetForm({ page }));
      } else {
        dispatch(
          updateStaticPageThenResetForm({
            page: { id: id || selectedPage.id, ...page }
          })
        );
      }

      setHasStaticPageUnsavedChanges(false);
      needsNavigationToList.current = true;
    }

    if (
      hasTranslationUnsavedChanges &&
      !hasStaticPageUnsavedChanges &&
      selectedPage?.id
    ) {
      if (!selectedTranslation?.id) {
        dispatch(
          addStaticPageTranslationThenResetForm({
            translation: {
              id: selectedPage?.id,
              ...translation,
              staticPageId: selectedPage.id
            }
          })
        );
      } else {
        dispatch(
          updateStaticPageTranslationThenResetForm({
            translation: {
              id: selectedTranslation.id,
              ...translation,
              staticPageId: selectedPage.id
            }
          })
        );
      }

      setHasTranslationUnsavedChanges(false);
      needsNavigationToList.current = true;
    }

    if (
      hasStaticPageUnsavedChanges &&
      hasTranslationUnsavedChanges &&
      selectedPage?.id
    ) {
      if (!selectedTranslation?.id) {
        dispatch(
          updateStaticPageThenAddTranslationThenResetForm({
            page: { id: id || selectedPage.id, ...page },
            translation: {
              id: selectedPage?.id,
              ...translation,
              staticPageId: selectedPage.id
            }
          })
        );
      } else {
        dispatch(
          updateStaticPageThenUpdateTranslationThenResetForm({
            page: { id: id || selectedPage.id, ...page },
            translation: {
              id: selectedTranslation.id,
              ...translation,
              staticPageId: selectedPage.id
            }
          })
        );
      }

      setHasStaticPageUnsavedChanges(false);
      setHasTranslationUnsavedChanges(false);
      needsNavigationToList.current = true;
    }
  };

  const handleReset = form => e => {
    if (hasStaticPageUnsavedChanges && form === "StaticPage") {
      resetStaticPageForm();
      setHasStaticPageUnsavedChanges(false);
    }
    if (hasTranslationUnsavedChanges && form === "Translation") {
      resetTranslationForm();
      setHasTranslationUnsavedChanges(false);
    }
  };

  const handleCancel = () => {
    history.push("/admin/static-pages");
  };

  /**
   * Submit (save all) bottun is disabled if there are no changes made,
   * or if one/both forms are changed and not valid.
   */
  const isSubmitDisabled = useMemo(() => {
    return (
      (!hasStaticPageUnsavedChanges && !hasTranslationUnsavedChanges) ||
      (hasStaticPageUnsavedChanges && !isPageFormValid) ||
      (hasTranslationUnsavedChanges && !isTranslationFormValid)
    );
  }, [
    hasStaticPageUnsavedChanges,
    hasTranslationUnsavedChanges,
    isPageFormValid,
    isTranslationFormValid
  ]);
  return (
    <div className={classes.container}>
      <Prompt
        when={hasStaticPageUnsavedChanges || hasTranslationUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      <Form
        initialData={staticPageInitialState}
        data={page}
        validations={pageValidations}
        courses={coursesLookup}
        isFormValid={isPageFormValid}
        isEditMode={isSaved || selectedPage?.id ? true : false}
        hasUnsavedChanges={hasStaticPageUnsavedChanges}
        onInputFocus={handlePageInputFocus}
        onInputChange={handlePageInputChange}
        onContentEditorChange={onContentEditorChange}
        onSave={handleSavePage}
        onReset={handleReset("StaticPage")}
        onCancel={handleCancel}
      />

      {!isSaved && !id ? (
        <FormInfoText
          type="info"
          label="Create basic page information to be able to create translation"
        />
      ) : null}

      {isSaved || id ? (
        <>
          <Form
            title="Translation"
            initialData={translationState}
            data={translation}
            validations={translationValidations}
            languages={languages}
            isFormValid={isTranslationFormValid}
            isEditMode={selectedTranslation?.id ? true : false}
            hasUnsavedChanges={hasTranslationUnsavedChanges}
            onInputFocus={handleTranslationInputFocus}
            onInputChange={handleTranslationInputChange}
            onContentEditorChange={onContentEditorChange}
            onLanguageChange={handleLanguageChange}
            onSave={handleSaveTranslation}
            onReset={handleReset("Translation")}
            folderName={"static-pages"}
            links={{
              title: "Static page link",
              list: [{ url: selectedTranslation?.staticPageTranslationLink }]
            }}
          />

          <ActionButtons
            label="Save all and return"
            isSubmitDisabled={isSubmitDisabled}
            handleSubmit={handleSaveAll}
            handleReturn={handleCancel}
          />
        </>
      ) : null}
    </div>
  );
};

export default StaticPagesFormContainer;
