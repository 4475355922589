export const courseInitialState = {
  name: {
    label: "Name",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100
    }
  },
  isActive: {
    label: "Active",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  isShownInMenu: {
    label: "Shown in menu",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  pointsForBasicCertificate: {
    label: "Points for basic certificate",
    value: null,
    meta: {
      type: "number",
      isRequired: true,
      maxLength: 100
    }
  }
};

export const translationInitialState = {
  languageId: {
    label: "Select Language",
    value: null,
    meta: {
      type: "number",
      dataItemKey: "languageId",
      textField: "languageName",
      isRequired: true,
      lookupType: "dropDown",
      lookupKey: "languages"
    }
  },
  informativeTitle: {
    label: "Informative title",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 255
    }
  },
  informativeSubtitle: {
    label: "Informative subtitle",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 255
    }
  },
  menuTitle: {
    label: "Menu title",
    value: "",
    meta: {
      type: "text",
      maxLength: 100
    }
  },
  informativeText: {
    label: "Informative text",
    value: "",
    meta: {
      type: "rich_text",
      isRequired: true
    }
  },
  scoreText: {
    label: "Score text",
    value: "",
    meta: {
      type: "rich_text",
      isRequired: false
    }
  },
  pointsText: {
    label: "Points text",
    value: "",
    meta: {
      type: "rich_text",
      isRequired: false
    }
  },
  workTitle: {
    label: "Work title",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 255
    }
  },
  workSubtitle: {
    label: "Work subtitle",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 255
    }
  },
  workIntroCommonText: {
    label: "Work intro common text",
    value: "",
    meta: {
      type: "rich_text"
    }
  },
  workIntroAdvancedText: {
    label: "Work intro advanced text",
    value: "",
    meta: {
      type: "rich_text"
    }
  },
  isAdvancedRegistrationFormFieldSet: {
    label: "Advanced registration form field set",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  showAdvancedTest: {
    label: "Show test when course is in advanced mode",
    value: true,
    meta: {
      type: "bool"
    }
  },
  showCommentsAfterLastLectureEnds: {
    label: "Show comments after last lecture ends",
    value: true,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  showAllLecturesAtOnceInBasicMode: {
    label: "Show all lectures at once when course is in basic mode",
    value: true,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  startDate: {
    label: "Course begins",
    value: null,
    meta: {
      type: "date",
      isRequired: false
    }
  },
  finishDate: {
    label: "Course finishes",
    value: null,
    meta: {
      type: "date",
      fieldForMinValue: "startDate",
      isRequired: false
    }
  },
  isActive: {
    label: "Active",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  isShownInMenu: {
    label: "Shown in menu",
    value: false,
    meta: {
      type: "bool"
    }
  },
  menuOrderNumberForInformativePage: {
    label: "Informative page position in menu",
    value: null,
    meta: {
      type: "number",
      isRequired: true
    }
  },
  menuOrderNumberForWorkPage: {
    label: "Work page position in menu",
    value: null,
    meta: {
      type: "number",
      isRequired: true
    }
  }
};
