import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";

import CommandCell from "../../../../components/GridCell/CommandCell";
import Dialog from "../../../../components/Dialog";
import {
  getStaticPages,
  changeStaticPageDataState,
  resetStaticPageFormState,
  deleteStaticPageAndUpdateGrid
} from "../../../../actions/staticPages";
import styles from "../../../../assets/jss/pages/listStyle";
import columns from "./GridFields";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import {
  getStaticPagesList,
  getStaticPagesListDataState,
  getStaticPagesListTotalNumber
} from "../../../../reducers/staticPages";

const useStyles = makeStyles(styles);

// TODO: po zelji dodati loader dok se ne ucitaju podaci za grid:
// https://www.telerik.com/kendo-react-ui/components/grid/advanced-examples/loading-indicator/
const StaticPagesListContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const idToDelete = useRef();

  const staticPages = useSelector(getStaticPagesList);
  const dataState = useSelector(getStaticPagesListDataState);
  const total = useSelector(getStaticPagesListTotalNumber);

  const dispatch = useDispatch();

  const handleDataStateChange = useCallback(
    event => dispatch(changeStaticPageDataState(event.dataState)),
    [dispatch]
  );

  useEffect(() => {
    dispatch(getStaticPages(dataState));
  }, [dispatch, dataState]);

  useScrollToTop({ dataState });

  const handleInsertClick = () => {
    dispatch(resetStaticPageFormState());
    history.push(`${location.pathname}/form`);
  };

  const handleEditIconClick = dataItem => {
    dispatch(resetStaticPageFormState());
    history.push(`${location.pathname}/form/${dataItem.id}`);
  };

  const handleDeleteIconClick = dataItem => {
    setIsDialogVisible(true);

    idToDelete.current = dataItem.id;
  };

  const closeDialog = () => {
    setIsDialogVisible(false);

    idToDelete.current = null;
  };

  const confirmDelete = () => {
    dispatch(deleteStaticPageAndUpdateGrid(idToDelete.current, dataState));

    closeDialog();
  };
  return (
    <div className={classes.container}>
      <Grid
        scrollable="none"
        data={staticPages}
        total={total}
        {...dataState}
        sortable={true}
        pageable={true}
        filterable
        filterOperators={{
          text: [{ text: "grid.filterContainsOperator", operator: "contains" }]
        }}
        onDataStateChange={handleDataStateChange}
      >
        {columns.map((field, index) => {
          const { name, title, cellType, filterable, sortable } = field;
          return (
            <Column
              key={index}
              field={name}
              title={title}
              cell={cellType}
              sortable={sortable}
              filterable={filterable}
            />
          );
        })}
        <Column
          field="_command"
          groupable={false}
          sortable={false}
          filterable={false}
          resizable={false}
          title="Edit / Delete"
          cell={props => (
            <CommandCell
              {...props}
              isDeleteOption={props.dataItem?.isDeleteOption}
              handleEdit={handleEditIconClick}
              handleDelete={handleDeleteIconClick}
            />
          )}
        />
      </Grid>

      <Button
        primary
        className={classes.insertButton}
        onClick={handleInsertClick}
      >
        Insert new
      </Button>
      {isDialogVisible && (
        <Dialog handleConfirm={confirmDelete} handleCancel={closeDialog} />
      )}
    </div>
  );
};

export default StaticPagesListContainer;
