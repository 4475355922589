import React from "react";

import NumericInput from "../Form/NumericInput";

const NumberEditCell = ({
  dataItem,
  field,
  isRequired = false,
  isTouched = false,
  isValid = false,
  onChange
}) => (
  <td>
    <NumericInput
      name={field}
      value={dataItem[field]}
      min={0}
      step={1}
      format="n0"
      isRequired={isRequired}
      isTouched={isTouched}
      isValid={isValid}
      onChange={onChange}
    />
  </td>
);

export default NumberEditCell;
