import { blackColor, hexToRgb, medOpenPrimaryColor } from "../commonStyle";

const formStyle = {
  cardTitle: {
    float: "none",
    display: "inline-block",
    marginRight: "0",
    borderRadius: "3px",
    padding: "0px 15px",
    marginTop: "-20px",
    fontSize: "1.3em"
  },
  labelHorizontal: {
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    textAlign: "right",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "39px",
    marginRight: "0",
    "@media (min-width: 992px)": {
      float: "right"
    }
  },
  formElementHorizontal: {
    alignItems: "flex-end",
    marginBottom: "1rem"
  },
  input: {
    width: "100%"
  },
  actionButtonsContainer: {
    marginTop: "35px"
  },
  insertButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  formElementInfo: {
    display: "flex",
    alignContent: "center",
    marginTop: "10px"
  },
  formElementInfoItem: {
    display: "flex",
    justifyContent: "center"
  },
  errorText: {
    position: "absolute"
  },
  dropDownList: {
    width: "70%"
  },
  maskedInput: {
    width: "50%"
  },
  fileUpload: {
    width: "50%"
  },
  multilineInput: {
    borderWidth: "0 0 1px"
  },
  underline: {
    "&:hover:before,&:before,&:after": {
      borderWidth: "0 0 1px !important"
    },
    "& + p": {
      fontWeight: "300"
    },
    "&:after": {
      borderColor: medOpenPrimaryColor
    }
  },
  underlineError: {
    "&:hover:before,&:before,&:after": {
      borderColor: "rgba(243,23,0,0.5) !important",
      borderWidth: "0 0 1px !important"
    }
  }
};

export default formStyle;
