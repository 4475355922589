import { toDataSourceRequestString } from "@progress/kendo-data-query";

export function getCourses(dataState) {
  return {
    path: `courses?${toDataSourceRequestString(dataState)}`
  };
}

export function getCoursesLookup() {
  return {
    path: `courses/lookup`
  };
}

export function getCourseDetails(id) {
  return {
    path: `courses/${id}`
  };
}

export function addCourse(data) {
  return {
    path: "courses",
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Course successfully created!"
    }
  };
}

export function updateCourse(data) {
  return {
    path: `courses/${data.id}`,
    method: "PUT",
    data: data,
    successNotificationConfig: {
      message: "Course successfully updated!"
    }
  };
}

export function deleteCourse(id) {
  return {
    path: `courses/${id}`,
    method: "DELETE",
    data: id,
    successNotificationConfig: {
      message: "Course successfully deleted!"
    }
  };
}

export function getCourseTranslation(data) {
  return {
    path: `courses/${data.id}/translations/${data.courseLanguageId}`
  };
}

export function addCourseTranslation(data) {
  return {
    path: `courses/${data.id}/translations`,
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Course translation successfully created!"
    }
  };
}

export function updateCourseTranslation(data) {
  return {
    path: `courses/${data.courseId}/translations/${data.id}`,
    method: "PUT",
    data: data,
    successNotificationConfig: {
      message: "Course translation successfully updated!"
    }
  };
}
