import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";

import FormElement from "./FormElement";

import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const FormInput = ({
  name = "",
  label = "",
  value = "",
  floatingLabel,
  isRequired = false,
  isDisabled = false,
  isUnique = false,
  autoFocus,
  autoComplete = false,
  isTouched = false,
  isFocused = false,
  isValid = false,
  errorMessage = "",
  containerStyles,
  onFocus = () => {},
  onChange = () => {},
  onBlur,
  ...rest
}) => {
  const classes = useStyles();

  const handleBlur = event => {
    onBlur && typeof onBlur === "function" && onBlur(event);
    isRequired && onFocus(event);
  };

  return (
    <FormElement label={label} containerStyles={containerStyles}>
      <Input
        autoFocus={autoFocus}
        autoComplete={autoComplete ? "on" : "new-password"}
        className={classes.input}
        label={floatingLabel}
        name={name}
        value={value || ""}
        disabled={isDisabled}
        onChange={onChange}
        onBlur={handleBlur}
        validityStyles={isRequired && isTouched ? true : false}
        valid={isRequired && isTouched ? isValid : undefined}
        {...rest}
      />

      {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormElement>
  );
};

export default FormInput;
