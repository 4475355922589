import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardText from "../Card/CardText";

import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const FormGroup = ({ title, children }) => {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info" className={classes.cardTitle}>
              <h6>{title}</h6>
            </CardText>
          </CardHeader>

          <CardBody>{children}</CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default FormGroup;
