const infoListStyles = {
  infoText: {
    fontSize: 14,
    color: "#454545",
    marginBottom: 0,
    textTransform: "uppercase"
  },
  main: {
    padding: "10px",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    margin: 0
  }
};

export default infoListStyles;
