export const staticPageInitialState = {
  name: {
    label: "Name",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100
    }
  },
  isActive: {
    label: "Active?",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  isShownInMenu: {
    label: "Visible in menu",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  isHomePage: {
    label: "Set this page as home page",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  },
  courseId: {
    label: "Select course",
    value: null,
    meta: {
      type: "number",
      isRequired: false,
      lookupType: "dropDown",
      lookupKey: "courses"
    }
  },
  isPublicAccessForCoursePage: {
    label: "Has public access from course page",
    value: false,
    meta: {
      type: "bool",
      isRequired: false
    }
  }
};

export const translationInitialState = {
  languageId: {
    label: "Select Language",
    value: null,
    meta: {
      type: "number",
      dataItemKey: "languageId",
      textField: "languageName",
      isRequired: true,
      lookupType: "dropDown",
      lookupKey: "languages"
    }
  },
  headLine: {
    label: "HeadLine",
    value: "",
    meta: {
      type: "text",
      maxLength: 100
    }
  },
  title: {
    label: "Title",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100
    }
  },
  subTitle: {
    label: "Subtitle",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100
    }
  },
  menuTitle: {
    label: "Menu title",
    value: "",
    meta: {
      type: "text",
      maxLength: 100
    }
  },
  text: {
    label: "Text",
    value: "",
    meta: {
      type: "rich_text",
      isRequired: true
    }
  },
  isActive: {
    label: "Active?",
    value: false,
    meta: {
      type: "bool"
    }
  },
  isShownInMenu: {
    label: "Shown in menu",
    value: false,
    meta: {
      type: "bool"
    }
  },
  menuOrderNumber: {
    label: "Tranaslation order in menu",
    value: null,
    meta: {
      type: "number",
      isRequired: true
    }
  },
  hasComments: {
    label: "Comments allowed",
    value: true,
    meta: {
      type: "bool"
    }
  }
};
