import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import styles from "../../assets/jss/components/gridStyle";

const useStyles = makeStyles(styles);

const GridItem = props => {
  const classes = useStyles();

  const { children, className, ...rest } = props;

  return (
    <Grid
      item
      {...rest}
      className={`${classes.item} ${className ? className : ""}`}
    >
      {children}
    </Grid>
  );
};

GridItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default GridItem;
