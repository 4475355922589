import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/components/linkListStyle";

const useStyles = makeStyles(styles);

const LinkListItem = ({
  dataItem: item,
  linkRef,
  copyToClipBoard = () => {},
  copySuccess
}) => {
  const classes = useStyles();
  const { id, userTypeInfo, url } = item;
  return (
    <div className={classes.main}>
      <div>
        <h2 className={classes.infoText}>{userTypeInfo}</h2>
        <div
          id={id}
          ref={r => (linkRef.current[id] = r)}
          className={classes.link}
          dangerouslySetInnerHTML={{ __html: url }}
        ></div>
      </div>
      {document.queryCommandSupported("copy") && (
        <div className={classes.copyButton}>
          <div>
            {!copySuccess[id] ? (
              <Button
                icon="copy"
                look="flat"
                onClick={e => copyToClipBoard(e, id)}
              >
                Copy link
              </Button>
            ) : (
              copySuccess[id]
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkListItem;
