import { toDataSourceRequestString } from "@progress/kendo-data-query";

export const getUsers = dataState => {
  return {
    path: `users?${toDataSourceRequestString(dataState)}`
  };
};
export const getUserDetails = id => {
  return {
    path: `users/${id}`
  };
};

export const addUser = data => {
  return {
    path: "users",
    method: "POST",
    data,
    successNotificationConfig: {
      message: "User successfully created!"
    }
  };
};

export const updateUser = data => {
  return {
    path: `users/${data.id}`,
    method: "PUT",
    data,
    successNotificationConfig: {
      message: "User successfully updated!"
    }
  };
};

export const deleteUser = id => {
  return {
    path: `users/${id}`,
    method: "DELETE",
    data: id,
    successNotificationConfig: {
      message: "User successfully deleted!"
    }
  };
};

export const getRoles = data => {
  return {
    path: "roles/lookup",
    data
  };
};
export const checkEmail = email => {
  let data = new FormData();
  data.set("email", email);
  return {
    path: "users/verify-email",
    method: "POST",
    data
  };
};
export const checkUserName = userName => {
  let data = new FormData();
  data.set("userName", userName);
  return {
    path: "users/verify-username",
    method: "POST",
    data
  };
};

export const getGenders = () => {
  return {
    path: `genders/lookup`
  };
};

export function getUsersLookup() {
  return {
    path: "users/lookup"
  };
}

export function getAssignedCourses(id) {
  return {
    path: `users/${id}/assigned-courses`
  };
}
