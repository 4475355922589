import PagesIcon from "@material-ui/icons/Pages";
import People from "@material-ui/icons/People";
import School from "@material-ui/icons/School";
import Comment from "@material-ui/icons/Comment";
import MenuBook from "@material-ui/icons/MenuBook";

import Login from "../pages/auth/Login";
import StaticPagesList from "../pages/admin/static-pages/list/StaticPagesListContainer";
import StaticPagesForm from "../pages/admin/static-pages/form/StaticPagesFormContainer";
import UsersList from "../pages/admin/user-management/list/UsersListContainer";
import UsersForm from "../pages/admin/user-management/form/UserFormContainer";
import CoursesList from "../pages/admin/courses/list/CoursesListContainer";
import CoursesForm from "../pages/admin/courses/form/CoursesFormContainer";
import LecturesList from "../pages/admin/lectures/list/LecturesListContainer";
import LecturesForm from "../pages/admin/lectures/form/LecturesFormContainer";
import CommentsList from "../pages/admin/comments/list/CommentsListContainer";
import CommentsForm from "../pages/admin/comments/form/CommentsFormContainer";

//For collapsed routes, pages property has been used instead of view
const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    private: false
  },
  {
    path: "/static-pages",
    name: "Static Pages",
    icon: PagesIcon,
    component: StaticPagesList,
    layout: "/admin",
    private: true,
    exact: true
  },
  {
    path: "/static-pages/form/:id?",
    name: "Static Pages",
    component: StaticPagesForm,
    layout: "/admin",
    private: true,
    removeFromSidebar: true
  },
  {
    path: "/users",
    name: "Users",
    icon: People,
    component: UsersList,
    layout: "/admin",
    private: true,
    exact: true
  },
  {
    path: "/users/form/:id?",
    name: "Users",
    component: UsersForm,
    layout: "/admin",
    private: true,
    removeFromSidebar: true
  },
  {
    path: "/courses",
    name: "Courses",
    icon: School,
    component: CoursesList,
    layout: "/admin",
    private: true,
    exact: true
  },
  {
    path: "/courses/form/:id?",
    name: "Courses",
    component: CoursesForm,
    layout: "/admin",
    private: true,
    removeFromSidebar: true
  },
  {
    path: "/lectures",
    name: "Lectures",
    icon: MenuBook,
    component: LecturesList,
    layout: "/admin",
    private: true,
    exact: true
  },
  {
    path: "/lectures/form/:id?",
    name: "Lectures",
    component: LecturesForm,
    layout: "/admin",
    private: true,
    removeFromSidebar: true
  },
  {
    path: "/comments",
    name: "Comments",
    icon: Comment,
    component: CommentsList,
    layout: "/admin",
    private: true,
    exact: true
  },
  {
    path: "/comments/form/:id?",
    name: "Comments",
    component: CommentsForm,
    layout: "/admin",
    private: true,
    removeFromSidebar: true
  }
];

export default routes;
