import { callApi } from "../services/api/api";

import {
  getLectures as getLecturesApi,
  getLectureDetails as getLectureDetailsApi,
  addLecture as addLectureApi,
  updateLecture as updateLectureApi,
  deleteLecture as deleteLectureApi,
  getLectureTranslation as getLectureTranslationApi,
  addLectureTranslation as addLectureTranslationApi,
  updateLectureTranslation as updateLectureTranslationApi,
  getLectureTranslationsLookup as getLectureTranslationsLookupApi
} from "../pages/admin/lectures/apiConfig";
import generateActions from "../services/utilities/generateActions";

export const GET_LECTURES = generateActions("GET_LECTURES");
export const GET_LECTURE_DETAILS = generateActions("GET_LECTURE_DETAILS");
export const ADD_LECTURE = generateActions("ADD_LECTURE");
export const UPDATE_LECTURE = generateActions("UPDATE_LECTURE");
export const DELETE_LECTURE = generateActions("DELETE_LECTURE");

export const GET_LECTURE_TRANSLATION = generateActions(
  "GET_LECTURE_TRANSLATION"
);
export const ADD_LECTURE_TRANSLATION = generateActions(
  "ADD_LECTURE_TRANSLATION"
);
export const UPDATE_LECTURE_TRANSLATION = generateActions(
  "UPDATE_LECTURE_TRANSLATION"
);
export const GET_LECTURE_TRANSLATIONS_LOOKUP = generateActions(
  "GET_LECTURE_TRANSLATIONS_LOOKUP"
);

export const CHANGE_LECTURE_DATA_STATE = "CHANGE_LECTURE_DATA_STATE";
export const RESET_LECTURE_STATE = "RESET_LECTURE_STATE";
export const RESET_LECTURE_FORM_STATE = "RESET_LECTURE_FORM_STATE";
export const RESET_LECTURE_TRANSLATION_FORM_STATE =
  "RESET_LECTURE_TRANSLATION_FORM_STATE";

/**
 * LECTURE ACTION CREATORS
 */

export function getLectures({ dataState }) {
  return {
    type: GET_LECTURES.ACTION,
    promise: callApi(getLecturesApi(dataState)),
    payload: dataState
  };
}

export function getLectureDetails({ id }) {
  return {
    type: GET_LECTURE_DETAILS.ACTION,
    promise: callApi(getLectureDetailsApi(id)),
    payload: id
  };
}

export function addLecture({ data }) {
  return {
    type: ADD_LECTURE.ACTION,
    promise: callApi(addLectureApi(data)),
    payload: data
  };
}

export function updateLecture({ data }) {
  return {
    type: UPDATE_LECTURE.ACTION,
    promise: callApi(updateLectureApi(data)),
    payload: data
  };
}

export function deleteLecture(id) {
  return {
    type: DELETE_LECTURE.ACTION,
    promise: callApi(deleteLectureApi(id)),
    payload: id
  };
}

export function deleteLectureAndUpdateGrid(id, dataState) {
  return dispatch => {
    return dispatch(deleteLecture(id)).then(() =>
      dispatch(getLectures(dataState))
    );
  };
}

export function changeLectureDataState({ dataState }) {
  return {
    type: CHANGE_LECTURE_DATA_STATE,
    payload: dataState
  };
}

/**
 * Resets the whole lecture state in store to initial state.
 */
export function resetLectureState() {
  return {
    type: RESET_LECTURE_STATE
  };
}

/**
 * Resets all data connected to lecture and translation forms.
 */
export function resetLectureFormState() {
  return {
    type: RESET_LECTURE_FORM_STATE
  };
}

/**
 * TRANSLATION ACTION CREATORS
 */

export function getLectureTranslation({ data }) {
  return {
    type: GET_LECTURE_TRANSLATION.ACTION,
    promise: callApi(getLectureTranslationApi(data)),
    payload: data
  };
}

export function addLectureTranslation({ data }) {
  return {
    type: ADD_LECTURE_TRANSLATION.ACTION,
    promise: callApi(addLectureTranslationApi(data)),
    payload: data
  };
}

export function updateLectureTranslation({ data }) {
  return {
    type: UPDATE_LECTURE_TRANSLATION.ACTION,
    promise: callApi(updateLectureTranslationApi(data)),
    payload: data
  };
}

/**
 * Only resets the fields related to the translation form.
 * Accepts new language ID for memorizing it while
 * changing languages in dropdown and reseting the rest
 * of the form.
 * @param {Object} [data]
 * @param {number} data.data
 */
export function resetLectureTranslationFormState({ data }) {
  return {
    type: RESET_LECTURE_TRANSLATION_FORM_STATE,
    payload: data
  };
}

export function getLectureTranslationsLookup({ data } = {}) {
  return {
    type: GET_LECTURE_TRANSLATIONS_LOOKUP.ACTION,
    promise: callApi(getLectureTranslationsLookupApi(data))
  };
}

/**
 * THUNKS
 */

/**
 * Used for autosaving changes to the selected translation
 * when changing languages in the dropdown. It first creates
 * the translation and then resets fields related to the
 * translation form, with the ID of the newly selected language.
 * @param {Object} data
 * @param {Object} data.translation
 * @param {number} data.newLanguageId
 */
export function addLectureTranslationThenResetTranslationForm({
  translation,
  newLanguageId
}) {
  return dispatch => {
    return dispatch(addLectureTranslation({ data: translation })).then(() =>
      dispatch(resetLectureTranslationFormState({ data: newLanguageId }))
    );
  };
}

/**
 * Used for autosaving changes to the selected translation
 * when changing languages in the dropdown. It first updates
 * the translation and then resets fields related to the
 * translation form, with the ID of the newly selected language.
 * @param {Object} data
 * @param {Object} data.translation
 * @param {number} data.newLanguageId
 */
export function updateLectureTranslationThenResetTranslationForm({
  translation,
  newLanguageId
}) {
  return dispatch => {
    return dispatch(updateLectureTranslation({ data: translation })).then(() =>
      dispatch(resetLectureTranslationFormState({ data: newLanguageId }))
    );
  };
}

/**
 * Used when saving changes on the lecture form and navigating back to list.
 * It first creates the lecture and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.lecture
 */
export function addLectureThenResetForm({ lecture }) {
  return dispatch => {
    return dispatch(addLecture(lecture)).then(() =>
      dispatch(resetLectureFormState())
    );
  };
}

/**
 * Used when saving changes on the lecture form and navigating back to list.
 * It first updates the lecture and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.lecture
 */
export function updateLectureThenResetForm({ lecture }) {
  return dispatch => {
    return dispatch(updateLecture({ data: lecture })).then(() =>
      dispatch(resetLectureFormState())
    );
  };
}

/**
 * Used when saving changes on the translation form and navigating back to list.
 * It first creates the translation and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.translation
 */
export function addLectureTranslationThenResetForm({ translation }) {
  return dispatch => {
    return dispatch(addLectureTranslation({ data: translation })).then(() =>
      dispatch(resetLectureFormState())
    );
  };
}

/**
 * Used when saving changes on the translation form and navigating back to list.
 * It first updates the translation and then resets all fields related
 * to both forms.
 * @param {Object} data
 * @param {Object} data.translation
 */
export function updateLectureTranslationThenResetForm({ translation }) {
  return dispatch => {
    return dispatch(updateLectureTranslation({ data: translation })).then(() =>
      dispatch(resetLectureFormState())
    );
  };
}

/**
 * Used when saving changes on both forms and navigating back to list.
 * It first updates the lecture, then creates the translation
 * and in the end resets all fields related to both forms.
 * @param {Object} data
 * @param {Object} data.lecture
 * @param {Object} data.translation
 */
export function updateLectureThenAddTranslationThenResetForm({
  lecture,
  translation
}) {
  return dispatch => {
    return dispatch(updateLecture({ data: lecture })).then(() =>
      dispatch(addLectureTranslation({ data: translation })).then(() =>
        dispatch(resetLectureFormState())
      )
    );
  };
}

/**
 * Used when saving changes on both forms and navigating back to list.
 * It first updates the lecture, then updates the translation
 * and in the end resets all fields related to both forms.
 * @param {Object} data
 * @param {Object} data.lecture
 * @param {Object} data.translation
 */
export function updateLectureThenUpdateTranslationThenResetForm({
  lecture,
  translation
}) {
  return dispatch => {
    return dispatch(updateLecture({ data: lecture })).then(() =>
      dispatch(updateLectureTranslation({ data: translation })).then(() =>
        dispatch(resetLectureFormState())
      )
    );
  };
}
