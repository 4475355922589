import { callApi } from "../services/api/api";

import {
  getUsers as getUsersApi,
  getUserDetails as getUserDetailsApi,
  addUser as addUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  getRoles as getRolesApi,
  checkEmail as checkEmailApi,
  checkUserName as checkUserNameApi,
  getGenders as getGendersApi,
  getUsersLookup as getUsersLookupApi,
  getAssignedCourses as getAssignedCoursesApi
} from "../pages/admin/user-management/apiConfig";
import generateActions from "../services/utilities/generateActions";

export const GET_USERS = generateActions("GET_USERS");
export const GET_USER_DETAILS = generateActions("GET_USER_DETAILS");
export const ADD_USER = generateActions("ADD_USER");
export const UPDATE_USER = generateActions("UPDATE_USER");
export const DELETE_USER = generateActions("DELETE_USER");
export const GET_ROLES = generateActions("GET_ROLES");
export const CHECK_EMAIL = generateActions("CHECK_EMAIL");
export const CHECK_USERNAME = generateActions("CHECK_USERNAME");
export const GET_GENDERS = generateActions("GET_GENDERS");
export const GET_USERS_LOOKUP = generateActions("GET_USERS_LOOKUP");
export const GET_ASSIGNED_COURSES = generateActions("GET_ASSIGNED_COURSES");

export const CHANGE_USER_DATA_STATE = "CHANGE_USER_DATA_STATE";
export const RESET_USER_STATE = "RESET_USER_STATE";

export const getUsers = dataState => {
  return {
    type: GET_USERS.ACTION,
    promise: callApi(getUsersApi(dataState)),
    payload: dataState
  };
};
export const getUserDetails = id => {
  return {
    type: GET_USER_DETAILS.ACTION,
    promise: callApi(getUserDetailsApi(id)),
    payload: id
  };
};

export const addUser = data => {
  return {
    type: ADD_USER.ACTION,
    promise: callApi(addUserApi(data)),
    payload: data
  };
};

export const updateUser = data => {
  return {
    type: UPDATE_USER.ACTION,
    promise: callApi(updateUserApi(data)),
    payload: data
  };
};

export const deleteUser = id => {
  return {
    type: DELETE_USER.ACTION,
    promise: callApi(deleteUserApi(id)),
    payload: id
  };
};

export function deleteUserAndUpdateGrid(id, dataState) {
  return dispatch => {
    return dispatch(deleteUser(id)).then(() => dispatch(getUsers(dataState)));
  };
}

export function changeUserDataState(dataState) {
  return {
    type: CHANGE_USER_DATA_STATE,
    payload: dataState
  };
}

export function resetUserState() {
  return {
    type: RESET_USER_STATE
  };
}

export const getRoles = () => {
  return {
    type: GET_ROLES.ACTION,
    promise: callApi(getRolesApi())
  };
};
export const checkEmail = email => {
  return {
    type: CHECK_EMAIL.ACTION,
    promise: callApi(checkEmailApi(email))
  };
};
export const checkUserName = userName => {
  return {
    type: CHECK_USERNAME.ACTION,
    promise: callApi(checkUserNameApi(userName))
  };
};
export const getGenders = () => {
  return {
    type: GET_GENDERS.ACTION,
    promise: callApi(getGendersApi())
  };
};

export function getUsersLookup() {
  return {
    type: GET_USERS_LOOKUP.ACTION,
    promise: callApi(getUsersLookupApi())
  };
}

export function getAssignedCourses(id) {
  return {
    type: GET_ASSIGNED_COURSES.ACTION,
    promise: callApi(getAssignedCoursesApi(id))
  };
}

export function loadSelectedUser(id) {
  return dispatch => {
    return dispatch(getUserDetails(id)).then(() =>
      dispatch(getAssignedCourses(id))
    );
  };
}
