import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from "react";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Form from "../../../../components/PageForm/PageForm";
import FormInfoText from "../../../../components/Form/FormInfoText";
import ActionButtons from "../../../../components/Form/ActionButtons";
import useForm from "../../../../hooks/useForm";
import useObjectMapper from "../../../../hooks/useObjectMapper";
import {
  lectureInitialState,
  translationInitialState
} from "./LectureTranslationFields";
import {
  getLectureDetails,
  addLecture,
  updateLecture,
  getLectureTranslation,
  addLectureTranslation,
  updateLectureTranslation,
  addLectureTranslationThenResetTranslationForm,
  updateLectureTranslationThenResetTranslationForm,
  addLectureThenResetForm,
  updateLectureThenResetForm,
  addLectureTranslationThenResetForm,
  updateLectureTranslationThenResetForm,
  updateLectureThenAddTranslationThenResetForm,
  updateLectureThenUpdateTranslationThenResetForm,
  resetLectureTranslationFormState
} from "../../../../actions/lectures";
import { getCoursesLookup } from "../../../../actions/courses";
import {
  getSelectedLecture,
  getIsLectureSaved,
  getLanguagesForSelectedLecture,
  getSelectedLectureTranslation
} from "../../../../reducers/lectures";
import { getCoursesLookupList } from "../../../../reducers/courses";
import styles from "../../../../assets/jss/pages/formStyle";

const useStyles = makeStyles(styles);

const Lecture = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const selectedLecture = useSelector(getSelectedLecture);
  const isLectureSaved = useSelector(getIsLectureSaved);
  const coursesLookup = useSelector(getCoursesLookupList);
  const languages = useSelector(getLanguagesForSelectedLecture);

  const selectedTranslation = useSelector(getSelectedLectureTranslation);

  const dispatch = useDispatch();

  const [hasLectureUnsavedChanges, setHasLectureUnsavedChanges] = useState(
    false
  );
  const [
    hasTranslationUnsavedChanges,
    setHasTranslationUnsavedChanges
  ] = useState(false);
  /**
   * We set this flag to detect when the 'save all'
   * option was clicked and we need to navigate back to list.
   */
  const needsNavigationToList = useRef(false);

  const onLectureInputChange = () => {
    if (!hasLectureUnsavedChanges) {
      setHasLectureUnsavedChanges(true);
    }
  };

  const onTranslationInputChange = propKey => {
    // Exclude language ID value change from setting
    // unsaved changes flag.
    if (propKey === "languageId") {
      return;
    }

    if (!hasTranslationUnsavedChanges) {
      setHasTranslationUnsavedChanges(true);
    }
  };

  /**
   * Initialize lecture values for form.
   */
  const { getInitialState: lectureState } = useObjectMapper({
    initialState: lectureInitialState,
    selectedItem: selectedLecture
  });

  const {
    values: lecture,
    validations: lectureValidations,
    handleInputFocus: handleLectureInputFocus,
    handleInputChange: handleLectureInputChange,
    isFormValid: isLectureFormValid,
    resetForm: resetLectureForm
  } = useForm({
    initialValues: lectureState,
    onInputChange: onLectureInputChange
  });

  /**
   * Initialize translation values for form.
   */
  const { getInitialState: translationState } = useObjectMapper({
    initialState: translationInitialState,
    selectedItem: selectedTranslation
  });

  const {
    values: translation,
    validations: translationValidations,
    handleInputFocus: handleTranslationInputFocus,
    handleInputChange: handleTranslationInputChange,
    onContentEditorChange,
    isFormValid: isTranslationFormValid,
    resetForm: resetTranslationForm
  } = useForm({
    initialValues: translationState,
    onInputChange: onTranslationInputChange
  });

  useEffect(() => {
    dispatch(getCoursesLookup());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getLectureDetails({ id }));
    }
  }, [dispatch, id]);

  /**
   * One or both of the unsaved changes flags have changed back to false
   * after the 'save all' option was clicked, so we need to navigate
   * back to list.
   */
  useEffect(() => {
    if (
      (!hasLectureUnsavedChanges || !hasTranslationUnsavedChanges) &&
      needsNavigationToList.current
    ) {
      history.push("/admin/lectures");
    }
  }, [history, hasLectureUnsavedChanges, hasTranslationUnsavedChanges]);

  /**
   * Track changes in lecture and translation objects in state.
   * If something changes, check if lecture has the translation
   * in the currently selected language. If it does, fetch it from api.
   */
  useEffect(() => {
    const lectureLanguageId = selectedLecture?.allLanguagesWithOrWithoutThisLecture?.find(
      x => x.languageId === selectedTranslation?.languageId
    )?.lectureLanguageId;

    if (
      selectedTranslation?.languageId &&
      lectureLanguageId &&
      !selectedTranslation?.id
    ) {
      dispatch(
        getLectureTranslation({
          data: {
            id: selectedLecture?.id,
            lectureLanguageId
          }
        })
      );
    }
  }, [dispatch, selectedLecture, selectedTranslation]);

  /**
   * Update route on create lecture action.
   */
  useEffect(() => {
    if (
      selectedLecture?.id &&
      history.location.pathname === "/admin/lectures/form"
    ) {
      history.replace(`/admin/lectures/form/${selectedLecture.id}`);
    }
  }, [selectedLecture, history]);

  /**
   * Automatically save translation if form is valid.
   * Otherwise, reset form.
   */
  const handleLanguageChange = e => {
    const newLanguageId = Object.values(e.target.value)[0];

    /**
     * If translation doesn't exist and the form is valid,
     * create it then reset form with new language.
     */
    if (
      isTranslationFormValid &&
      hasTranslationUnsavedChanges &&
      !selectedTranslation.id
    ) {
      let payload = {
        id: selectedLecture?.id,
        ...translation,
        lectureId: selectedLecture?.id
      };
      dispatch(
        addLectureTranslationThenResetTranslationForm({
          translation: payload,
          newLanguageId: newLanguageId
        })
      );
      setHasTranslationUnsavedChanges(false);
    }

    /**
     * If translation already exists and the form is valid,
     * update it then reset form with new language.
     */
    if (
      isTranslationFormValid &&
      hasTranslationUnsavedChanges &&
      selectedTranslation.id
    ) {
      let payload = {
        id: selectedTranslation.id,
        ...translation,
        lectureId: selectedLecture?.id
      };

      dispatch(
        updateLectureTranslationThenResetTranslationForm({
          translation: payload,
          newLanguageId: newLanguageId
        })
      );
      setHasTranslationUnsavedChanges(false);
    }

    /**
     * If there were no changes or if the form isn't valid,
     * cancel changes and reset form.
     */
    if (!hasTranslationUnsavedChanges) {
      dispatch(resetLectureTranslationFormState({ data: newLanguageId }));
    } else if (hasTranslationUnsavedChanges && !isTranslationFormValid) {
      setHasTranslationUnsavedChanges(false);
      dispatch(resetLectureTranslationFormState({ data: newLanguageId }));
    }
  };

  const handleSaveLecture = () => {
    if (id || selectedLecture) {
      dispatch(
        updateLecture({ data: { id: id || selectedLecture.id, ...lecture } })
      );
    } else {
      dispatch(addLecture({ data: { ...lecture } }));
    }

    if (hasLectureUnsavedChanges) {
      setHasLectureUnsavedChanges(false);
    }
  };

  const handleSaveTranslation = () => {
    if (!selectedTranslation.id) {
      dispatch(
        addLectureTranslation({
          data: {
            id: selectedLecture?.id,
            ...translation,
            lectureId: selectedLecture?.id
          }
        })
      );
    } else {
      dispatch(
        updateLectureTranslation({
          data: {
            id: selectedTranslation.id,
            ...translation,
            lectureId: selectedLecture?.id
          }
        })
      );
    }

    if (hasTranslationUnsavedChanges) {
      setHasTranslationUnsavedChanges(false);
    }
  };

  const handleSaveAll = useCallback(
    e => {
      // There are several possible scenarios:
      // 1. only general info form was modified
      // 1.1. lecture didn't previously exist so we need to create it
      // 1.2. lecture exists so we need to update it
      // 2. only translation form was modified
      // 2.1. translation didn't previously exist so we need to create it
      // 2.2. translation exists so we need to update it
      // 3. both forms were modified
      // 3.1. translation didn't previously exist
      //      so we need to update lecture and create translation
      // 3.2. translation exists
      //      so we need to update both lecture and translation
      if (hasLectureUnsavedChanges && !hasTranslationUnsavedChanges) {
        if (!id && !selectedLecture?.id) {
          dispatch(addLectureThenResetForm({ lecture }));
        } else {
          dispatch(
            updateLectureThenResetForm({
              lecture: { id: id || selectedLecture.id, ...lecture }
            })
          );
        }

        setHasLectureUnsavedChanges(false);
        needsNavigationToList.current = true;
      }

      if (
        hasTranslationUnsavedChanges &&
        !hasLectureUnsavedChanges &&
        selectedLecture?.id
      ) {
        if (!selectedTranslation?.id) {
          dispatch(
            addLectureTranslationThenResetForm({
              translation: {
                id: selectedLecture?.id,
                ...translation,
                lectureId: selectedLecture.id
              }
            })
          );
        } else {
          dispatch(
            updateLectureTranslationThenResetForm({
              translation: {
                id: selectedTranslation.id,
                ...translation,
                lectureId: selectedLecture.id
              }
            })
          );
        }

        setHasTranslationUnsavedChanges(false);
        needsNavigationToList.current = true;
      }

      if (
        hasLectureUnsavedChanges &&
        hasTranslationUnsavedChanges &&
        selectedLecture?.id
      ) {
        if (!selectedTranslation?.id) {
          dispatch(
            updateLectureThenAddTranslationThenResetForm({
              lecture: { id: id || selectedLecture.id, ...lecture },
              translation: {
                id: selectedLecture?.id,
                ...translation,
                lectureId: selectedLecture.id
              }
            })
          );
        } else {
          dispatch(
            updateLectureThenUpdateTranslationThenResetForm({
              lecture: { id: id || selectedLecture.id, ...lecture },
              translation: {
                id: selectedTranslation.id,
                ...translation,
                lectureId: selectedLecture.id
              }
            })
          );
        }

        setHasLectureUnsavedChanges(false);
        setHasTranslationUnsavedChanges(false);
        needsNavigationToList.current = true;
      }
    },
    [
      dispatch,
      hasLectureUnsavedChanges,
      hasTranslationUnsavedChanges,
      id,
      lecture,
      translation,
      selectedLecture,
      selectedTranslation
    ]
  );

  const handleReset = form => () => {
    if (form === "Lecture" && hasLectureUnsavedChanges) {
      resetLectureForm();
      setHasLectureUnsavedChanges(false);
    } else if (form === "Translation" && hasTranslationUnsavedChanges) {
      resetTranslationForm();
      setHasTranslationUnsavedChanges(false);
    }
  };

  const handleCancel = () => {
    history.push("/admin/lectures");
  };

  /**
   * Submit (save all) bottun is disabled if there are no changes made,
   * or if one/both forms are changed and not valid.
   */
  const isSubmitDisabled = useMemo(() => {
    return (
      (!hasLectureUnsavedChanges && !hasTranslationUnsavedChanges) ||
      (hasLectureUnsavedChanges && !isLectureFormValid) ||
      (hasTranslationUnsavedChanges && !isTranslationFormValid)
    );
  }, [
    hasLectureUnsavedChanges,
    hasTranslationUnsavedChanges,
    isLectureFormValid,
    isTranslationFormValid
  ]);

  return (
    <div className={classes.container}>
      <Prompt
        when={hasLectureUnsavedChanges || hasTranslationUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      <Form
        initialData={lectureInitialState}
        data={lecture}
        validations={lectureValidations}
        courses={coursesLookup}
        isFormValid={isLectureFormValid}
        isEditMode={isLectureSaved || selectedLecture?.id ? true : false}
        hasUnsavedChanges={hasLectureUnsavedChanges}
        onInputFocus={handleLectureInputFocus}
        onInputChange={handleLectureInputChange}
        onSave={handleSaveLecture}
        onReset={handleReset("Lecture")}
        onCancel={handleCancel}
      />

      {!isLectureSaved && !id ? (
        <FormInfoText
          type="info"
          label="Create basic lecture information to be able to create translation"
        />
      ) : null}

      {isLectureSaved || id ? (
        <>
          <Form
            title="Translation"
            initialData={translationState}
            data={translation}
            validations={translationValidations}
            languages={languages}
            isFormValid={isTranslationFormValid}
            isEditMode={selectedTranslation?.id ? true : false}
            hasUnsavedChanges={hasTranslationUnsavedChanges}
            onInputFocus={handleTranslationInputFocus}
            onInputChange={handleTranslationInputChange}
            onContentEditorChange={onContentEditorChange}
            onLanguageChange={handleLanguageChange}
            onSave={handleSaveTranslation}
            onReset={handleReset("Translation")}
            folderName={"lectures"}
          />

          <ActionButtons
            label="Save all and return"
            isSubmitDisabled={isSubmitDisabled}
            handleSubmit={handleSaveAll}
            handleReturn={handleCancel}
          />
        </>
      ) : null}
    </div>
  );
};

export default Lecture;
