import { MULTISELECT, DROPDOWN } from "../../../../constants/dropDownTypes";
import { CRUD_TYPES } from "../../../../enums/crudTypes";

export default {
  userName: {
    label: "Username",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100,
      isUnique: true,
      group: "User",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  email: {
    label: "Email",
    value: "",
    meta: {
      type: "email",
      isRequired: true,
      maxLength: 100,
      isUnique: true,
      group: "User",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  password: {
    label: "Password",
    value: "",
    meta: {
      type: "password",
      isRequired: true,
      maxLength: 100,
      group: "User",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  confirmPassword: {
    label: "Confirm password",
    value: "",
    meta: {
      type: "password",
      isRequired: true,
      maxLength: 100,
      group: "User",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  roleId: {
    label: "Select role",
    value: null,
    meta: {
      type: "number",
      dataItemKey: "id",
      isRequired: true,
      lookupType: DROPDOWN,
      lookupKey: "roles",
      group: "User",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  isApproved: {
    label: "Approved?",
    value: false,
    meta: {
      type: "bool",
      group: "User",
      crud: [CRUD_TYPES.Edit]
    }
  },
  firstName: {
    label: "First name",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  lastName: {
    label: "Last name",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },

  genderId: {
    label: "Gender",
    value: null,
    meta: {
      type: "radio",
      isRequired: false,
      lookupType: "radio",
      lookupKey: "genders",
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  dateOfBirth: {
    label: "Date of birth",
    value: null,
    meta: {
      type: "date",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit],
      isDateOfBirthField: true
    }
  },
  nationality: {
    label: "Nationality",
    value: "",
    meta: {
      type: "text",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  educationBackground: {
    label: "Education background",
    value: "",
    meta: {
      type: "text",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  workplaceFunction: {
    label: "Workplace function",
    value: "",
    meta: {
      type: "text",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  facultyCountry: {
    label: "Faculty country",
    value: "",
    meta: {
      type: "text",
      isRequired: true,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  country: {
    label: "Country",
    value: "",
    meta: {
      type: "text",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  city: {
    label: "City",
    value: "",
    meta: {
      type: "text",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  address: {
    label: "Address",
    value: "",
    meta: {
      type: "text",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  skypeName: {
    label: "Skype name",
    value: "",
    meta: {
      type: "text",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  phoneNumber: {
    label: "Phone number",
    value: "",
    meta: {
      type: "phone",
      isRequired: false,
      maxLength: 100,
      group: "Member",
      crud: [CRUD_TYPES.Insert, CRUD_TYPES.Edit]
    }
  },
  courses: {
    label: "Enrolled courses",
    value: null,
    meta: {
      // type: "number",
      dataItemKey: "id",
      lookupType: MULTISELECT,
      lookupKey: "courses",
      group: "course",
      crud: [CRUD_TYPES.Edit]
    }
  }
};
