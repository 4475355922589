import { GridCell } from "@progress/kendo-react-grid";

export default [
  {
    name: "id",
    title: "ID",
    cellType: GridCell,
    sortable: true,
    filterable: false
  },
  {
    name: "course",
    title: "Course Name",
    cellType: GridCell,
    sortable: true,
    filterable: true
  },
  {
    name: "name",
    title: "Lecture Name",
    cellType: GridCell,
    sortable: true,
    filterable: true
  },
  {
    name: "currentTranslations",
    title: "Translations",
    cellType: GridCell,
    sortable: false,
    filterable: false
  }
];
