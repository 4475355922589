import { combineReducers } from "redux";

import staticPages from "./staticPages";
import auth from "./auth";
import users from "./users";
import courses from "./courses";
import languages from "./languages";
import lectures from "./lectures";
import comments from "./comments";
import questions from "./questions";

const rootReducer = combineReducers({
  staticPages,
  auth,
  users,
  courses,
  languages,
  lectures,
  comments,
  questions
});

export default rootReducer;
