import { removeAuthStateFromStorage } from "../services/utilities/storage";
import { RESET_AUTH_STATE } from "../actions/auth";

export default function promiseMiddleware() {
  return dispatch => action => {
    const { promise, type, ...rest } = action;
    type === RESET_AUTH_STATE && removeAuthStateFromStorage();

    if (!promise) {
      return dispatch(action);
    }

    const SUCCESS = `${type}_SUCCESS`;
    const REQUEST = `${type}_REQUEST`;
    const FAILURE = `${type}_ERROR`;

    dispatch({ ...rest, type: REQUEST });

    return promise
      .then(res => {
        if (typeof res === "undefined") {
          dispatch({ ...rest, res, type: FAILURE });
        } else {
          dispatch({ ...rest, payload: res, type: SUCCESS });
        }

        return true;
      })
      .catch(error => {
        dispatch({ ...rest, error, type: FAILURE });
        return false;
      });
  };
}
