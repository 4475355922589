import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

import styles from "../../assets/jss/components/joditEditorStyles";

const useStyles = makeStyles(styles);

const FormEditorElement = ({ label, children }) => {
  const classes = useStyles();

  return (
    <GridContainer className={classes.editorElementTop}>
      <GridItem xs={12}>
        <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
      </GridItem>

      <GridItem xs={12} className={classes.editor}>
        {children}
      </GridItem>
    </GridContainer>
  );
};

export default FormEditorElement;
