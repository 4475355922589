import React, { useEffect, useState, useRef, useMemo } from "react";
// TODO: vidjeti ima li potrebe za mijenjanjem izgleda prompta prilikom navigacije.
// Opcije su ili napraviti custom dialog koji ce pratiti potrebne eventove
// ili koristiti neki 3rd party paket, npr. https://github.com/ZacharyRSmith/react-router-navigation-prompt
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Form from "../../../../components/PageForm/PageForm";
import ActionButtons from "../../../../components/Form/ActionButtons";
import {
  getCourseDetails,
  addCourse,
  updateCourse,
  getCourseTranslation,
  addCourseTranslation,
  updateCourseTranslation,
  resetCourseTranslationFormState,
  addCourseTranslationThenResetTranslationForm,
  updateCourseTranslationThenResetTranslationForm,
  addCourseThenResetForm,
  updateCourseThenResetForm,
  addCourseTranslationThenResetForm,
  updateCourseTranslationThenResetForm,
  updateCourseThenAddTranslationThenResetForm,
  updateCourseThenUpdateTranslationThenResetForm
} from "../../../../actions/courses";
import styles from "../../../../assets/jss/pages/formStyle";
import FormInfoText from "../../../../components/Form/FormInfoText";
import useObjectMapper from "../../../../hooks/useObjectMapper";
import useForm from "../../../../hooks/useForm";
import {
  courseInitialState,
  translationInitialState
} from "./CourseTranslationFields";
import {
  getSelectedCourse,
  getIsCourseSaved,
  getLanguagesForSelectedCourse,
  getSelectedCourseTranslation
} from "../../../../reducers/courses";

const useStyles = makeStyles(styles);

const CoursesFormContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();

  const selectedCourse = useSelector(getSelectedCourse);
  const isSaved = useSelector(getIsCourseSaved);
  const languages = useSelector(getLanguagesForSelectedCourse);

  const selectedTranslation = useSelector(getSelectedCourseTranslation);

  const dispatch = useDispatch();

  const [hasCourseUnsavedChanges, setHasCourseUnsavedChanges] = useState(false);
  const [
    hasTranslationUnsavedChanges,
    setHasTranslationUnsavedChanges
  ] = useState(false);

  /**
   * We set this flag to detect when the 'save all'
   * option was clicked and we need to navigate back to list.
   */
  const needsNavigationToList = useRef(false);

  const onCourseInputChange = () => {
    if (!hasCourseUnsavedChanges) {
      setHasCourseUnsavedChanges(true);
    }
  };

  const onTranslationInputChange = propKey => {
    // Exclude language ID value change from setting
    // unsaved changes flag.
    if (propKey === "languageId") {
      return;
    }

    if (!hasTranslationUnsavedChanges) {
      setHasTranslationUnsavedChanges(true);
    }
  };

  /**
   * Initialize course values for form.
   */
  const { getInitialState: courseState } = useObjectMapper({
    initialState: courseInitialState,
    selectedItem: selectedCourse
  });

  const {
    values: course,
    validations: courseValidations,
    handleInputFocus: handleCourseInputFocus,
    handleInputChange: handleCourseInputChange,
    isFormValid: isCourseFormValid,
    resetForm: resetCourseForm
  } = useForm({
    initialValues: courseState,
    onInputChange: onCourseInputChange
  });

  /**
   * Initialize translation values for form.
   */
  const { getInitialState: translationState } = useObjectMapper({
    initialState: translationInitialState,
    selectedItem: selectedTranslation
  });

  const {
    values: translation,
    validations: translationValidations,
    handleInputFocus: handleTranslationInputFocus,
    handleInputChange: handleTranslationInputChange,
    onContentEditorChange,
    isFormValid: isTranslationFormValid,
    resetForm: resetTranslationForm
  } = useForm({
    initialValues: translationState,
    onInputChange: onTranslationInputChange
  });

  useEffect(() => {
    if (id) {
      dispatch(getCourseDetails(id));
    }
  }, [dispatch, id]);

  /**
   * One or both of the unsaved changes flags have changed back to false
   * after the 'save all' option was clicked, so we need to navigate
   * back to list.
   */
  useEffect(() => {
    if (
      (!hasCourseUnsavedChanges || !hasTranslationUnsavedChanges) &&
      needsNavigationToList.current
    ) {
      history.push("/admin/courses");
    }
  }, [history, hasCourseUnsavedChanges, hasTranslationUnsavedChanges]);

  /**
   * Track changes in Course and translation objects in state.
   * If something changes, check if Course has the translation
   * in the currently selected language. If it does, fetch it from api.
   */
  useEffect(() => {
    const courseLanguageId = selectedCourse?.allLanguagesWithOrWithoutThisCourse?.find(
      x => x.languageId === selectedTranslation?.languageId
    )?.courseLanguageId;

    if (
      selectedTranslation?.languageId &&
      courseLanguageId &&
      !selectedTranslation?.id
    ) {
      dispatch(
        getCourseTranslation({
          id: selectedCourse?.id,
          courseLanguageId
        })
      );
    }
  }, [dispatch, selectedCourse, selectedTranslation]);

  // update route on create course action
  useEffect(() => {
    if (
      selectedCourse?.id &&
      history.location.pathname === "/admin/courses/form"
    ) {
      history.replace(`/admin/courses/form/${selectedCourse?.id}`);
    }
  }, [selectedCourse, history]);

  /**
   * Automatically save translation if form is valid.
   * Otherwise, reset form.
   */
  const handleLanguageChange = e => {
    const newLanguageId = Object.values(e.target.value)[0];

    /**
     * If translation doesn't exist and the form is valid,
     * create it then reset form with new language.
     */
    if (
      isTranslationFormValid &&
      hasTranslationUnsavedChanges &&
      !selectedTranslation.id
    ) {
      let payload = {
        id: selectedCourse?.id,
        ...translation,
        courseId: selectedCourse?.id
      };
      dispatch(
        addCourseTranslationThenResetTranslationForm({
          translation: payload,
          newLanguageId: newLanguageId
        })
      );
      setHasTranslationUnsavedChanges(false);
    }

    /**
     * If translation already exists and the form is valid,
     * update it then reset form with new language.
     */
    if (
      isTranslationFormValid &&
      hasTranslationUnsavedChanges &&
      selectedTranslation.id
    ) {
      let payload = {
        id: selectedTranslation.id,
        ...translation,
        courseId: selectedCourse?.id
      };

      dispatch(
        updateCourseTranslationThenResetTranslationForm({
          translation: payload,
          newLanguageId: newLanguageId
        })
      );
      setHasTranslationUnsavedChanges(false);
    }

    /**
     * If there were no changes or if the form isn't valid,
     * cancel changes and reset form.
     */
    if (!hasTranslationUnsavedChanges) {
      dispatch(resetCourseTranslationFormState({ data: newLanguageId }));
      setHasTranslationUnsavedChanges(false);
    } else if (hasTranslationUnsavedChanges && !isTranslationFormValid) {
      setHasTranslationUnsavedChanges(false);
      dispatch(resetCourseTranslationFormState({ data: newLanguageId }));
    }
  };
  const handleSaveCourse = () => {
    if (id || selectedCourse) {
      dispatch(updateCourse({ id: id || selectedCourse.id, ...course }));
    } else {
      dispatch(addCourse(course));
    }

    if (hasCourseUnsavedChanges) {
      setHasCourseUnsavedChanges(false);
    }
  };

  const handleSaveTranslation = () => {
    if (!selectedTranslation.id) {
      dispatch(
        addCourseTranslation({
          id: selectedCourse?.id,
          ...translation,
          courseId: selectedCourse?.id
        })
      );
    } else {
      dispatch(
        updateCourseTranslation({
          id: selectedTranslation.id,
          ...translation,
          courseId: selectedCourse?.id
        })
      );
    }

    if (hasTranslationUnsavedChanges) {
      setHasTranslationUnsavedChanges(false);
    }
  };

  const handleSaveAll = e => {
    // There are several possible scenarios:
    // 1. only course form was modified
    // 1.1. course didn't previously exist so we need to create it
    // 1.2. course exists so we need to update it
    // 2. only translation form was modified
    // 2.1. translation didn't previously exist so we need to create it
    // 2.2. translation exists so we need to update it
    // 3. both forms were modified
    // 3.1. translation didn't previously exist
    //      so we need to update course and create translation
    // 3.2. translation exists
    //      so we need to update both course and translation
    if (hasCourseUnsavedChanges && !hasTranslationUnsavedChanges) {
      if (!id && !selectedCourse?.id) {
        dispatch(addCourseThenResetForm({ course }));
      } else {
        dispatch(
          updateCourseThenResetForm({
            course: { id: id || selectedCourse.id, ...course }
          })
        );
      }

      setHasCourseUnsavedChanges(false);
      needsNavigationToList.current = true;
    }

    if (
      hasTranslationUnsavedChanges &&
      !hasCourseUnsavedChanges &&
      selectedCourse?.id
    ) {
      if (!selectedTranslation?.id) {
        dispatch(
          addCourseTranslationThenResetForm({
            translation: {
              id: selectedCourse?.id,
              ...translation,
              courseId: selectedCourse.id
            }
          })
        );
      } else {
        dispatch(
          updateCourseTranslationThenResetForm({
            translation: {
              id: selectedTranslation.id,
              ...translation,
              courseId: selectedCourse.id
            }
          })
        );
      }

      setHasTranslationUnsavedChanges(false);
      needsNavigationToList.current = true;
    }

    if (
      hasCourseUnsavedChanges &&
      hasTranslationUnsavedChanges &&
      selectedCourse?.id
    ) {
      if (!selectedTranslation?.id) {
        dispatch(
          updateCourseThenAddTranslationThenResetForm({
            course: { id: id || selectedCourse.id, ...course },
            translation: {
              id: selectedCourse?.id,
              ...translation,
              courseId: selectedCourse.id
            }
          })
        );
      } else {
        dispatch(
          updateCourseThenUpdateTranslationThenResetForm({
            course: { id: id || selectedCourse.id, ...course },
            translation: {
              id: selectedTranslation.id,
              ...translation,
              courseId: selectedCourse.id
            }
          })
        );
      }

      setHasCourseUnsavedChanges(false);
      setHasTranslationUnsavedChanges(false);
      needsNavigationToList.current = true;
    }
  };

  const handleReset = form => e => {
    if (hasCourseUnsavedChanges && form === "Course") {
      resetCourseForm();
      setHasCourseUnsavedChanges(false);
    } else if (hasTranslationUnsavedChanges && form === "Translation") {
      resetTranslationForm();
      setHasTranslationUnsavedChanges(false);
    }
  };

  const handleCancel = () => {
    history.push("/admin/courses");
  };

  /**
   * Submit (save all) bottun is disabled if there are no changes made,
   * or if one/both forms are changed and not valid.
   */
  const isSubmitDisabled = useMemo(() => {
    return (
      (!hasCourseUnsavedChanges && !hasTranslationUnsavedChanges) ||
      (hasCourseUnsavedChanges && !isCourseFormValid) ||
      (hasTranslationUnsavedChanges && !isTranslationFormValid)
    );
  }, [
    hasCourseUnsavedChanges,
    hasTranslationUnsavedChanges,
    isCourseFormValid,
    isTranslationFormValid
  ]);
  return (
    <div className={classes.container}>
      <Prompt
        when={hasCourseUnsavedChanges || hasTranslationUnsavedChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />

      <Form
        initialData={courseInitialState}
        data={course}
        validations={courseValidations}
        isFormValid={isCourseFormValid}
        isEditMode={isSaved || selectedCourse?.id ? true : false}
        hasUnsavedChanges={hasCourseUnsavedChanges}
        onInputFocus={handleCourseInputFocus}
        onInputChange={handleCourseInputChange}
        onSave={handleSaveCourse}
        onReset={handleReset("Course")}
        onCancel={handleCancel}
      />

      {!isSaved && !id ? (
        <FormInfoText
          type="info"
          label="Create basic course information to be able to create translation"
        />
      ) : null}

      {isSaved || id ? (
        <>
          <Form
            title="Translation"
            initialData={translationState}
            data={translation}
            validations={translationValidations}
            languages={languages}
            isFormValid={isTranslationFormValid}
            isEditMode={selectedTranslation?.id ? true : false}
            hasUnsavedChanges={hasTranslationUnsavedChanges}
            onInputFocus={handleTranslationInputFocus}
            onInputChange={handleTranslationInputChange}
            onContentEditorChange={onContentEditorChange}
            onLanguageChange={handleLanguageChange}
            onSave={handleSaveTranslation}
            onReset={handleReset("Translation")}
            folderName={"courses"}
            links={{
              title: "Registration links",
              list: selectedTranslation?.registrationLinks
            }}
          />

          <ActionButtons
            label="Save all and return"
            isSubmitDisabled={isSubmitDisabled}
            handleSubmit={handleSaveAll}
            handleReturn={handleCancel}
          />
        </>
      ) : null}
    </div>
  );
};

export default CoursesFormContainer;
