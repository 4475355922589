import React from "react";
import { DropDownList as DropDown } from "@progress/kendo-react-dropdowns";
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";

import FormElement from "./FormElement";
import styles from "../../assets/jss/components/formStyle";

const useStyles = makeStyles(styles);

const DropDownList = ({
  label = "",
  name = "",
  dataItemKey = "id",
  textField = "name",
  data = [],
  value = {},
  defaultValue = "",
  helperText = "",
  isDisabled = false,
  isRequired = false,
  isTouched = false,
  isFocused = false,
  isValid,
  errorMessage = "",
  onFocus = () => {},
  onChange = () => {},
  ...rest
}) => {
  const classes = useStyles();

  const handleBlur = event => {
    isRequired && onFocus(event);
  };

  const handleChange = event => {
    onChange(event, dataItemKey);
  };

  return (
    <FormElement label={label}>
      <DropDown
        className={classes.dropDownList}
        name={name}
        dataItemKey={dataItemKey}
        textField={textField}
        value={
          data.length && value ? data.find(x => x[dataItemKey] === value) : null
        }
        data={data}
        defaultValue={
          data.length && defaultValue
            ? data.find(x => x[dataItemKey] === defaultValue)
            : null
        }
        // onFocus={onFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        required={isRequired}
        disabled={isDisabled}
        validityStyles={isRequired && isTouched ? true : false}
        valid={isRequired && isTouched ? isValid : undefined}
      />

      {isRequired && isTouched ? (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormElement>
  );
};

export default DropDownList;
