import React from "react";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import useFocus from "../../hooks/useFocus";
// import styles from "../../assets/jss/components/infoTextStyle";
import FormElement from "./FormElement";
// const useStyles = makeStyles(styles);

const FormInfoText = ({ type = "", label = "" }) => {
  // const classes = useStyles();
  const [handleFocus] = useFocus();
  return (
    <>
      <FormElement type={type}>
        {!handleFocus ? <h3>{label}</h3> : null}
      </FormElement>
    </>
  );
};

export default FormInfoText;
