import { toDataSourceRequestString } from "@progress/kendo-data-query";

export function getLectures(dataState) {
  return {
    path: `lectures?${toDataSourceRequestString(dataState)}`
  };
}

export function getLectureDetails(id) {
  return {
    path: `lectures/${id}`
  };
}

export function addLecture(data) {
  return {
    path: "lectures",
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Lecture successfully created!"
    }
  };
}

export function updateLecture(data) {
  return {
    path: `lectures/${data.id}`,
    method: "PUT",
    data: data,
    successNotificationConfig: {
      message: "Lecture successfully updated!"
    }
  };
}

export function deleteLecture(id) {
  return {
    path: `lectures/${id}`,
    method: "DELETE",
    data: id,
    successNotificationConfig: {
      message: "Lecture successfully deleted!"
    }
  };
}

export function getLectureTranslation(data) {
  return {
    path: `lectures/${data.id}/translations/${data.lectureLanguageId}`
  };
}

export function addLectureTranslation(data) {
  return {
    path: `lectures/${data.id}/translations`,
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Lecture translation successfully created!"
    }
  };
}

export function updateLectureTranslation(data) {
  return {
    path: `lectures/${data.lectureId}/translations/${data.id}`,
    method: "PUT",
    data: data,
    successNotificationConfig: {
      message: "Lecture translation successfully updated!"
    }
  };
}

export function getLectureTranslationsLookup(data) {
  return {
    path: `lectures/translation-lookup${data ? `?id=${data.lectureId}` : ""}`
  };
}

export function getLectureQuestions(data) {
  return {
    path: `questions?lectureLanguageId=${data.lectureLanguageId}`
  };
}

export function addOrUpdateQuestions(data) {
  return {
    path: "questions",
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Question successfully saved!"
    }
  };
}

export function deleteQuestion(data) {
  return {
    path: `questions/${data.id}`,
    method: "DELETE",
    successNotificationConfig: {
      message: "Question successfully deleted!"
    }
  };
}

export function getQuestionAnswers(data) {
  return {
    path: `questions/answers?questionId=${data.questionId}`
  };
}

export function addOrUpdateQuestionAnswers(data) {
  return {
    path: `questions/answers`,
    method: "POST",
    data: data,
    successNotificationConfig: {
      message: "Answers successfully saved!"
    }
  };
}

export function deleteQuestionAnswer(data) {
  return {
    path: `questions/answer/${data.id}`,
    method: "DELETE",
    successNotificationConfig: {
      message: "Answer successfully deleted!"
    }
  };
}
